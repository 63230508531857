import store from "@/store/store";
import { db } from "@/_lib/firebase";
import { mapChats } from "./messaging/chat_service";
import { getColorForName } from "@/_helpers/colour_functions";
import { mapReadTimestamps } from "./messaging/message_service";
import { IChat, IMessage } from "@/interfaces/messaging/messaging";
import { IMessagingContact } from "@/interfaces/messaging/contact";
import { collection, query, where, onSnapshot, orderBy, getDocs, and, or } from "firebase/firestore";
import {
  setChats,
  setMessages,
  addMessage,
  updateMessage,
  removeMessage,
  setLoaded,
  setContacts,
} from "@/store/messaging";


export const listenForUsers = (coach_id: number, team_id?: number) => {
  const usersRef = collection(db, "users");
  const usersQuery = team_id ?
    query(usersRef, or(
        and(
            or(where("coach_id", "==", coach_id), where("team_id", "==", team_id)),
            where("active", "==", true),
        ),
        and(
            where("team_id", "==", team_id), 
            where("role", "==", "coach"),
        )
    )) :
    query(usersRef, and(
        where("coach_id", "==", coach_id),
        where("active", "==", true),
    ));

  return onSnapshot(usersQuery, async (snapshot) => {
    const contacts: IMessagingContact[] = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        client_id: doc.data().client_id,
        coach_id: doc.data().coach_id,
        team_id: doc.data().team_id,
        name: doc.data().name,
        email: doc.data().email,
        avatar: doc.data().avatar,
        role: doc.data().role,
        active: doc.data().active,
        messaging_enabled: doc.data().messaging_enabled,
        color: getColorForName(doc.data().name),
      };
    });
    store.dispatch(setContacts(contacts));
  });
};


export const listenForChats = (user_id: string) => {
  const chatsRef = collection(db, "chats");
  const chatsQuery = query(chatsRef, where("participants", "array-contains", user_id));

  return onSnapshot(chatsQuery, async (snapshot) => {
    const chats: IChat[] = await mapChats(snapshot.docs);
    store.dispatch(setChats(chats));

    chats.forEach((chat) => {
      listenForChatMessages(chat.id);
    });

    // If first load, fetch messages
    if (!store.getState().messaging.loaded) {
        await fetchInitialMessages(chats);
        store.dispatch(setLoaded(true));
    }
  });
};

const fetchInitialMessages = async (chats: IChat[]) => {
  for (const chat of chats) {
    const messagesRef = collection(db, `chats/${chat.id}/messages`);
    const messagesQuery = query(messagesRef, orderBy("created_at", "asc"));
    const snapshot = await getDocs(messagesQuery);

    const messages: IMessage[] = snapshot.docs.map((doc) => ({
      id: doc.id,
      type: doc.data().type,
      sender_id: doc.data().sender_id,
      text: doc.data().text,
      media: doc.data().media,
      media_thumbnail: doc.data().media_thumbnail,
      reactions: doc.data().reactions,
      pending: doc.data().pending,
      upload_progress: doc.data().upload_progress,
      media_group: doc.data().media_group,
      metadata: doc.data().metadata,
      reply_to: doc.data().reply_to,
      created_at: doc.data().created_at ? new Date(doc.data().created_at.toDate()).toISOString() : '',
      updated_at: doc.data().updated_at ? new Date(doc.data().updated_at.toDate()).toISOString() : '',
      read_by: doc.data().read_by ?? [],
      read_timestamps: doc.data().read_timestamps ? mapReadTimestamps(doc.data().read_timestamps) : [],
    })) as IMessage[];

    store.dispatch(setMessages({ chat_id: chat.id, messages }));
  }
};

export const listenForChatMessages = (chat_id: string) => {
  const messagesRef = collection(db, `chats/${chat_id}/messages`);
  const messagesQuery = query(messagesRef, orderBy("created_at", "asc"));

  return onSnapshot(messagesQuery, (snapshot) => {
      const loaded = store.getState().messaging.loaded;
      const notificationsEnabled = store.getState().settings.notifications.messaging;
      if (!loaded) {
          return;
      }
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        if (!doc.exists || !doc.data().created_at) return;
        const message: IMessage = {
            id: doc.id,
            type: doc.data().type,
            sender_id: doc.data().sender_id,
            text: doc.data().text,
            media: doc.data().media,
            media_thumbnail: doc.data().media_thumbnail,
            reactions: doc.data().reactions,
            pending: doc.data().pending,
            upload_progress: doc.data().upload_progress,
            media_group: doc.data().media_group,
            metadata: doc.data().metadata,
            reply_to: doc.data().reply_to,
            created_at: doc.data().created_at ? new Date(doc.data().created_at.toDate()).toISOString() : '',
            updated_at: doc.data().updated_at ? new Date(doc.data().updated_at.toDate()).toISOString() : '',
            read_by: doc.data().read_by ?? [],
            read_timestamps: doc.data().read_timestamps ? mapReadTimestamps(doc.data().read_timestamps) : [],
        };
        switch (change.type) {
            case "added": {
              store.dispatch(addMessage({ chat_id, message, notificationsEnabled }));
              if (!loaded) return
              break;
            }
            case "modified": {
              store.dispatch(updateMessage({ chat_id, message, notificationsEnabled }));
              break;
            }
            case "removed": 
            store.dispatch(removeMessage({ chat_id, message_id: message.id }));
            break;
        }
      });
    });
};

export const listenForTyping = (chat_id: string, onTypingUpdate: (typing: Record<string, boolean>) => void) => {
    const typingRef = collection(db, `chats/${chat_id}/typing`);
    const unsubscribe = onSnapshot(typingRef, (snapshot) => {
        const typing: Record<string, boolean> = {};
        snapshot.forEach((doc) => {
            typing[doc.id] = doc.data().is_typing;
        });
        onTypingUpdate(typing);
    });
    return unsubscribe;
};