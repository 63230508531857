import { FC, ReactNode } from "react";

// Components
import { Popover as MuiPopover } from "@mui/material";


interface _PopoverPlacement {
    horizontal: 'left' | 'center' | 'right' | number;
    vertical: 'top' | 'center' | 'bottom' | number;
}

interface _PopoverProps {
    anchor: HTMLElement|null;
    placement?: _PopoverPlacement;
    children: ReactNode;
    onClose: () => void;
}

const Popover: FC<_PopoverProps> = ({
    anchor,
    placement = {horizontal: 'center', vertical: 'top'},
    children,
    onClose
}) => {

    return (
        <>
            <MuiPopover
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={onClose}
                anchorOrigin={placement}
                transformOrigin={{horizontal: 'center', vertical: 'bottom'}}
                slotProps={{
                    paper: {
                        sx: {
                            position: 'relative',
                            overflow: 'visible',
                            maxWidth: 250,
                            mt: '-8px',
                            borderRadius: '6px',
                            border: 'solid 1px var(--nav-layer-03)',
                            bgcolor: 'var(--nav-layer-01)',
                            '&::before': {
                                content: "' '",
                                position: 'absolute',
                                top: '100%',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                borderWidth: '8px',
                                borderStyle: 'solid',
                                borderColor: 'var(--nav-layer-01) transparent transparent transparent',
                                zIndex: 1
                            },
                            '&::after': {
                                content: "' '",
                                position: 'absolute',
                                top: '100%',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                borderWidth: '9px',
                                borderStyle: 'solid',
                                borderColor: 'var(--nav-layer-03) transparent transparent transparent',
                                zIndex: 0
                            }
                        }
                    }
                }}
                >
                {children}
            </MuiPopover>
        </>
    )
}

export default Popover;