import AudioWorker from '@/_lib/audio_worker?worker';

const worker = new AudioWorker();

export function convertToM4A(file: Blob): Promise<Blob> {
    return new Promise((resolve, reject) => {
        worker.postMessage({ file });
        worker.onmessage = (event) => {
            if (event.data.success) {
                resolve(event.data.outputBlob);
            } else {
                reject(new Error(`Conversion failed: ${event.data.error}`));
            }
        };
        worker.onerror = (error) => reject(error);
    });
}
