import { useDispatch } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

// Reducers
import authReducer from "./auth";
import coachReducer from "./coach";
import settingsReducer from "./settings";
import registrationReducer from "./registration";
import subscriptionReducer from "./subscription";
import teamReducer from "./team";
import datatableReducer from "./datatable";
import pageStatesReducer from "./page_states";
import checkinPopupReducer from "./checkin_popup";
import messagingReducer from "./messaging";

// Middlewares
import { contactsMiddleware } from "@/middleware/contacts_middleware";

// Base API
import baseApi from "@/repositories/base_api";


const store = configureStore({
    reducer: {
      auth: authReducer,
      registration: registrationReducer,
      subscription: subscriptionReducer,
      coach: coachReducer,
      settings: settingsReducer,
      team: teamReducer,
      datatable: datatableReducer,
      pageStates: pageStatesReducer,
      checkinPopup: checkinPopupReducer,
      messaging: messagingReducer,
      [baseApi.reducerPath]: baseApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(baseApi.middleware, contactsMiddleware),
  });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store;