import { t } from "i18next";
import { v4 as uuidv4 } from 'uuid';
import { useForm } from "react-hook-form";
import { FC, useEffect, useState } from "react";

// Helpers
import { useDebounce } from "@/_helpers/hooks";
import { getFilePrefix } from "@/_helpers/file_functions";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { sendMedia } from "@/services/messaging/media_service";
import { setTypingStatus } from "@/services/messaging/chat_service";
import { IMediaFile, IMessage } from "@/interfaces/messaging/messaging";
import { editMessage, sendMessage } from "@/services/messaging/message_service";

// Helpers
import { formatHumanReadableDateString } from "@/_helpers/date_functions";

// Styles
import { SendAlt } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import VoiceNote from "./voice_note";
import MediaInput from "./media_input";
import Button from "@/components/button";
import TextInput from "@/components/text_input";
import IconButton from "@/components/icon_button";
import MessageMediaButton from "./message_media_button";
import MessageInputReply from "./message_input_reply";


export interface IMessageReply {
    message: IMessage;
    sender: string;
}

interface _MessageInputProps {
    chat_id: string;
    user_id: string;
    messageToEdit?: IMessage|null;
    messageToReply?: IMessageReply|null;
    isLoading?: boolean;
    disabled?: boolean;
    onClearEdit: () => void;
    onClearReply: () => void;
}

const MessageInput: FC<_MessageInputProps> = ({
    chat_id,
    user_id,
    messageToEdit,
    messageToReply,
    isLoading = false,
    disabled = false,
    onClearEdit,
    onClearReply
}) => {

    const [isUploading, setIsUploading] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<IMediaFile[]>([])
    const [selectedFile, setSelectedFile] = useState<IMediaFile|null>(null);

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            message: '',
        }
    });
    const message = formMethods.watch('message');
    const canSendVoiceNote = message?.length < 1 && selectedFiles?.length < 1;

    const debouncedTyping = useDebounce(() => {
        if (disabled) return;
        setTypingStatus(chat_id, user_id, false);
    }, 3000);

    const handleTyping = () => {
        if (!message || message == '') return;
        setTypingStatus(chat_id, user_id, true);
        debouncedTyping;
    };

    const handleSendMedia = async (file: IMediaFile) => {
        setIsUploading(true);
        sendMedia(chat_id, file).then(() => {
            setIsUploading(false);
        }).catch((error: ApiError) => {
            console.error('Error sending media:', error);
            setIsUploading(false);
        });
    }

    const handleSendMessage = async (data: { message: string }) => {
        const { message } = data;
        if (!message.trim()) return;

        const reply_to = messageToReply ? messageToReply.message.id : undefined;

        sendMessage(chat_id, message, reply_to);

        // Clear the typing status
        setTypingStatus(chat_id, user_id, false);

        // Clear the reply message if present
        onClearReply();

        formMethods.reset();
    };

    const handleEditMessage = async (data: { message: string }) => {
        if (!messageToEdit) return;
        editMessage(chat_id, messageToEdit.id, data.message);
        handleCancelEditMessage();
    }

    const handleCancelEditMessage = () => {
        onClearEdit();
        formMethods.reset();
    }

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (messageToEdit) return;
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            selectedFiles.length == 0 ?
                formMethods.handleSubmit(handleSendMessage)() :
                handleSendSelectedMedia();
        } else {
            handleTyping();
        }
    }

    const handleSelectMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const mediaFiles: IMediaFile[] = Array.from(event.target.files).map((file) => {
            const fileExtension = file.name.split('.').pop();
            const isDocument = file.type.includes('application');
            return {
                file,
                name: `${isDocument ? file.name.replace(`.${fileExtension}`, '') : getFilePrefix(file.type)}_${formatHumanReadableDateString()}.${fileExtension}`,
                type: getFilePrefix(file.type),
                text: null,
                preview_url: URL.createObjectURL(file),
            }
        });

        setSelectedFiles((prev) => {
            const existingNames = new Set(prev.map((item) => item.name));
            return [...prev, ...mediaFiles.filter((item) => !existingNames.has(item.name))];
        });
        setSelectedFile(mediaFiles[0]);

        event.target.value = "";
    };

    const handleSelectMediaItem = (file: IMediaFile) => {
        setSelectedFile(file);
        formMethods.setValue('message', file.text ?? '');
    }

    const handleRemoveMedia = (index: number) => {
        setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
    };

    const handleSendSelectedMedia = async () => {
        setIsUploading(true);
        setSelectedFile(null);
        try {
            const mediaGroup = selectedFiles.filter((file) => ['image', 'video'].includes(file.type)).length > 3 ? uuidv4() : null;
            const files = selectedFiles.map((file) => {
                file.media_group = ['image', 'video'].includes(file.type) ? mediaGroup : null;
                return file;
            });
            for (const file of files) {
                sendMedia(chat_id, file);
            }
            setSelectedFiles([]);
        } catch (error) {
            console.error("Error sending media:", error);
        } finally {
            formMethods.reset();
            setIsUploading(false);
        }
    };

    useEffect(() => {
        if (messageToEdit) {
            formMethods.setValue('message', messageToEdit.text);
        }
    }, [messageToEdit]);

    useEffect(() => {
        if (selectedFiles && selectedFile) {
            selectedFile.text = message;
        }
    }, [message]);

    return (
        <Box display="flex" flexDirection="column" padding="16px 16px" borderTop="solid 1px var(--border-subtle-01)">
            {selectedFiles.length > 0 && <MediaInput
                selectedFiles={selectedFiles}
                selectedFile={selectedFile}
                onSelectMedia={handleSelectMediaItem}
                handleRemoveMedia={handleRemoveMedia}
                />}
            {messageToReply && 
                <MessageInputReply
                    message={messageToReply.message}
                    onClearReply={onClearReply}
                    />}
            <Box display="flex">
                <MessageMediaButton 
                    onSelectMedia={handleSelectMedia} 
                    disabled={isUploading || isRecording || disabled} 
                    />
                {!isRecording && <TextInput
                    size="textarea"
                    name="message"
                    control={formMethods.control}
                    placeHolder="Type your message..."
                    minRows={1}
                    maxRows={4}
                    autoResize
                    allowEmoji={!disabled}
                    onKeyDown={handleOnKeyDown}
                    disabled={isLoading || disabled}
                    sx={{'.MuiInputBase-multiline': {overflow: 'unset'}}}
                    />}
                {canSendVoiceNote && !messageToEdit &&
                    <VoiceNote 
                        isUploading={isUploading}
                        isRecording={isRecording}
                        disabled={disabled}
                        setIsRecording={setIsRecording}
                        onSend={handleSendMedia}
                        />}
                {!canSendVoiceNote && !messageToEdit && <IconButton
                    size="medium"
                    icon={<SendAlt />}
                    onClick={selectedFiles.length == 0 ? formMethods.handleSubmit(handleSendMessage) : handleSendSelectedMedia}
                    sx={{marginLeft: '12px'}}
                    />}
            </Box>

            {messageToEdit && <Box display="flex" justifyContent="flex-end">
                <Button
                    size="small"
                    kind="tertiary"
                    label={t('components.buttons.cancel')}
                    minWidth={false}
                    onClick={handleCancelEditMessage}
                    sx={{marginRight: '8px'}}
                    />
                <Button
                    size="small"
                    label={t('components.buttons.save')}
                    minWidth={false}
                    onClick={formMethods.handleSubmit(handleEditMessage)}
                    />
            </Box>}
        </Box>
    )
}

export default MessageInput;