import { t } from "i18next";
import { FC, ReactNode } from "react";
import { useSelector } from "react-redux";
import { DefaultExtensionType, defaultStyles, FileIcon } from "react-file-icon";

// Helpers
import { formatDuration } from "@/_helpers/time_functions";

// Services and interfaces
import { RootState } from "@/store/store";
import { selectContactById, selectFirebaseUser } from "@/store/messaging";
import { IMessage } from "@/interfaces/messaging/messaging";

// Styles
import { Folder, PhotoCamera } from "@mui/icons-material";
import { Close, MicrophoneFilled, VideoFilled } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import IconButton from "@/components/icon_button";



interface _MessageInputReplyProps {
    message: IMessage;
    onClearReply: () => void;
}

const MessageInputReply: FC<_MessageInputReplyProps> = ({
    message,
    onClearReply
}) => {

    const firebaseUser = useSelector(selectFirebaseUser);
    const participantSender = useSelector((state: RootState) => selectContactById(state, message.sender_id));
    const sender = message.sender_id == firebaseUser.id ? firebaseUser : participantSender;

    const hasMedia = !!message.media;
    const hasText = !!message.text && message.text.length > 0;
    const isImage = hasMedia && message.type === 'image';
    const isVideo = hasMedia && message.type === 'video';
    const isAudio = hasMedia && message.type === 'voice';
    const isFile = hasMedia && message.type === 'file';
    const fileExtension = message.metadata?.extension as DefaultExtensionType;

    const getMediaIcon = () => {
        const iconStyle = {color: 'var(--text-secondary)', height: '16px', width: '16px', marginRight: '4px'};
        if (isImage) return <PhotoCamera style={iconStyle} />;
        if (isVideo) return <VideoFilled style={iconStyle} />;
        if (isAudio) return <MicrophoneFilled style={iconStyle} />;
        if (isFile) return <Folder style={iconStyle} />;
        return null;
    }

    const getMediaText = (): ReactNode => {
        if (hasText) return (
            <span className="body-02 ThreeLine--ellipsis" style={{color: 'var(--text-primary)'}}>{message.text}</span>
        )
        return (
            <Box display="flex" alignItems="center" mt="2px">
                {getMediaIcon()}
                {!isAudio && <span className="label-text-02">{t(`enums.mediaIcon.${message.type}`)}</span>}
                {isAudio && <span className="label-text-02">{formatDuration(message.metadata?.duration ?? 0)}</span>}
            </Box>
        )
    }

    return (
        <Box 
            display="flex"
            margin="0 52px 8px 52px" 
            padding="4px 0 4px 12px"
            boxSizing="border-box"
            borderRadius="6px"
            border="solid 1px var(--border-subtle-01)"
            borderLeft={`solid 3px ${sender?.color}`}
            bgcolor="var(--background-hover)"
            >
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                padding="4px 0"
                borderRadius="6px"
                textAlign="left"
                >
                <span className="heading-07-compact" style={{color: sender?.color}}>{sender?.name}</span>
                {getMediaText()}
            </Box>
            {(isImage || isVideo) && <img src={message.media_thumbnail} alt="media" style={{width: '40px', height: '40px', alignSelf: 'center', borderRadius: '6px', objectFit: 'cover'}} />}
            {isFile && <Box display="flex" width="30px">
                <FileIcon
                    extension={fileExtension} {...defaultStyles[fileExtension]} />
            </Box>}
            <IconButton
                kind="ghost"
                icon={<Close />}
                onClick={onClearReply}
                sx={{marginLeft: '8px'}}
                />
        </Box>
    )
}

export default MessageInputReply;