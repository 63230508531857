
import { useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";

// Services and interfaces
import { IChat } from "@/interfaces/messaging/messaging";
import { setTypingStatus } from "@/services/messaging/chat_service";
import { selectChats, selectFirebaseUser } from "@/store/messaging";
import { markMessagesAsReadWithTimestamp } from "@/services/messaging/message_service";


// Components
import Chat from "./chat";
import Messages from "./messages";
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";


interface _ChatPanelProps {
    open: boolean;
    onClose: ()=>void;
}

const ChatPanel: FC<_ChatPanelProps> = ({
    open,
    onClose,
}) => {

    const [panelOpen, setPanelOpen] = useState(false);

    const handleClose = () => {
        setPanelOpen(false);
        onClose();
    }

    useEffect(() => {
        if (open) setPanelOpen(true);
    }, [open])

    return (
        <Modal
            open={panelOpen}
            onClose={handleClose}
            showClose
            title="Chat"
            children={<Chats />}
            sx={{'& .MuiPaper-root': {maxWidth: '100%', width: '100vw', height: '100vh', background: 'var(--background)'}}}
            />
    )
}

export default ChatPanel

const Chats: FC = () => {

    const chats = useSelector(selectChats);
    const firebaseUser = useSelector(selectFirebaseUser);
    const [selectedChat, setSelectedChat] = useState<string>(chats[0].id);

    useEffect(() => {
        markMessagesAsReadWithTimestamp(selectedChat)
    }, [selectedChat]);

    useEffect(() => {
        if (!selectedChat) return;

        return () => {
            setTypingStatus(selectedChat, firebaseUser.user_id, false);
        };
    }, [selectedChat, firebaseUser.user_id]);

    return (
        <Box 
            display="grid" 
            gridTemplateColumns="2fr 4fr" 
            height="100%" 
            borderRadius="6px" 
            border="solid 1px var(--border-subtle-01)" 
            overflow="hidden"
            >

            {/* Chats list */}
            <Box 
                display="flex" 
                flexDirection="column" 
                alignItems="flex-start" 
                justifyContent="flex-start" 
                borderRight="solid 1px var(--border-subtle-01)"
                bgcolor="var(--layer-01)"
                >
                {chats.map((chat, index) => 
                    <Chat
                        key={chat.id} 
                        chat={chat} 
                        chatIndex={index}
                        isSelected={chat.id === selectedChat}
                        onSelect={setSelectedChat}
                        />
                )}
            </Box>

            {/* Messages */}
            <Messages chat={chats.find((chat) => chat.id === selectedChat) as IChat} />

        </Box>
    )
}