import { MacroKeys } from "@/interfaces/nutrition/nutrition_builder";
import { IClientNutrition } from "@/interfaces/client/client_nutrition";


export const roundNumber = (num: number, decimalPlaces: number) => {
    if (num === 0) return 0;
    const rounded = parseFloat(num.toFixed(decimalPlaces));
    return Number.isInteger(rounded) ? parseFloat(rounded.toFixed(0)) : rounded;
};

export const totalMacro = (meals: IClientNutrition['meals'], macro: MacroKeys) => {
    if (!meals) return 0;
    return roundNumber(meals.reduce((acc, meal) => acc + (meal[macro] as number), 0), 0);
}

export const convertToPounds = (value: number) => {
    if (value == 0) return 0;
    return roundNumber(value * 2.20462, 1);
}

export const formatNumber = (value: number) => {
    if (value > 0) return `+${value}`;
    if (value < 0) return value.toString();
    return '';
}

export const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1000;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const isMegabytes = bytes / 1000 > 1000;
    return parseFloat((bytes / Math.pow(k, i)).toFixed(isMegabytes ? 2 : 0)) + ' ' + sizes[i];
}

export const formatAmount = (value: number) => {
    const formattedValue = value.toFixed(2);
  
    if (formattedValue.endsWith('.00')) {
      return value.toFixed(0);
    } else if (formattedValue.endsWith('0')) {
      return value.toFixed(2);
    } else {
      return formattedValue;
    }
}