import { BaseSyntheticEvent, FC, useEffect, useMemo } from "react";

// Services and interfaces
import { IMessage, MediaType } from "@/interfaces/messaging/messaging";
import { useMessageMediaContext } from "@/contexts/message_media_context";

// Styles
import { ArrowLeft, ArrowRight, Close } from "@carbon/icons-react";

// Components
import Lightbox from "./lightbox";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@/components/icon_button";


interface _LightboxDialogProps {
    messages: IMessage[];
    onEndReached: () => void;
}

const LightboxDialog: FC<_LightboxDialogProps> = ({
    messages,
    onEndReached
}) => {

    const filteredMedia = useMemo(() => {
        return messages?.filter((message) => message.media && ['image', 'video'].includes(message.type)) ?? [];
    }, [messages]);

    const { lightboxState, setLightboxState } = useMessageMediaContext();
    const idx = filteredMedia.findIndex(d => d.id === lightboxState);

    const prev = () => setLightboxState(filteredMedia[idx-1].id)
    const next = () => setLightboxState(filteredMedia[idx+1].id)

    useEffect(() => {
        if (idx !== -1 && idx === filteredMedia.length - 1) {
            onEndReached()
        }
    }, [idx])

    const handleClose = (e: BaseSyntheticEvent) => {
        if (!e.target.className.includes('MuiBackdrop-root')) return;
        setLightboxState(null)
    }

    if (idx === -1 || !filteredMedia[idx].media) return <></>

    return (
        <Backdrop 
            onClick={handleClose}
            open={lightboxState != null}
            sx={{ zIndex: 10000, p: 2, bgcolor: '#161616E5' }}
            >
            {/* actions box */}
            <Box>
                <IconButton 
                    sx={{position: 'absolute', left: 0, mx: 3, zIndex: 1000}}
                    icon={<ArrowLeft />}
                    disabled={idx === 0}
                    onClick={prev}
                    />
                
                <IconButton 
                    sx={{position: 'absolute', right: 0, mx: 3, zIndex: 1000}}
                    icon={<ArrowRight />} 
                    disabled={idx === (filteredMedia?.length ?? 0) - 1} 
                    onClick={next} 
                    />
                
                <IconButton 
                    sx={{position: 'absolute', top: 0, right: 0, m: 3, zIndex: 1000}}
                    icon={<Close />} 
                    onClick={()=>setLightboxState(null)}
                    />
            </Box>

            <Lightbox
                id={filteredMedia[idx].id}
                source={filteredMedia[idx].media ?? ''}
                type={filteredMedia[idx].type as MediaType}
            />
        </Backdrop>
    );
}

export default LightboxDialog;