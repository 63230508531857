import { useSelector } from "react-redux";
import { FC, SyntheticEvent, useState } from "react";

// Services and interfaces
import { IMessageReaction } from "@/interfaces/messaging/messaging";
import { deleteReaction } from "@/services/messaging/message_service";
import { selectFirebaseId, selectFirebaseUser } from "@/store/messaging";

// Components
import { Box } from "@mui/material";
import Avatar from "@/components/avatar";
import Popover from "@/components/popover";


interface _MessageAcctionsProps {
    chat_id: string;
    message_id: string;
    sender_id: string;
    reactions?: IMessageReaction[];
}

const MessageReactions: FC<_MessageAcctionsProps> = ({
    chat_id,
    message_id,
    sender_id,
    reactions,
}) => {

    const firebaseId = useSelector(selectFirebaseId);
    const isSender = sender_id === firebaseId;
    const firebaseUser = useSelector(selectFirebaseUser);
    const [anchor, setAnchor] = useState<HTMLElement|null>(null);
    const handleOpen = (e: SyntheticEvent<HTMLElement>) => setAnchor(e.currentTarget);
    const reaction = reactions?.find((reaction) => reaction.user_id === firebaseId);

    const getUniqueReactions = () => {
        if (!reactions || reactions.length === 0) return [];
        
        const reactionCounts: Record<string, number> = {};
        reactions.forEach((reaction) => {
            reactionCounts[reaction.emoji] = (reactionCounts[reaction.emoji] || 0) + 1;
        });
    
        return Object.entries(reactionCounts).map(([type, count]) => ({ type, count }));
    };

    const handleRemoveReaction = () => {
        if (!reaction) return;
        deleteReaction(chat_id, message_id, { user_id: firebaseId, emoji: reaction?.emoji });
    }

    return (
        <>
        <Box
            position="absolute"
            bottom="-4px"
            left={isSender ? '8px' : 'unset'}
            right={isSender ? 'unset' : '8px'}
            display="flex" 
            height="24px" 
            width="fit-content" 
            padding="0 8px"
            columnGap="8px"
            borderRadius="24px" 
            border={`solid 1px ${isSender ? 'var(--text-secondary)' : 'var(--border-subtle-01)'}`}
            bgcolor={isSender ? 'var(--nav-background)' : 'var(--layer-01)'}
            boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.15)"
            onClick={handleOpen}
            >
            {getUniqueReactions().map((reaction) => (
                <Box
                    key={reaction.type}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color={isSender ? 'var(--white)' : 'var(--text-secondary)'}
                    sx={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                    }}
                    >
                    <span className="PreventTextSelection">{reaction.type}</span>
                    {reaction.count > 1 && <span className="label-text-02 PreventTextSelection" style={{ marginLeft: '4px' }}>{reaction.count}</span>}
                </Box>
            ))}
        </Box>
        <Popover
            anchor={anchor}
            onClose={() => setAnchor(null)}
            >
            <Box display="flex" flexDirection="column">
                {/* Reactions */}
                <Box display="flex" borderBottom="solid 1px var(--nav-layer-03)">
                    {getUniqueReactions().map((reaction) => (
                        <Box
                            key={reaction.type}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            color="var(--text-primary)"
                            padding="4px 10px"
                            sx={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap',
                            }}
                            >
                            <span className="heading-05 PreventTextSelection">{reaction.type}</span>
                            {reaction.count > 1 && <span className="label-text-02 PreventTextSelection" style={{ marginLeft: '4px', color: isSender ? 'var(--white)' : 'var(--text-secondary)' }}>{reaction.count}</span>}
                        </Box>
                    ))}
                </Box>

                {/* Sender reaction */}
                {reaction && <Box 
                    display="flex" 
                    alignItems="center" 
                    padding="8px"
                    onClick={handleRemoveReaction} 
                    sx={{cursor: 'pointer'}}
                    >
                    <Avatar
                        size="small"
                        name={firebaseUser.name}
                        src={'avatarUrl'}
                        alt={firebaseUser.name}
                        />
                    <Box display="flex" flexDirection="column" ml="8px">
                        <span className="label-text-02 PreventTextSelection" style={{color: 'white', fontWeight: '600'}}>You</span>
                        <span className="label-text-03 PreventTextSelection" style={{color: 'var(--text-placeholder)'}}>Click to remove</span>
                    </Box>
                    <Box display="flex" flexGrow={1} />
                    <span className="heading-05 PreventTextSelection" style={{marginRight: '8px'}}>{reaction?.emoji}</span>
                </Box>}
            </Box>
        </Popover>
        </>
    )
}

export default MessageReactions;