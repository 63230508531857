import { t } from "i18next";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

// Services and interfaces
import { selectCoachId } from "@/store/coach";
import { IChat } from "@/interfaces/messaging/messaging";
import { selectHasTeam, selectTeamId } from "@/store/team";
import { selectContacts, selectFirebaseId } from "@/store/messaging";
import { IGroupForm, groupFormSchema } from "@/interfaces/messaging/group_form";
import { leaveGroup, newGroup, updateGroup } from "@/services/messaging/group_service";

// Styles
import { ArrowRight, ArrowUpRight, Exit, Misuse } from "@carbon/icons-react";

// Components
import Avatar from "@/components/avatar";
import Modal from "@/components/modal/modal";
import SearchInput from "@/components/search_input";
import { Box, Radio, RadioGroup } from "@mui/material";


interface _GroupModalProps {
    open: boolean;
    onClose: () => void;
    chat?: IChat|null;
    onSubmit: (contact: string) => void;
    onLeave?: (chat_id: string) => void;
}

const GroupModal: FC<_GroupModalProps> = ({
    open,
    onClose,
    chat,
    onSubmit,
    onLeave
}) => {

    const { t } = useTranslation();
    const firebaseId = useSelector(selectFirebaseId);
    const isGroupOwner = chat?.owner_id === firebaseId;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formMethods = useForm<IGroupForm>({
        resolver: zodResolver(groupFormSchema),
        mode: 'onChange',
        defaultValues: {
            contacts: Object.keys(chat?.participants ?? {}).filter((id) => id !== firebaseId)
        }
    });

    const getTitle = () => {
        if (chat && isGroupOwner) return t('modals.manageGroup.title');
        if (chat && !isGroupOwner) return chat.name ?? t('components.messaging.groupChat');
        return t('modals.newGroup.title');
    }

    const getText = () => {
        if (chat && isGroupOwner) return t('modals.manageGroup.text');
        if (chat && !isGroupOwner) return null;
        return t('modals.newGroup.text');
    }

    const handleClose = () => {
        formMethods.reset();
        onClose();
    }

    const handleCreateGroup = async (data: IGroupForm) => {
        setIsLoading(true);
        const chatId = await newGroup(data.contacts);
        formMethods.reset();
        onSubmit(chatId);
        setIsLoading(false);
    }

    const handleUpdateGroup = async (data: IGroupForm) => {
        if (!chat) return;
        setIsLoading(true);
        await updateGroup(chat.id, data.contacts);
        onClose();
        setIsLoading(false);
    }

    const handleLeaveGroup = async () => {
        if (!chat) return;
        setIsLoading(true);
        const chat_id = await leaveGroup(chat.id);
        onLeave?.(chat_id);
        setIsLoading(false);
    }

    return (
        <Modal 
            open={open}
            onClose={formMethods.formState.isDirty ? () => {} : handleClose}
            title={getTitle()}
            text={getText()}
            children={
                <FormProvider {...formMethods}>
                    {(!chat || isGroupOwner) ? <GroupForm /> : <MemberList />}
                </FormProvider>}
            action1={(!chat || isGroupOwner) ? {
                kind: 'primary',
                label: t(`components.buttons.${chat ? 'updateGroup' : 'createGroup'}`),
                icon: chat ? <ArrowUpRight /> : <ArrowRight />,
                loading: isLoading,
                disabled: !formMethods.formState.isValid,
                onClick: formMethods.handleSubmit(chat ? handleUpdateGroup : handleCreateGroup)
            } : {
                kind: 'danger-ghost',
                label: t('components.buttons.leaveGroup'),
                icon: <Exit />,
                loading: isLoading,
                onClick: handleLeaveGroup,
                sx: {minWidth: 'unset'}
            }}
            cancel={(!chat || isGroupOwner) ? {
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: handleClose
            } : undefined}
            sx={{'& .MuiPaper-root': {maxWidth: '448px', width: '100%', height: isGroupOwner ? '72vh' : 'unset', background: 'var(--background)'}}}
            />
    )
}

export default GroupModal;


const GroupForm: FC = () => {

    const teamId = useSelector(selectTeamId);
    const coachId = useSelector(selectCoachId);
    const hasTeam = useSelector(selectHasTeam);
    const contacts = useSelector(selectContacts);
    const isTeamOwner = useSelector(selectHasTeam);
    const { setValue, watch } = useFormContext<IGroupForm>();

    // Search form
    const formMethods = useForm({
        mode: 'onBlur',
        defaultValues: {
            search: '',
        }
    });
    const selectedContacts = watch('contacts');
    const search = formMethods.watch('search');

    const filteredContacts = contacts.filter((contact) =>
        !isTeamOwner ? contact.coach_id == coachId || (contact.team_id == teamId && contact.role == 'coach') : true &&
        contact.messaging_enabled && contact.name.toLowerCase().includes(formMethods.watch('search').toLowerCase())
    );

    const handleSelectContact = (id: string) => {
        if (selectedContacts.includes(id)) {
            return setValue('contacts', selectedContacts.filter((contact) => contact !== id), { shouldValidate: true });
        }
        setValue('contacts', [...selectedContacts, id], { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    }

    const handleRemoveContact = (id: string) => {
        if (!selectedContacts.includes(id)) return;
        return setValue('contacts', selectedContacts.filter((contact) => contact !== id), { shouldValidate: true });
    }

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            gap="12px"
            sx={{overflow: 'hidden'}}
            >

            <SearchInput 
                name="search"
                control={formMethods.control}
                disabled={contacts.length === 0}
                sx={{maxWidth: 'unset'}}
                />
            
            <RadioGroup
                defaultValue="standard"
                name="radio-buttons-group"
                sx={{width: '100%', rowGap: '8px'}}
                >
                <Box
                    display="flex" 
                    flexDirection="column" 
                    height="36vh" 
                    sx={{overflowY: 'auto'}}
                    >
                    {filteredContacts.map((contact, index) => (
                        <Box
                            key={contact.id}
                            display="flex" 
                            alignItems="center" 
                            padding="8px 16px"
                            onClick={handleSelectContact.bind(null, contact.id)} 
                            sx={{cursor: 'pointer'}}
                            >
                            <Avatar
                                size="medium"
                                index={index}
                                name={contact.name}
                                src={contact.avatar}
                                sx={{marginRight: '16px'}}
                                />
                            <span className="body-02-compact">{contact.name}</span>
                            <Box display="flex" flexGrow={1} />
                            <Radio 
                                value={contact.id}
                                size="small" 
                                checked={selectedContacts.includes(contact.id)} 
                                onClick={() => handleSelectContact(contact.id)}
                                sx={{padding: 0, marginLeft: '16px'}} 
                                />
                        </Box>
                    ))}
                    {filteredContacts.length == 0 && <Box 
                        display="flex" 
                        flexDirection="column"
                        height="100%"
                        width="100%"
                        alignItems="center" 
                        justifyContent="center" 
                        padding="16px" 
                        boxSizing="border-box"
                        >
                        {search ? 
                            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('components.messaging.noContactsFound', {context: `${hasTeam}`})}</span> :
                            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('components.messaging.noContacts', {context: `${hasTeam}`})}</span>}
                    </Box>}
                </Box>
            </RadioGroup>

            <Box display="flex" flexGrow={1} />

            {selectedContacts.length > 0 ? 
                <Box className="ScrollContainer__horizontal" display="flex" gap="8px" padding="8px" bgcolor="var(--layer-01)" border="solid 1px var(--border-subtle-01)" borderRadius="6px">
                    {selectedContacts.map((contact, index) => (
                        <Box key={contact} position="relative">
                            <Avatar 
                                size="medium"
                                index={index}
                                name={contacts.find((c) => c.id === contact)?.name || ''}
                                src={contacts.find((c) => c.id === contact)?.avatar || ''}
                                />
                            <Misuse
                                onClick={() => handleRemoveContact(contact)}
                                style={{
                                    position: 'absolute',
                                    top: '-3px', 
                                    right: '-3px', 
                                    borderRadius: '20px', 
                                    background: 'white', 
                                    color: 'var(--button-tertiary-active)',
                                    cursor: 'pointer'
                                }} />
                        </Box>
                    ))}
                </Box> : <Box height="42px" />}

        </Box>
    )
}

const MemberList: FC = () => {

    const contacts = useSelector(selectContacts);
    const firebaseId = useSelector(selectFirebaseId);

    const { watch } = useFormContext<IGroupForm>();
    const selectedContacts = watch('contacts');
    const participants = contacts.filter((contact) => contact.id !== firebaseId && selectedContacts.includes(contact.id));

    return (
        <Box
            display="flex" 
            flexDirection="column" 
            maxHeight="36vh"
            sx={{overflowY: 'auto'}}
            >
            {participants.map((contact, index) => (
                <Box
                    key={contact.id}
                    display="flex" 
                    alignItems="center" 
                    padding="8px 16px"
                    >
                    <Avatar
                        size="medium"
                        index={index}
                        name={contact.name}
                        src={contact.avatar}
                        sx={{marginRight: '16px'}}
                        />
                    <span className="body-02-compact">{contact.name}</span>
                </Box>
            ))}
        </Box>
    )
}