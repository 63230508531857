import { FC } from "react";
import ReactPlayer from "react-player";

// Styles
import { PlayFilledAlt } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import IconButton from "@/components/icon_button";


interface _MediaGridVideoProps {
    message_id: string;
    source: string;
    onMediaLoaded: (id: string) => void;
    onMediaPlay: () => void;
}

const MediaGridVideo: FC<_MediaGridVideoProps> = ({
    message_id,
    source,
    onMediaLoaded,
    onMediaPlay
}) => {

    return (
        <Box position="relative" display="inline-block" width="100%" height="100%">
            <ReactPlayer
                url={source} 
                width="100%"
                height="100%"
                onReady={() => onMediaLoaded(message_id)} 
                />
            <IconButton
                icon={<PlayFilledAlt color="white" />}
                onClick={() => onMediaPlay()}
                sx={{
                    position: 'absolute', 
                    top: '50%', 
                    left: '50%', 
                    zIndex: '1000', 
                    transform: 'translate(-50%, -50%)'
                }}
                />
        </Box>
    )
}

export default MediaGridVideo;