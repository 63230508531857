
import { t } from "i18next";
import { useSelector } from "react-redux";
import { FC, useEffect, useMemo, useRef, useState } from "react";

// Helpers
import { formatDateAsLongText } from "@/_helpers/date_functions";

// Services and interfaces
import { RootState } from "@/store/store";
import { listenForTyping } from "@/services/chat_listeners";
import { IChat, IMessage } from "@/interfaces/messaging/messaging";
import { setTypingStatus } from "@/services/messaging/chat_service";
import { selectChatMessages, selectFirebaseUser } from "@/store/messaging";
import { markMessagesAsReadWithTimestamp } from "@/services/messaging/message_service";

// Components
import Message from "./message";
import { Box } from "@mui/material";
import MediaGrid from "./media_grid";
import LightboxDialog from "./lightbox_dialog";
import TypingIndicator from "./typing_indicator";
import MessageInput, { IMessageReply } from "./message_input";


interface _MessagesProps {
    chat: IChat;
}

const Messages: FC<_MessagesProps> = ({
    chat,
}) => {

    const messages = useSelector((state: RootState) => selectChatMessages(state, chat.id));
    const firebaseUser = useSelector(selectFirebaseUser);
    const chatContainerRef = useRef<HTMLDivElement|null>(null);
    const [editMessage, setEditMessage] = useState<IMessage|null>(null);
    const [replyToMessage, setReplyToMessage] = useState<IMessageReply|null>(null);
    const [typingUsers, setTypingUsers] = useState<Record<string, boolean>>({});
    const isTyping = Object.entries(typingUsers).some(([id, typing]) => id !== firebaseUser.id && typing);
    const typingUsersList = Object.entries(typingUsers).filter(([id, typing]) => id !== firebaseUser.id && typing).map(([id]) => {
        return {
            id: id,
            name: chat.participants[id]?.name ?? t('components.messagining.unknownUser')
        }
    });
    const unreadMessages = messages.filter((message) => !message.read_by.includes(firebaseUser.id));

    const groupedMessages = useMemo(() => {
        const groups: Array<{ groupKey: string; _messages: IMessage[] }> = [];
        const groupMap = new Map<string, IMessage[]>();
    
        messages.forEach((message) => {
            const groupKey = message.media_group ?? message.id;
            if (!groupMap.has(groupKey)) {
                groupMap.set(groupKey, []);
            }
            groupMap.get(groupKey)!.push(message);
        });
    
        groupMap.forEach((_messages, groupKey) => {
            groups.push({ groupKey, _messages });
        });
    
        return groups;
    }, [messages]);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    const shouldShowDateHeader = (current: IMessage, previous: IMessage | null): boolean => {
        if (!previous) return true; // Always show for the first message
        return new Date(current.created_at).toDateString() !== new Date(previous.created_at).toDateString();
    };

    const handleSelectMessageToReply = (message: IMessage) => {
        setReplyToMessage({message: message, sender: chat.participants[message.sender_id].name});
    }

    useEffect(() => {
        if (!chat.id || !firebaseUser) return;

        const unsubscribeTyping = listenForTyping(chat.id, (typing) => {
            setTypingUsers(typing);
        });

        return () => {
            setTypingStatus(chat.id, firebaseUser.id, false);
            unsubscribeTyping();
        };
    }, [chat?.id, firebaseUser]);

    useEffect(() => {
        if (unreadMessages.length == 0) return
        markMessagesAsReadWithTimestamp(chat.id);
    }, [unreadMessages]);

    useEffect(() => {
        scrollToBottom();
    }, [messages, typingUsers]);

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px - 48px)" overflow="hidden">
            <Box
                ref={chatContainerRef}
                display="flex"
                flexDirection="column"
                height="90%"
                rowGap="16px"
                padding="16px"
                bgcolor="var(--layer-02)"
                sx={{ overflowY: "auto" }}
                >
                {groupedMessages.map(({ groupKey, _messages }) => {
                    const firstMessage = _messages[0];
                    const isMediaGroup = _messages.length > 1;

                    return (
                        <Box key={groupKey} display="flex" flexDirection="column">
                            {/* Show Date Header for the First Message in the Group */}
                            {shouldShowDateHeader(firstMessage, messages[messages.indexOf(firstMessage) - 1]) && (
                                <Box
                                    textAlign="center"
                                    margin="16px 0"
                                    padding="4px 8px"
                                    color="var(--text-secondary)"
                                    borderRadius="4px"
                                    className="date-header"
                                    >
                                    <span className="label-text-02">
                                        {formatDateAsLongText(firstMessage.created_at)}
                                    </span>
                                </Box>
                            )}

                            {/* Grouped Media Messages */}
                            {isMediaGroup ? 
                                <MediaGrid
                                    chat_id={chat.id}
                                    messages={_messages}
                                    participants={chat.participants}
                                    /> : 
                                <Message
                                    key={firstMessage.id}
                                    chat_id={chat.id}
                                    message={firstMessage}
                                    participants={chat.participants}
                                    onEdit={(message) => setEditMessage(message)}
                                    onReply={handleSelectMessageToReply}
                                />
                            }
                        </Box>
                    );
                })}

                {groupedMessages.length == 0 && 
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <span className="body-02" style={{color: 'var(--text-secondary)'}}>{t('components.messaging.noMessages')}</span>
                    </Box>}

                {isTyping && (
                    <TypingIndicator
                        name={typingUsersList[0].name}
                        typingCount={typingUsersList.length}
                    />
                )}
            </Box>

            <MessageInput
                chat_id={chat.id}
                user_id={firebaseUser.id}
                messageToEdit={editMessage}
                messageToReply={replyToMessage}
                onClearEdit={() => setEditMessage(null)}
                onClearReply={() => setReplyToMessage(null)}
                />

            {/* Media viewer */}
            <LightboxDialog
                messages={messages}
                onEndReached={() => {}}
                />
        </Box>
    )
}

export default Messages;