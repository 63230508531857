import { IChat, IMessage } from "@/interfaces/messaging/messaging"


export const loadingMessages: IMessage[] = [
    {id: '1', sender_id: '1', text: '', created_at: '', updated_at: '', read_by: [], read_timestamps: [{user_id: '1', read_at: ''}], type: 'text', reactions: []},
    {id: '2', sender_id: '1', text: '', created_at: '', updated_at: '', read_by: [], read_timestamps: [{user_id: '1', read_at: ''}], type: 'text', reactions: []},
    {id: '3', sender_id: '1', text: '', created_at: '', updated_at: '', read_by: [], read_timestamps: [{user_id: '1', read_at: ''}], type: 'text', reactions: []},
    {id: '4', sender_id: '1', text: '', created_at: '', updated_at: '', read_by: [], read_timestamps: [{user_id: '1', read_at: ''}], type: 'text', reactions: []},
    {id: '5', sender_id: '1', text: '', created_at: '', updated_at: '', read_by: [], read_timestamps: [{user_id: '1', read_at: ''}], type: 'text', reactions: []},
]

export const loadingChats: IChat[] = [
    {id: '1', type: 'direct', participants: {1: {id: '1', name: '', avatar: '', role: 'client', color: ''}}, messages: loadingMessages as any[], typing: {}, muted: false, last_message_at: ''},
    {id: '2', type: 'direct', participants: {1: {id: '1', name: '', avatar: '', role: 'client', color: ''}}, messages: loadingMessages as any[], typing: {}, muted: false, last_message_at: ''},
    {id: '3', type: 'direct', participants: {1: {id: '1', name: '', avatar: '', role: 'client', color: ''}}, messages: loadingMessages as any[], typing: {}, muted: false, last_message_at: ''},
    {id: '4', type: 'direct', participants: {1: {id: '1', name: '', avatar: '', role: 'client', color: ''}}, messages: loadingMessages as any[], typing: {}, muted: false, last_message_at: ''},
    {id: '5', type: 'direct', participants: {1: {id: '1', name: '', avatar: '', role: 'client', color: ''}}, messages: loadingMessages as any[], typing: {}, muted: false, last_message_at: ''},
]
