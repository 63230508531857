import { useSelector } from "react-redux";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { FC, ReactSVGElement, useState, useEffect, useRef } from "react";

// Services and interfaces
import { selectFirebaseId } from "@/store/messaging";
import { reactToMessage } from "@/services/messaging/message_service";

// Styles
import { FaceAdd, Reply } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";


interface _MessageAcctionsProps {
    chat_id: string;
    message_id: string;
    isSender: boolean;
    onReply: () => void;
}

const MessageActions: FC<_MessageAcctionsProps> = ({
    chat_id,
    message_id,
    isSender,
    onReply
}) => {

    const firebaseId = useSelector(selectFirebaseId);
    const emojiPickerRef = useRef<HTMLDivElement>(null);
    const [emojiPickerOpen, setEmojiPickerOpen] = useState<boolean>(false);

    const handleMouseEnter = (e: React.MouseEvent<ReactSVGElement>) => {
        const target = e.currentTarget as unknown as HTMLElement;
        target.style.transform = "scale(1.1)";
    }

    const handleMouseLeave = (e: React.MouseEvent<ReactSVGElement>) => {
        const target = e.currentTarget as unknown as HTMLElement;
        target.style.transform = "scale(1)";
    }

    const handleReactionClick = (reaction: EmojiClickData) => {
        reactToMessage(chat_id, message_id, {user_id: firebaseId, emoji: reaction.emoji});
        setEmojiPickerOpen(false);
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                emojiPickerRef.current &&
                !emojiPickerRef.current.contains(event.target as Node)
            ) {
                setEmojiPickerOpen(false);
            }
        };

        if (emojiPickerOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [emojiPickerOpen]);

    return (
        <Box
            ref={emojiPickerRef}
            className="_MessageActions" 
            position="relative" 
            display="flex"
            flexDirection={isSender ? 'row' : 'row-reverse'}
            columnGap="8px" 
            sx={{
                visibility: emojiPickerOpen ? 'visible' : 'hidden',
                opacity: emojiPickerOpen ? 1 : 0,
                transition: "visibility 0.2s, opacity 0.2s",
                ml: isSender ? '0px' : '16px', 
                mr: isSender ? '16px' : '0px', 
                mb: '18px'
            }}
            >
            <Reply size="20px" onClick={onReply}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                color="var(--text-secondary)"
                style={{
                    position: 'relative', 
                    cursor: 'pointer',
                    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                }} 
                />
            <FaceAdd size="20px" onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                color="var(--text-secondary)"
                style={{
                    position: 'relative', 
                    cursor: 'pointer',
                    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                }} 
                />
            {emojiPickerOpen && 
                <EmojiPicker
                    open={emojiPickerOpen}
                    reactionsDefaultOpen={true} 
                    onReactionClick={handleReactionClick}
                    onEmojiClick={handleReactionClick}
                    style={{
                        position: 'absolute', 
                        bottom: '30px', 
                        left: '-152px', 
                        borderRadius: '6px',
                        borderColor: 'white !important',
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                        zIndex: 1000,
                    }}
                    />}
        </Box>
    )
}

export default MessageActions;