import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Local - dev
// const firebaseConfig = {
//   apiKey: "AIzaSyBr1xwMyBK5_LaN2CmMJU6HtoTdj1hgkTs",
//   authDomain: "fit-messaging-dev.firebaseapp.com",
//   projectId: "fit-messaging-dev",
//   storageBucket: "fit-messaging-dev.firebasestorage.app",
//   messagingSenderId: "609397756813",
//   appId: "1:609397756813:web:71a82773502d2c121ba3c4"
// };

// Develop
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

export { db, storage };