import { FC, useRef } from "react";

// Helpers
import { formatFileSize } from "@/_helpers/number_functions";

// Services and interfaces
import { IMediaMetadata } from "@/interfaces/messaging/messaging";
import { downloadMedia } from "@/services/messaging/media_service";

// Styles
import { Warning } from "@carbon/icons-react";

// Components
import { Box, CircularProgress } from "@mui/material";
import { DefaultExtensionType, FileIcon, defaultStyles } from "react-file-icon";


interface _FileMessageProps {
    source?: string;
    upload_progress?: number;
    metadata?: IMediaMetadata;
}

const FileMessage: FC<_FileMessageProps> = ({ 
    source,
    upload_progress, 
    metadata,
}) => {

    const clickTimeout = useRef<NodeJS.Timeout|null>(null);
    const fileExtension = metadata?.extension as DefaultExtensionType;
    const hasFailed = upload_progress === -1;
    const showLoader = upload_progress != null && upload_progress >= 0 && !hasFailed;
    const isAvailable = upload_progress == null;

    const handleDownloadFile = () => {
        if (!source || clickTimeout.current) return;
        clickTimeout.current = setTimeout(() => {
            downloadMedia(source);
            clickTimeout.current = null;
        }, 300);
    }

    const handleDoubleClick = () => {
        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
            clickTimeout.current = null;
        }
    };

    return (
        <Box 
            display="flex" 
            width="100%" 
            alignItems="center" 
            position="relative"
            justifyItems="center"
            sx={{cursor: isAvailable ? 'pointer' : 'unset'}}
            onClick={handleDownloadFile}
            onDoubleClick={handleDoubleClick}
            >
            <Box 
                position="relative"
                display="inline-block"
                >
                {/* File with fallback for loader */}
                <Box display="flex" bgcolor="var(--layer-01)" padding="8px 8px" borderRadius="6px" alignItems="center">
                    <Box display="flex" width="30px" mr="8px">
                        <FileIcon
                            extension={fileExtension} {...defaultStyles[fileExtension]} />
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <span className="label-text-02 OneLine--ellipsis-breakWord PreventTextSelection" style={{color: 'var(--text-primary)'}}>{metadata?.filename}</span>
                        <span className="label-text-03" style={{color: 'var(--text-secondary)'}}>{fileExtension.toUpperCase()} - {formatFileSize(metadata?.size ?? 0)}</span>
                    </Box>
                    {showLoader && <Box display="flex" position="relative" ml="8px">
                        <CircularProgress 
                            variant="determinate"
                            size={36} 
                            thickness={3} 
                            value={100} 
                            sx={{color: 'var(--text-placeholder)'}}/>
                        <Box
                            position="absolute"
                            top="50%"
                            left="50%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{transform: 'translate(-50%, -50%)'}}
                            >
                            <span style={{ fontSize: '10px', fontWeight: 'bold', color: 'var(--text-secondary)' }}>
                                {Math.round(upload_progress ?? 0)}%
                            </span>
                        </Box>
                    </Box>}
                </Box>

                {/* Error Icon if Upload Failed */}
                {hasFailed && (
                    <Box position="absolute" top="50%" left="50%" sx={{ color: "var(--background-brand)" }}>
                        <Warning size="40px" style={{ color: 'var(--support-error)' }} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default FileMessage;