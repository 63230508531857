import { FC, useRef, useState } from "react"

// Styles
import { OverflowMenuVertical } from "@carbon/icons-react";

// Components
import Menu, { Options } from "@/components/menu";
import IconButton from "@/components/icon_button";


interface OverflowMenuProps {
    kind?: 'primary'|'secondary'|'tertiary'|'ghost'|'danger'|'danger-secondary'|'danger-ghost';
    size?: 'small'|'medium'|'large';
    position?: { 
        horizontal: 'center'|'left'|'right'|number, 
        vertical: 'bottom'|'center'|'top'|number 
    }
    options?: Options[];
    deleteLabel?: string;
    disableDelete?: boolean;
    disabled?: boolean;
    onDelete?: (() => void);
}

const OverflowMenu: FC<OverflowMenuProps> = ({
    kind = 'ghost',
    size = 'small',
    position = { horizontal: 'right', vertical: 'bottom' },
    options,
    deleteLabel,
    disableDelete = false,
    disabled = false,
    onDelete
}) => {

    const [open, setOpen] = useState<boolean>(false);
    const anchor = useRef<HTMLButtonElement>(null);

    return (
        <>
            <IconButton 
                ref={anchor} 
                kind={kind} 
                size={size} 
                icon={<OverflowMenuVertical />}
                disabled={disabled}
                onClick={() => setOpen(true)}
                sx={{boxShadow: open ? '0px 2px 6px 0px rgba(0, 0, 0, 0.30)' : null}}
            />
            <Menu
                size={size}
                open={open}
                onClose={() => setOpen(false)}
                anchorEl={anchor?.current}
                position={position}
                options={options ?? []}
                deleteLabel={deleteLabel}
                disableDelete={disableDelete}
                onDelete={onDelete}
            />
        </>
    )

}

export default OverflowMenu;