import { FC } from "react";

// Components
import { Box, Container } from "@mui/material";
import MessagingSettings from "./messaging/settings";


const MessagingPage: FC = () => {

    return (
        <Container sx={{py: '24px'}}>

            <Box display="flex" flexDirection="column" justifyContent="flex-start" rowGap="24px" paddingBottom="40px">

                <MessagingSettings />

            </Box>
        </Container>
    )
}

export default MessagingPage;