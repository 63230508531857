import { ApiError } from '@/interfaces/api/error';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axiosLib, { AxiosRequestConfig, AxiosResponse } from 'axios';

const API_URL = import.meta.env.VITE_API_URL;

interface ErrorResponse {
    message?: string;
    response?: {
        status: number;
        statusText?: string | undefined;
        data?: {
            data?: any;
            message?: string;
            status?: string;
            errors?: any;
            type?: string;
        },
        config?: {
            url?: string;
            method?: string;
        }
    };
  }

function onSuccess(res: AxiosResponse): AxiosResponse {
    return res;
}

function onError(error: any): void {
    const err = {} as ApiError;
    // Error Response indicates a laravel error
    if (error as ErrorResponse) {
        err.name = error.response?.statusText || "Error";
        err.message = error.response?.data?.message || "An unknown error occurred";
        err.code = error.response.status.toString();
        err.errors = error.response?.data?.errors || null;
        // err.stack = JSON.stringify(error.response?.data?.errors || {});
        err.type = error.response?.data?.type || "unexpected_error";
    } else {
        err.message = "An unexpected error occurred"
    }
    throw err;
}

const axios = axiosLib.create({
    baseURL: API_URL,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
});

axios.interceptors.response.use(onSuccess, onError)

export default axios;

const axiosBaseQuery =
  (): BaseQueryFn<
    {
        url: string;
        baseUrl?: string;
        method?: AxiosRequestConfig['method'];
        data?: AxiosRequestConfig['data'];
        params?: AxiosRequestConfig['params'];
        headers?: AxiosRequestConfig['headers'];
        withCredentials?: AxiosRequestConfig['withCredentials'];
        onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
    },
    unknown,
    ApiError
  > =>
  async ({ url, baseUrl, method, data, params, headers, withCredentials, onUploadProgress }) => {
    try {
    const result = await axios({
        url,
        baseURL: baseUrl || axios.defaults.baseURL,
        method,
        data,
        params,
        headers,
        withCredentials,
        onUploadProgress
    })
    return { data: result.data }
    } catch (error) {
        return { error: error as ApiError};
    }
}

export { axiosBaseQuery };