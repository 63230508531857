import { db } from "@/_lib/firebase";
import { doc, getDoc } from "firebase/firestore";
import { getColorForName } from "@/_helpers/colour_functions";
import { IFirebaseUser } from "@/interfaces/messaging/messaging";
import { getAuth, signInWithCustomToken, onAuthStateChanged, User as FirebaseUser } from "firebase/auth";


const auth = getAuth();

export const authenticateFirebase = async (token: string) => {
    try {
        const userCredential = await signInWithCustomToken(auth, token);
        return userCredential.user;
    } catch (error) {
        console.error("Firebase Authentication failed:", error);
        throw error;
    }
};

export const monitorAuthState = (callback: (user: FirebaseUser|null) => void) => {
    onAuthStateChanged(auth, (user) => {
        callback(user);
    });
};

export const syncUserWithFirestore = async (uid: string) => {
    const userRef = doc(db, "users", uid);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
        const userDoc: IFirebaseUser = {
            id: docSnap.id,
            user_id: docSnap.data().user_id,
            name: docSnap.data().name,
            email: docSnap.data().email,
            avatar: docSnap.data().avatar,
            color: getColorForName(docSnap.data().name),
        };
        return userDoc;
    } else {
        throw new Error("Document does not exist after syncing");
    }
};