import { t } from "i18next";
import { FC, ReactNode } from "react";

// Constants
import { allowedFileTypes } from "@/_constants/file_type";

// Styles
import { Folders, Add, Image } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import { Options } from "@/components/menu";
import MenuIconButton from "@/components/menu_icon_button";


interface _MessageMediaButtonProps {
    disabled?: boolean;
    onSelectMedia: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MessageMediaButton: FC<_MessageMediaButtonProps> = ({
    disabled = false,
    onSelectMedia
}) => {

    const renderMenuItem = (icon: ReactNode, label: string): ReactNode => {
        return (
            <Box display="flex" alignItems="center">
                {icon}
                <span className="body-02-compact" style={{marginLeft: '8px'}}>{label}</span>
            </Box>
        )
    }
    
    const menuOptions: Options[] = [
        {
            name: t('components.menuItems.file'), 
            render: () => renderMenuItem(<Folders size="20px" />, t('components.menuItems.file')),  
            action: () => document.getElementById("file-upload-docs")?.click()
        },
        {
            name: t('components.menuItems.photosAndVideos'), 
            render: () => renderMenuItem(<Image size="20px" />, t('components.menuItems.photosAndVideos')) , 
            action: () => document.getElementById("file-upload-media")?.click()
        },
    ];

    return (
        <>
        <MenuIconButton
            kind="primary"
            size="medium"
            icon={<Add />}
            position={{horizontal: 'left', vertical: 'top'}}
            options={menuOptions}
            disabled={disabled}
            sx={{mr: '12px'}}
            />
            {/* Hidden input for files */}
            <input
                type="file"
                id="file-upload-docs"
                accept={allowedFileTypes}
                multiple
                style={{ display: 'none' }}
                onChange={onSelectMedia}
            />
            {/* Hidden input for photos and videos */}
            <input
                type="file"
                id="file-upload-media"
                accept="image/*,video/*"
                multiple
                style={{ display: 'none' }}
                onChange={onSelectMedia}
            />
        </>
    )
}

export default MessageMediaButton;