import { FC, useEffect, useRef, useState } from "react";

// Styles
import { Pause, PlayFilledAlt } from "@carbon/icons-react";


interface _AudioPlayerProps {
    audioURL: string;
}

const AudioPlayer: FC<_AudioPlayerProps> = ({ audioURL }) => {
    
    const audioRef = useRef<HTMLAudioElement|null>(null);
    const[isPlaying, setIsPlaying] = useState(false);

    audioRef.current?.addEventListener('ended', () => {
        setIsPlaying(false);
    });

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.src = audioURL;
            audioRef.current.load();
        }
    }, [audioURL]);

    const handlePlay = () => {
        if (audioRef.current) {
            audioRef.current.play().then(() => {
                setIsPlaying(true);
            }).catch((error) => {
                console.error("Error playing audio:", error);
            });
        }
    };

    const handlePause = () => {
        if (audioRef.current) {
            audioRef.current.pause();
        }
    }

    return (
        <>
            <audio ref={audioRef} src={audioURL} style={{ display: "none" }} />
            {isPlaying ? 
                <Pause color="var(--text-primary)" onClick={handlePause} style={{marginRight: '8px', cursor: 'pointer'}} /> :
                <PlayFilledAlt color="var(--text-primary)" onClick={handlePlay} style={{marginRight: '8px', cursor: 'pointer'}} />}
        </>
    );
}

export default AudioPlayer;