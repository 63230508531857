import { z } from "zod";
import { ISiteSettings } from "./site_settings";
import { IClientSettings } from "./client_settings";

export interface ISettings {
    client_settings: IClientSettings,
    site_settings: ISiteSettings,
    messaging: boolean;
    notifications: INotificationSettings;
}

export interface INotificationSettings {
    messaging: boolean;
}

export interface INotificationSettingsForm {
    messaging: boolean;
}

export const notificationSettingsFormSchema = z.object({
    messaging: z.boolean(),
});