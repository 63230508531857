import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IExercise } from "@/interfaces/exercise/exercise";
import { IExerciseForm, exerciseFormSchema } from "@/interfaces/exercise/exercise_form";
import { useAddExerciseMutation, useUpdateExerciseMutation, useUploadVideoMutation } from "@/repositories/exercise";

// Components
import ExerciseForm from "./exercise_form";
import Modal from "@/components/modal/modal";


interface ExerciseModalProps {
    open: boolean;
    onClose: () => void;
    exercise?: IExercise;
}

const ExerciseModal: FC<ExerciseModalProps> = ({
    open,
    onClose,
    exercise
}) => {

    const { t } = useTranslation();
    const [addExercise, { isLoading: isAddingExercise }] = useAddExerciseMutation();
    const [updateExercise, { isLoading: isUpdatingExercise }] = useUpdateExerciseMutation();
    const [uploadVideo, { isLoading: isUploadingVideo }] = useUploadVideoMutation();

    // const [requestUploadLink] = useLazyUploadRequestQuery();

    const formMethods = useForm<IExerciseForm>({
        resolver: zodResolver(exerciseFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: exercise?.name ?? '',
            type: exercise?.type ?? 'resistance',
            metrics: exercise?.metrics ?? {weight: true, reps: true, duration: false, distance: false, calories: false},
            bodypart: exercise?.bodypart ?? '',
            category: exercise?.category ?? '',
            machine_type: exercise?.machine_type ?? '',
            machine_brand: exercise?.machine_brand ?? '',
            instructions: exercise?.instructions ?? '',
            video_link: exercise?.video_link ?? '',
            media_link: exercise?.media_link ?? '',
        }
    });
    const { reset, watch, setValue, getFieldState, handleSubmit, formState: { isDirty } } = formMethods;
    const type = watch('type');

    useEffect(() => {
        if (getFieldState('type').isDirty) setValue('metrics', null, { shouldDirty: true });
    }, [type])

    const handleClose = () => {
        reset();
        onClose();
    }

    const submitAddExercise = (data: IExerciseForm) => {
        addExercise(data).unwrap().then((exercise) => {
            uploadMedia(exercise.id, data.media_link).then(() => {
                showToast({
                    type: 'success', 
                    title: t('notifications.exercise.created.title'), 
                    message: t('notifications.exercise.created.message', {name: exercise.name})
                });
                handleClose();
            })
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const submitUpdateExercise = (data: IExerciseForm) => {
        if (!exercise) return;
        updateExercise({id: exercise.id, data: data}).unwrap().then((exercise) => {
            uploadMedia(exercise.id, data.media_link).then(() => {
                showToast({type: 'success', title: t('notifications.exercise.updated.title'), message: t('notifications.exercise.updated.message', {name: exercise.name})});
                handleClose();
            })
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const uploadMedia = async (exId: string, video?: string|URL) => {
        if (!video || !(video as string).startsWith('blob:')) return;
        const data = new FormData();
        const blob = await fetch(video).then(r => r.blob());
        data.append('video', blob)
        await uploadVideo({id: exId, data}).unwrap()
    }

    return (
        <Modal
            open={open}
            onClose={isDirty ? undefined : onClose}
            title={exercise ? exercise.name : t('modals.addExercise')}
            children={
                <FormProvider {...formMethods}>
                    <ExerciseForm libraryType={exercise?.library} />
                </FormProvider>
            }
            action1={{
                label: t('components.buttons.save'),
                disabled: !isDirty || !formMethods.formState.isValid,
                loading: isAddingExercise || isUpdatingExercise || isUploadingVideo,
                onClick: handleSubmit(exercise ? submitUpdateExercise : submitAddExercise),
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isAddingExercise || isUpdatingExercise || isUploadingVideo,
                onClick: handleClose
            }}
        />
    )
}

export default ExerciseModal;