import { z } from "zod";
import ruleset from "@/_helpers/ruleset";


export interface INewMessageForm {
    contact: string;
}

export const newMessageFormSchema = z.object({
    contact: ruleset.required
});