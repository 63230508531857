import dayjs from "dayjs";
import { t } from "i18next";


export function formatDateAsText(date: string): string {
    if (!date || date === '') return t('timeDate.today');
    if (dayjs().isSame(dayjs(date.substring(0,10)), 'day')) {
        return t('timeDate.today');
    } else if (dayjs().subtract(1, 'day').isSame(dayjs(date.substring(0,10)), 'day')) {
        return t('timeDate.yesterday');
    } else if (dayjs().add(1, 'day').isSame(dayjs(date.substring(0,10)), 'day')) {
        return t('timeDate.tomorrow');
    } else {
        return dayjs().startOf('day').to(date.substring(0,10)); // Default to relative time
    }
}

export function getOrdinalSuffix(n: number): string {
    const value = n % 100;
  
    if (value >= 11 && value <= 13) {
      return `${n}th`;
    }
  
    switch (value % 10) {
      case 1:
        return `${n}st`;
      case 2:
        return `${n}nd`;
      case 3:
        return `${n}rd`;
      default:
        return `${n}th`;
    }
}

/**
 * Returns the date in the format day name day with ordinal suffix month, e.g. Wednesday 24th December.
 * Includes the year if the year is not the current year.
 * @param date The date to format.
 */

export function formatDateAsLongText(date: string): string {
    const day = dayjs(date);
    const year = dayjs().year() === day.year() ? '' : day.year();
    return `${day.format('dddd')} ${getOrdinalSuffix(day.date())} ${day.format('MMMM')} ${year}`;
}

/**
 * Returns the date in string form of yy_mm_dd_hhmmssms.
 * @param date The date to format.
 */

export function formatHumanReadableDateString() {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  const millis = String(now.getMilliseconds()).padStart(3, "0");

  return `${year}_${month}_${day}_${hours}${minutes}${seconds}${millis}`;
}

export function formatDateTimeShort(date: string): string {
  const day = dayjs(date).format('DD MMM');
  const time = dayjs(date).format('HH:mm');
  return t('timeDate.textOutputs.dateAtTimeShort', { date: day, time: time });
}