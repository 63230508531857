import { FC } from "react";

// Interfaces
import IconAction from "@/interfaces/components/icon_action";
import ButtonAction from "@/interfaces/components/button_action";

// Styles
import { ArrowLeft } from "@carbon/icons-react";

// Components
import Tooltip from "./tooltip";
import Box from "@mui/material/Box";
import Tag, { TagProps } from "./tag";
import Tabs, { TabProps } from "./tabs";
import Button from "@/components/button";
import { Skeleton } from "@mui/material";
import Container from "@mui/material/Container";
import NavHeaderAction from "./navigation/nav_header_action";
import Toolbar, { ToolbarOwnProps } from "@mui/material/Toolbar";

interface ToolbarProps {
    kind?: 'primary' | 'secondary' | 'tertiary' | 'ghost';
    title?: string;
    tags?: TagProps[];
    tabs?: TabProps[];
    initialTab?: string|null;
    onBack?: (() => void);
    titleAction?: IconAction;
    action1?: ButtonAction;
    action2?: ButtonAction;
    iconActions?: IconAction[];
    slot?: React.ReactNode;
    isLoading?: boolean;
    sx?: ToolbarOwnProps['sx']
}

const SecondaryToolbar: FC<ToolbarProps> = ({
    kind = 'primary',
    title = '',
    tags,
    tabs, 
    initialTab,
    onBack,
    titleAction,
    action1,
    action2,
    iconActions,
    slot,
    isLoading = false,
    sx
}) => {

    return (
        <Toolbar className={`SecondaryToolbar__${kind}`} sx={sx}>
            <Container sx={{display: 'flex', height: '100%', alignItems: 'center', padding: 0}}>

                {!!onBack && <NavHeaderAction
                    kind="primary" 
                    icon={<ArrowLeft />}
                    onClick={onBack}
                    sx={{mr: 2}}
                />}

                {/* Title and action */}
                {isLoading ? <Skeleton variant="text" width={200} height={28} /> : 
                    <span className='heading-05 text-primary' style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{ title }</span>}
                    
                {titleAction && <NavHeaderAction
                    kind="primary"
                    icon={titleAction.icon}
                    disabled={isLoading}
                    onClick={titleAction.onClick}
                    sx={{ml: 1}}
                />}

                {/* Tabs */}
                {tabs && tabs.length > 0 && (
                    <Tabs tabs={tabs} initialTab={initialTab} />
                )}

                {/* Tags */}
                {tags && tags.length > 0 && (
                    <Box display='flex' gap={1} mx={2} alignItems='center'>
                        {tags.map((t,i) => <Tag key={i} {...t} />)}
                    </Box>
                )}

                <Box flexGrow={1} />

                {/* Icon Actions */}
                {iconActions?.map((action, index) => (
                    <Tooltip key={index} kind="nav" placement="bottom" title={action.tooltip}>
                        <NavHeaderAction
                            kind="primary"
                            icon={action.icon}
                            disabled={isLoading}
                            onClick={action.onClick}
                        />
                    </Tooltip>
                ))}

                {/* Spacer */}
                <Box width="8px" />

                {/* Slot */}
                {slot}
                
                {/* Actions */}
                {action2 && <Button
                    kind={action2?.kind ?? 'tertiary'}
                    size="small"
                    label={action2?.label}
                    endIcon={action2?.icon}
                    disabled={action2?.disabled || isLoading}
                    loading={action2?.loading}
                    onClick={action2?.onClick}
                    sx={{...action2?.sx, ml: 1}}
                    />}

                {action1 && <Button
                    size="small"
                    label={action1?.label}
                    endIcon={action1?.icon}
                    disabled={action1?.disabled || isLoading}
                    loading={action1?.loading}
                    onClick={action1?.onClick}
                    sx={{...action1?.sx, ml: 1}}
                    />}

            </Container>
        </Toolbar>
    );
}

export default SecondaryToolbar;