import { CSSProperties, FC, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

// Services and interfaces
import { downloadQuery } from "@/repositories/media";

// Styles
import { Download, Rotate, ZoomIn, ZoomOut } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import IconButton from "@/components/icon_button";

interface ImageProps {
    src: string
    alt? :string
    showDownload?: boolean
}

const Image: FC<ImageProps> = ({
    src,
    alt,
    showDownload = false
}) => {

    const [rotation, setRotation] = useState(0);
    const isPortrait = Math.abs(rotation) % 180 !== 0;

    const rotateLeft = () => setRotation((prev) => prev - 90);
    const rotateRight = () => setRotation((prev) => prev + 90);

    // required as content-fit defaults cause images to be cropped
    const transformStyle: CSSProperties = {
        height: '100%', 
        width: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
    }

    return (
        <Box height='100%' overflow='hidden' position='relative' bgcolor='#000000bb' minWidth={400} width='100%' maxWidth={1200}>
        <TransformWrapper centerOnInit>
        {({ zoomIn, zoomOut }) => (
        <>
            {/* Optional download button */}
            {showDownload && <Box sx={{
                position: 'absolute',
                p: 2, top: 0, left: 0,
                display: 'flex', gap: 1,
                flexDirection: 'column',
                zIndex: 1000,
            }}>
                <IconButton 
                    size="small"
                    icon={<Download />}
                    onClick={()=>downloadQuery(src)}
                    />
            </Box>}

            {/* Zoom buttons */}
            <Box sx={{
                position: 'absolute',
                p: 2, bottom: 0, left: 0,
                display: 'flex', gap: 1,
                flexDirection: 'column',
                zIndex: 1000,
            }}>
                <IconButton size='small' icon={<ZoomIn />} onClick={() => zoomIn()} />
                <IconButton size='small' icon={<ZoomOut />} onClick={() => zoomOut()} />
            </Box>

            {/* Image */}
            <TransformComponent wrapperStyle={transformStyle} contentStyle={transformStyle}>
            <Box
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                >
                <img
                    src={src}
                    alt={alt}
                    style={{
                        objectFit: "contain",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
                        transition: "transform 0.3s ease",
                        maxWidth: isPortrait ? "95vh" : "100%",
                        maxHeight: isPortrait ? "95vw" : "100%",
                    }}
                    />
            </Box>
            </TransformComponent>

            {/* Rotate buttons */}
            <Box sx={{
                position: 'absolute',
                p: 2, bottom: 0, right: 0,
                display: 'flex', gap: 1,
                flexDirection: 'column',
                zIndex: 1000,
            }}>
                <IconButton size='small' icon={<Rotate />} onClick={rotateLeft} />
                <IconButton size='small' icon={<Rotate style={{transform: 'scaleX(-1)'}} />} onClick={rotateRight} />
            </Box>
        </>
        )}
        </TransformWrapper>
        </Box>
    );
};

export default Image;