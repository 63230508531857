import { createContext, FC, ReactNode, useContext, useState } from "react";


interface _IMessageMediaContext {
    lightboxState: string|[string, string]|null;
    setLightboxState: (value: string|[string, string]|null) => void;
}

interface ProviderProps {
    children?: ReactNode|ReactNode[]
}

const MessageMediaContext = createContext<_IMessageMediaContext|undefined>(undefined);

const useMessageMediaContext = () => {
    const context = useContext(MessageMediaContext);
    if (!context) throw Error('Message media context not initialized!')
    return context;
};

const MessageMediaProvider: FC<ProviderProps> = ({ children }) => {

    const [lightboxState, setLightboxState] = useState<string|[string, string]|null>(null);

    return (
        <MessageMediaContext.Provider value={{ 
            lightboxState,
            setLightboxState,
        }}>
            {children}
        </MessageMediaContext.Provider>
    );
};
  
export { MessageMediaProvider, useMessageMediaContext };
export default MessageMediaContext;