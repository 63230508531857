import { t } from "i18next";
import { useSelector } from "react-redux";
import { FC, ReactNode, RefObject, useRef, useState } from "react";

// Context
import { useMessageMediaContext } from "@/contexts/message_media_context";

// Services and interfaces
import { selectFirebaseId } from "@/store/messaging";
import { downloadMedia } from "@/services/messaging/media_service";
import { IMessage, IParticipant } from "@/interfaces/messaging/messaging";

// Styles
import { ChevronDown } from "@carbon/icons-react";
import { DoneAll, Done } from "@mui/icons-material";

// Components
import Avatar from "@/components/avatar";
import MediaLoader from "./media_loader";
import MediaGridVideo from "./media_grid_video";
import Menu, { Options } from "@/components/menu";
import DeleteMessageModal from "./delete_message";
import { Box, CircularProgress, Skeleton } from "@mui/material";


interface _MediaGridProps {
    chat_id: string;
    messages: IMessage[];
    participants: Record<string, IParticipant>;
}

const MediaGrid: FC<_MediaGridProps> = ({
    chat_id,
    messages,
    participants,
}) => {

    const anchor = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [mediaLoaded, setMediaLoaded] = useState<Record<string, boolean>>({});
    const {setLightboxState} = useMessageMediaContext();

    const firebaseId = useSelector(selectFirebaseId);
    const senderId = messages[0].sender_id;
    const isSender = senderId == firebaseId;
    const avatarUrl = participants[senderId].avatar;
    
    const read = Object.keys(participants).filter((participantId) => 
        senderId !== participantId &&
            messages[0].read_by && !messages[0].read_by.some((user) => 
                user === participantId)).length === 0;
    const sent_at = new Date(messages[0].created_at).toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})
    const edited_at = messages[0].updated_at && new Date(messages[0].updated_at).toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})
    const messageColor = isSender ? 'var(--nav-background)' : 'var(--layer-01)';
    const textColor = isSender ? 'var(--white)' : 'var(--text-primary)';

    const getReadMarker = (): ReactNode => {
        if (!isSender) return null;
        if (read) return <DoneAll sx={{height: '14px', color: 'var(--text-placeholder)'}} />;
        return <Done sx={{height: '14px', color: 'var(--text-placeholder)'}} />;
    };

    const handleMediaLoaded = (message_id: string) => {
        setMediaLoaded((prev) => ({...prev, [message_id]: true}));
    };
    
    return (
        <Box
            display="flex"
            flexDirection={isSender ? "row-reverse" : "row"}
            alignSelf={isSender ? 'flex-end' : 'flex-start'}
            width="100%" maxWidth="45%"
            alignItems="flex-start"
            >
            <Avatar
                size="small"
                name={participants[senderId].name}
                src={avatarUrl}
                alt={participants[senderId].name}
                sx={{marginLeft: isSender ? '8px' : '0', marginRight: isSender ? '0' : '8px'}}
                />

            {/* Media Grid */}
            <Box display="flex" flexDirection="column" width="100%">
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(2, 1fr)"
                    width="100%"
                    alignItems="center"
                    justifyItems="center"
                    gap="8px"
                    padding="8px"
                    boxSizing="border-box"
                    borderRadius="6px"
                    bgcolor={messageColor}
                    color={textColor}
                    textAlign="left"
                    position="relative"           
                    sx={{
                        alignSelf: isSender ? "flex-end" : "flex-start", 
                        transition: "transform 0.2s ease-in-out",
                        "&:hover": {
                            transform: "scale(1.01)",
                        },
                        '&:hover .chevron': {
                            color: 'var(--text-primary)',
                            backgroundColor: 'var(--layer-01)',
                            visibility: 'visible',
                        },
                    }}
                    >
                    {messages.slice(0,4).map((message, index) => {
                        const hasFailed = message.upload_progress === -1;
                        const showLoader = message.upload_progress != null && message.upload_progress >= 0 && !hasFailed;
                        return (
                            <Box
                                key={message.id}
                                sx={{
                                    width: "100%",
                                    aspectRatio: "1/1",
                                    overflow: "hidden",
                                    position: "relative",
                                    cursor: "pointer",
                                }}
                                >
                                {!mediaLoaded[message.id] && <_ImageLoader
                                    hideLoader={showLoader}
                                    />}
                                {message.type == "image" && <img
                                    src={message.media_thumbnail}
                                    alt="Media"
                                    onLoad={() => handleMediaLoaded(message.id)}
                                    onClick={() => setLightboxState(message.id)}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        borderRadius: "6px",
                                        display: "block",
                                    }}
                                    />}
                                {message.type == "video" && message.media && 
                                    <MediaGridVideo 
                                        message_id={message.id}
                                        source={message.media}
                                        onMediaLoaded={handleMediaLoaded}
                                        onMediaPlay={() => setLightboxState(message.id)}
                                        />}

                                <MediaLoader 
                                    showLoader={message.upload_progress != null && message.upload_progress >= 0 && message.upload_progress != -1}
                                    upload_progress={message.upload_progress ?? 0}
                                    />

                                {index === 3 && messages.length > 4 && (
                                    <Box
                                        display="flex"
                                        position="absolute"
                                        top="0"
                                        left="0"
                                        width="100%"
                                        height="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                        bgcolor="rgba(0, 0, 0, 0.6)"
                                        color="white"
                                        borderRadius="6px"
                                        onClick={() => setLightboxState(message.id)}
                                        >
                                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                                            +{messages.length - 4}
                                        </span>
                                    </Box>
                                )}
                            </Box>
                        )}
                    )}
                    <Box 
                        ref={anchor}
                        className="chevron"
                        display="flex"
                        alignItems="center"
                        borderRadius="4px"
                        position="absolute"
                        top="8px"
                        right="8px"
                        color="transparent"
                        visibility="hidden"
                        onClick={() => setOpen(true)}
                        sx={{
                            cursor: 'pointer',
                            transition: 'color 0.2s, background-color 0.2s',
                        }}
                        >
                        <ChevronDown  size="16px" />
                    </Box>
                </Box>

                {/* Timestamp and read marker */}
                <Box display="flex" justifyContent={isSender ? 'flex-end' : 'flex-start'} mt="4px">
                    <span className="label-text-03">{edited_at ? t('components.messaging.message.editedAt', {time: edited_at}) : sent_at}</span>
                    {getReadMarker()}
                </Box>
            </Box>
            <_MessageMenu 
                open={open}
                onClose={() => setOpen(false)}
                chat_id={chat_id}
                messages={messages}
                anchor={anchor}
                />
        </Box>
    )
}

export default MediaGrid;

interface _ImageLoaderProps {
    hideLoader: boolean;
}

const _ImageLoader: FC<_ImageLoaderProps> = ({
    hideLoader
}) => {

    return (
        <Box
            display="flex"
            bgcolor="white" 
            borderRadius="6px"
            height="100%"
            >
            <Box
                position="absolute"
                top="50%"
                left="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{transform: 'translate(-50%, -50%)'}}
                >
                {!hideLoader && <CircularProgress
                    thickness={3}
                    size="50px"
                    sx={{ color: 'var(--text-placeholder)' }}
                    />}
            </Box>
            <Skeleton 
                variant="rounded"
                width="100%"
                height="100%"
                sx={{boxSizing: 'border-box'}}
                />  
        </Box>
    )
}

interface _MessageMenuProps {
    open: boolean;
    onClose: () => void;
    chat_id: string;
    messages: IMessage[];
    anchor: RefObject<HTMLButtonElement>;
}

const _MessageMenu: FC<_MessageMenuProps> = ({
    open,
    onClose,
    chat_id,
    messages,
    anchor,
}) => {

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const handleDownloadAll = () => {
        messages.forEach((message) => {
            message.media && downloadMedia(message.media);
        });
    }

    const options: Options[] = [
        {key: 'download', name: t('components.menuItems.downloadAll'), action: handleDownloadAll},
        {key: 'delete', name: t('components.menuItems.deleteAll'), action: () => setModalOpen(true)},
    ]

    return (
        <>
            <Menu
                anchorEl={anchor?.current}
                open={open}
                onClose={() => onClose()}
                divider={false}
                options={options}
                position={{horizontal: 'right', vertical: 'bottom'}}
                />
                {modalOpen && <DeleteMessageModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    chat_id={chat_id}
                    message_ids={messages.map((message) => message.id)}
                    />}
            </>
    )
}