import { FC, useState } from "react";
// import ReactPlayer from "react-player";

// Contexts
import { useMessageMediaContext } from "@/contexts/message_media_context";

// Styles
import { PlayFilledAlt, Warning } from "@carbon/icons-react";

// Components
import MediaLoader from "./media_loader";
import IconButton from "@/components/icon_button";
import { Box, CircularProgress, Skeleton } from "@mui/material";


interface _VideoMessageProps {
    id: string;
    source?: string;
    thumbnail?: string;
    upload_progress?: number;
}

const VideoMessage: FC<_VideoMessageProps> = ({ 
    id,
    source, 
    thumbnail,
    upload_progress, 
}) => {

    const {setLightboxState} = useMessageMediaContext();
    const [mediaLoaded, setMediaLoaded] = useState<boolean>(false);
    
    const hasFailed = upload_progress === -1;
    const showLoader = upload_progress != null && upload_progress >= 0 && !hasFailed;

    const handleViewVideo = () => {
        if (!source) return;
        setLightboxState(id);
    }
    
    return (
        <Box 
            display="flex" 
            width="100%" 
            alignItems="center" 
            position="relative" 
            >
            <Box 
                position="relative"
                display="inline-block"
                >
                {/* Video with fallback for loader */}
                {!mediaLoaded && <_ImageLoader hideLoader={showLoader} />}
                <Box position="relative" display="inline-block" width="100%" height="100%">
                    {thumbnail && <img 
                        src={thumbnail} 
                        alt="Video Thumbnail"
                        onLoad={() => setMediaLoaded(true)}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '6px'
                        }}
                    />}
                    {/* {source && !thumbnail &&
                        <ReactPlayer
                            className="ReactPlayer-thumbnail"
                            url={source} 
                            width="250px"
                            height="250px"
                            onReady={() => setMediaLoaded(true)}
                            />} */}
                    {mediaLoaded && <IconButton
                        icon={<PlayFilledAlt color="white" />}
                        onClick={handleViewVideo}
                        sx={{
                            position: 'absolute', 
                            top: '50%', 
                            left: '50%', 
                            zIndex: '1000', 
                            transform: 'translate(-50%, -50%)'
                        }}
                        />}
                </Box>

                <MediaLoader 
                    showLoader={showLoader}
                    upload_progress={upload_progress ?? 0}
                    />

                {/* Error Icon if Upload Failed */}
                {hasFailed && (
                    <Box position="absolute" top="50%" left="50%" sx={{ color: "var(--background-brand)" }}>
                        <Warning size="40px" style={{ color: 'var(--support-error)' }} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default VideoMessage;

interface _ImageLoaderProps {
    hideLoader: boolean;
}

const _ImageLoader: FC<_ImageLoaderProps> = ({
    hideLoader
}) => {

    return (
        <Box
            display="flex"
            bgcolor="white" 
            borderRadius="6px"
            height="100%"
            sx={{aspectRatio: '1/1'}}
            >
            <Box
                position="absolute"
                top="50%"
                left="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{transform: 'translate(-50%, -50%)'}}
                >
                {!hideLoader && <CircularProgress
                    thickness={3}
                    size="50px"
                    sx={{ color: 'var(--text-placeholder)' }}
                    />}
            </Box>
            <Skeleton 
                variant="rounded"
                width="100%"
                height="100%"
                sx={{
                    minWidth: '250px',
                    minHeight: '250px',
                    boxSizing: 'border-box'
                }}
                />
        </Box>
    )
}