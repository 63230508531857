import store from "@/store/store";
import { db } from "@/_lib/firebase";
import { getAuth } from "firebase/auth";
import { IParticipant } from "@/interfaces/messaging/messaging";
import { IMessagingContact } from "@/interfaces/messaging/contact";
import { 
    addDoc,
    arrayRemove,
    arrayUnion,
    collection, 
    doc,
    getDocs, 
    query,
    serverTimestamp,
    setDoc,
    where, 
} from "firebase/firestore";


const auth = getAuth();

export const getChats = async () => {

    const user = auth.currentUser;

    if (!user) {
        throw new Error("User is not authenticated");
    }

    const chatsRef = collection(db, 'chats');
    const chatsQuery = query(chatsRef, where('participants', 'array-contains', user.uid));
    const chatsSnapshot = await getDocs(chatsQuery);

    // Process chats and fetch related data
    const chats = await mapChats(chatsSnapshot.docs);
  
    return chats;
};

export const mapChats = (chats: any) => {
    return Promise.all(
        chats.map(async (chat: any) => {
            const chatData = chat.data();
            const participants = getParticipants(chatData.participants);
            return {
                id: chat.id,
                type: chatData.type,
                name: chatData.name,
                owner_id: chatData.owner_id ?? null,
                participants: participants,
                muted: chatData.muted?.includes(auth.currentUser?.uid) ?? false,
                last_message_at: chatData.last_message_at ? new Date(chatData.last_message_at.toDate()).toISOString() : '',
                created_at: chatData.created_at ? new Date(chatData.created_at.toDate()).toISOString() : '',
                updated_at: chatData.updated_at ? new Date(chatData.updated_at.toDate()).toISOString() : ''
            };
        })
    );
}

export const getParticipants = (participantIds: string[]) => {

    const state = store.getState();
    const contacts = state.messaging.contacts;
    const currentUser = state.messaging.user;

    const mapContact = (contact: IMessagingContact) => {
        return {
            id: contact.id,
            client_id: contact.client_id,
            name: contact.name,
            avatar: contact.avatar,
            color: contact.color,
            role: contact.role,
        };
    }

    const userParticipant: IParticipant = {
        id: currentUser.id,
        name: currentUser.name,
        avatar: currentUser.avatar,
        color: currentUser.color,
        role: 'coach'
    };


    const particpants: Record<string, IParticipant> = participantIds.reduce((acc, id) => {
        const contact = contacts.find((contact) => contact.id == id);
        if (contact) {
            acc[id] = mapContact(contact);
        }
        return acc;
    }, {} as Record<string, IParticipant>);

    // Add current user to participants
    particpants[currentUser.id] = userParticipant;

    return particpants;
}

export const newChat = async (contact: string): Promise<string> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("User is not authenticated");
    }

    const state = store.getState();
    const chatRef = collection(db, 'chats');

    // Check if a chat already exists
    const existingChat = state.messaging.chats.find((chat) => {
        return chat.type === 'direct' && chat.participants[contact] && chat.participants[user.uid];
    });

    if (existingChat) {
        return existingChat.id;
    }

    const chatDoc = await addDoc(chatRef, {
        type: 'direct',
        participants: [contact, user.uid],
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
    });

    return chatDoc.id;
}

export const setTypingStatus = async (chat_id: string, id: string, is_typing: boolean) => {
    try {
        const typingRef = doc(db, `chats/${chat_id}/typing/${id}`);
        await setDoc(typingRef, { is_typing }, { merge: true });
    } catch (error) {
        console.error("Failed to update typing status:", error);
    }
}

export const muteChat = async (chat_id: string, is_muted: boolean) => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("User is not authenticated");
    }
    try {
        const chatRef = doc(db, `chats/${chat_id}`);
        await setDoc(chatRef, { 
            muted: is_muted ? arrayUnion(user.uid) : arrayRemove(user.uid)
         }, { merge: true });
    } catch (error) {
        console.error("Failed to update mute status:", error);
    }
}