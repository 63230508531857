import { FC } from "react";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectMessagingEnabled } from "@/store/messaging";
import { selectNotificationSettings } from "@/store/settings";
import { useUpdateMessagingMutation, useUpdateNotificationSettingsMutation } from "@/repositories/coach";
import { INotificationSettingsForm, notificationSettingsFormSchema } from "@/interfaces/settings/settings";

// Styles
import { Chat, ConnectionSignalOff } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import Toggle from "@/components/toggle";


interface _MessagingSettingsProps {
}

const MessagingSettings: FC<_MessagingSettingsProps> = () => {

    const messagingEnabled = useSelector(selectMessagingEnabled);
    const notificationSettings = useSelector(selectNotificationSettings);
    const [updateNotifications] = useUpdateNotificationSettingsMutation();
    const [updateMessaging, { isLoading: isUpdatingMessaging }] = useUpdateMessagingMutation();

    const formMethods = useForm<INotificationSettingsForm>({
        resolver: zodResolver(notificationSettingsFormSchema),
        mode: 'onBlur',
        defaultValues: {
            messaging: notificationSettings.messaging
        }
    });

    const handleUpdateNotifications = (data: INotificationSettingsForm) => {
        updateNotifications(data).unwrap().then(() => {
            // Not used
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    const handleToggleMessaging = () => {
        const setting = messagingEnabled;
        updateMessaging().unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.messaging.setting.title', {context: `${!setting}`})
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start" textAlign="left" padding="24px 32px" borderRadius="6px" border="1px solid var(--border-subtle-01)" bgcolor="var(--layer-01)">
            
            <Box display="flex" alignItems="center" width="100%" sx={{marginBottom: '12px'}}>
                <span className="heading-05">{t('pages.settings.messaging.title')}</span>
            </Box>

            <span className="body-02" style={{marginBottom: '12px'}}>{t('pages.settings.messaging.header.text')}</span>
            <span className="body-02" style={{whiteSpace: 'pre-wrap'}}>{t('pages.settings.messaging.header.features')}</span>

            <Box display="flex" width="100%" sx={{marginTop: '32px'}}>

                <Toggle
                    name="messaging"
                    control={formMethods.control}
                    label={t('components.toggle.enableNotifications')}
                    disabled={!messagingEnabled || isUpdatingMessaging}
                    onChange={formMethods.handleSubmit(handleUpdateNotifications)}
                    />

                <Box display="flex" flexGrow={1} />
                <Button
                    size="small"
                    kind={messagingEnabled ? 'danger' : 'primary'}
                    label={t(`components.buttons.${messagingEnabled ? 'disableMessaging' : 'enableMessaging'}`)}
                    endIcon={messagingEnabled ? <ConnectionSignalOff /> : <Chat />}
                    loading={isUpdatingMessaging}
                    onClick={handleToggleMessaging}
                    sx={{marginLeft: '16px'}}
                    />

            </Box>

        </Box>
    )
}

export default MessagingSettings;