import showToast from "@/_lib/toast";
import { t } from "i18next";
import { DefaultExtensionType } from "react-file-icon";


export const downloadFile = async (url: string, fileName: string) => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const urlBlob = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = urlBlob;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();

        window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
        showToast({ type: 'error', title: t('notifications.file.downloadError.title'), message: t('notifications.file.downloadError.message')});
    }
};

export const getFilePrefix = (type: string) => {
    switch (type.split('/')[0]) {
        case 'audio':
            return 'voice';
        case 'image':
            return 'image';
        case 'video':
            return 'video';
        case 'application':
            return 'file';
        default:
            return 'file';
    }
}

export const fileExtension = (fileName: string) => fileName.split('.').pop() as DefaultExtensionType;

export const extractFirebaseFileName = (url?: string): string => {
    if (!url || url == '') return '';
    // Decode the URL to handle encoded characters like %2F
    const decodedUrl = decodeURIComponent(url);

    const fileNameWithParams = decodedUrl.substring(decodedUrl.lastIndexOf('/') + 1);

    // Remove any query parameters (everything after '?')
    const fileName = fileNameWithParams.split('?')[0];

    return fileName;
}
 