import { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Services and interfaceas
import { selectMessagingEnabled } from "@/store/messaging";
import { IClientSettings } from "@/interfaces/settings/client_settings";

// Components
import { Box } from "@mui/material";
import Checkbox from "@/components/checkbox";

const ClientSettings: FC = () => {

    const { t } = useTranslation();
    const { control, trigger } = useFormContext<IClientSettings>();
    const messagingEnabled = useSelector(selectMessagingEnabled);

    return (
        <Box display="flex" flexGrow={1} alignItems="start">

            {/* Settings column 1 */}
            <Box display="flex" flexDirection="column" flexGrow={1} maxWidth="270px" alignItems="start" justifyContent="start">

                {/* Physique Data */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.metricsCard.titles.physique')}</span>
                    <Box height="8px" />

                    <Checkbox name="physique.bodyweight" control={control} label={t('components.checkbox.bodyweight')} disabled />
                    <Checkbox name="physique.photos" control={control} label={t('components.checkbox.photos')} disabled />
                </Box>

                {/* Spacer */}
                <Box height="40px" />

                {/* Training Data */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.metricsCard.titles.training')}</span>
                    <Box height="8px" />

                    {/* <Checkbox name="training.weight" control={control} label={t('components.checkbox.weight')} disabled />
                    <Checkbox name="training.reps" control={control} label={t('components.checkbox.reps')} disabled /> */}
                    <Checkbox name="training.rir" control={control} label={t('components.checkbox.rir')} onChange={() => trigger()} />
                    <Checkbox name="training.rpe" control={control} label={t('components.checkbox.rpe')} onChange={() => trigger()} />
                    <Checkbox name="training.tempo" control={control} label={t('components.checkbox.tempo')} onChange={() => trigger()} />
                    <Checkbox name="training.set_type" control={control} label={t('components.checkbox.setType')} onChange={() => trigger()} />
                    <Checkbox name="training.rest_time" control={control} label={t('components.checkbox.restTime')} onChange={() => trigger()} />
                    {/* <Checkbox name="training.session_performance" control={control} label={t('components.checkbox.sessionPerformance')} /> */}
                </Box>

                {/* Spacer */}
                <Box height="40px" />

                {/* Supplementation */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.metricsCard.titles.supplementation')}</span>
                    <Box height="8px" />

                    <Checkbox name="supplementation.supplements" control={control} label={t('components.checkbox.supplements')} onChange={() => trigger()} />
                </Box>

                {/* Spacer */}
                <Box height="40px" />

                {/* Messaging */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.metricsCard.titles.messaging')}</span>
                    <Box height="8px" />

                    <Checkbox 
                        name="messaging.messages" 
                        control={control} 
                        label={t('components.checkbox.enabled')}
                        disabled={!messagingEnabled}
                        onChange={() => trigger()}
                        />
                </Box>

            </Box>

            {/* Spacer between columns */}
            <Box width="24px" />

            {/* Settings column 2 */}
            <Box display="flex" flexDirection="column" flexGrow={1} maxWidth="540px" alignItems="start" justifyContent="space-between">

                {/* Activity Data */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.metricsCard.titles.activity')}</span>
                    <Box height="8px" />

                    <Checkbox name="activity.steps" control={control} label={t('components.checkbox.steps')} onChange={() => trigger()} />
                    <Checkbox name="activity.cardio" control={control} label={t('components.checkbox.cardio')} onChange={() => trigger()} />
                </Box>

                {/* Spacer */}
                <Box height="40px" />

                {/* Recovery Data */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.metricsCard.titles.recovery')}</span>
                    <Box height="8px" />

                    <Checkbox name="recovery.sleep_time" control={control} label={t('components.checkbox.sleepTime')} onChange={() => trigger()} />
                    <Checkbox name="recovery.sleep_quality" control={control} label={t('components.checkbox.sleepQuality')} onChange={() => trigger()} />
                    <Checkbox name="recovery.muscle_soreness" control={control} label={t('components.checkbox.muscleSoreness')} onChange={() => trigger()} />
                    <Checkbox name="recovery.energy_level" control={control} label={t('components.checkbox.energyLevels')} onChange={() => trigger()} />
                </Box>

                {/* Spacer */}
                <Box height="40px" />

                {/* Health Data */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.metricsCard.titles.health')}</span>
                    <Box height="8px" />

                    <Checkbox name="health.hrv" control={control} label={t('components.checkbox.hrv')} onChange={() => trigger()} />
                    <Checkbox name="health.rhr" control={control} label={t('components.checkbox.rhr')} onChange={() => trigger()} />
                    <Checkbox name="health.blood_glucose" control={control} label={t('components.checkbox.bloodGlucose')} onChange={() => trigger()} />
                    <Checkbox name="health.blood_pressure" control={control} label={t('components.checkbox.bloodPressure')} onChange={() => trigger()} />
                    <Checkbox name="health.menstrual_cycle" control={control} label={t('components.checkbox.menstrualCycle')} onChange={() => trigger()} />
                </Box>

            </Box>

        </Box>
    )
}

export default ClientSettings;