import { t } from "i18next";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectClientSettingsTraining } from "@/store/settings";
import { IProgrammeBuilder } from "@/interfaces/programme/programme_builder";
import { useAddProgrammeTemplateMutation } from "@/repositories/programme_template";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import ProgrammeModal from "@/components/programme_builder/programme_modal";
import { useSelector } from "react-redux";


interface _NoTemplatesProps {
    message?: string;
    disabled?: boolean;
}

const NoTemplates: FC<_NoTemplatesProps> = ({
    message = t('components.dataTable.noData.noTemplatesFound'),
    disabled = false
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const trainingMetrics = useSelector(selectClientSettingsTraining)
    const [addProgramme, { isLoading: isSaving }] = useAddProgrammeTemplateMutation();

    const handleAdd = (data: IProgrammeBuilder) => {
        addProgramme(data).unwrap().then((template) => {
            showToast({
                type: 'success', 
                title: t('notifications.template.created.title'), 
                message: t('notifications.template.created.message', {name: template.name})
            });
            setOpen(null);
            navigate(`/templates/programmes/${template.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" paddingBottom="24px">

            <Box height="32px" />
            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{message}</span>
            <Box height="12px" />

            <Box display="flex"justifyContent="center" alignItems="center" columnGap="8px">
                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addTemplate')}
                    endIcon={<Add />}
                    minWidth={false}
                    disabled={disabled}
                    onClick={() => setOpen('add')}
                />
                {open === 'add' && <ProgrammeModal 
                    open={open === 'add'}
                    onClose={() => setOpen(null)}
                    isLoading={isSaving}
                    onSave={handleAdd}
                    defaultMetrics={trainingMetrics}
                />}
            </Box>

        </Box>
    )
}

export default NoTemplates;