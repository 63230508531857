import { FC, useRef, useState } from "react";

// Styles
import { Warning } from "@carbon/icons-react";
import PlaceHolder from "@/assets/placeholders/image_upload_placeholder.png";

// Components
import MediaLoader from "./media_loader";
import { Box, CircularProgress, Skeleton } from "@mui/material";
import { useMessageMediaContext } from "@/contexts/message_media_context";


interface _ImageMessageProps {
    id: string;
    source?: string;
    upload_progress?: number;
}

const ImageMessage: FC<_ImageMessageProps> = ({ 
    id,
    source, 
    upload_progress, 
}) => {

    const {setLightboxState} = useMessageMediaContext();
    const clickTimeout = useRef<NodeJS.Timeout|null>(null);
    const [mediaLoaded, setMediaLoaded] = useState<boolean>(false);

    const hasFailed = upload_progress === -1;
    const showLoader = upload_progress != null && upload_progress >= 0 && !hasFailed;
    const isAvailable = upload_progress == null;

    const handleViewImage = () => {
        if (!source || clickTimeout.current) return;
        clickTimeout.current = setTimeout(() => {
            setLightboxState(id);
            clickTimeout.current = null;
        }, 300);
    }

    const handleDoubleClick = () => {
        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
            clickTimeout.current = null;
        }
    };

    return (
        <Box 
            display="flex" 
            width="100%" 
            alignItems="center" 
            justifyContent="center"
            position="relative" 
            onClick={handleViewImage}
            onDoubleClick={handleDoubleClick}
            sx={{cursor: isAvailable ? 'pointer' : 'unset'}}
            >
            <Box 
                position="relative"
                display="flex"
                width="100%"
                justifyContent="center"
                bgcolor="black"
                borderRadius="6px"
                >
                {/* Image with fallback for loader */}
                {!mediaLoaded && <_ImageLoader hideLoader={showLoader} />}
                {source && <img
                    src={source}
                    alt="Media"
                    onLoad={() => setMediaLoaded(true)}
                    onError={(e) => (e.currentTarget.src = PlaceHolder)}
                    style={{
                        width: '100%',
                        height: '100%',
                        maxWidth: '300px',
                        maxHeight: '300px',
                        objectFit: 'cover',
                        display: 'block',
                        clipPath: 'inset(0px)',
                        filter: !source ? 'blur(5px)' : 'none',
                        transition: 'filter 0.3s ease-in-out',
                    }}
                    />}

                <MediaLoader 
                    showLoader={showLoader}
                    upload_progress={upload_progress ?? 0}
                    />

                {/* Error Icon if Upload Failed */}
                {hasFailed && (
                    <Box position="absolute" top="50%" left="50%" sx={{ color: "var(--background-brand)" }}>
                        <Warning size="40px" style={{ color: 'var(--support-error)' }} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default ImageMessage;

interface _ImageLoaderProps {
    hideLoader: boolean;
}

const _ImageLoader: FC<_ImageLoaderProps> = ({
    hideLoader
}) => {

    return (
        <Box
            display="flex"
            bgcolor="white" 
            borderRadius="6px"
            height="100%"
            sx={{aspectRatio: '1/1'}}
            >
            <Box
                position="absolute"
                top="50%"
                left="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{transform: 'translate(-50%, -50%)'}}
                >
                {!hideLoader && <CircularProgress
                    thickness={3}
                    size="50px"
                    sx={{ color: 'var(--text-placeholder)' }}
                    />}
            </Box>
            <Skeleton 
                variant="rounded"
                width="100%"
                height="100%"
                sx={{
                    minWidth: '250px',
                    boxSizing: 'border-box'
                }}
                />
        </Box>
    )
}