

export function primaryFontFromBackground(backgroundColor: string) {
    // Convert hex color to RGB
    function hexToRgb(hex: string) {
        let r = 0, g = 0, b = 0;
        // 3 digits
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        }
        // 6 digits
        else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return { r: r, g: g, b: b };
    }

    // Calculate the brightness of the color
    function getLuma(r: number, g: number, b: number) {
        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    }

    // Assume backgroundColor is in hex format (e.g., #RRGGBB)
    const rgb = hexToRgb(backgroundColor);
    const luma = getLuma(rgb.r, rgb.g, rgb.b);

    // Set font color based on brightness
    return luma > 128 ? 'var(--text-primary)' : 'var(--nav-text-primary)';
}

export function secondaryFontFromBackground(backgroundColor: string) {
    // Convert hex color to RGB
    function hexToRgb(hex: string) {
        let r = 0, g = 0, b = 0;
        // 3 digits
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        }
        // 6 digits
        else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return { r: r, g: g, b: b };
    }

    // Calculate the brightness of the color
    function getLuma(r: number, g: number, b: number) {
        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    }

    // Assume backgroundColor is in hex format (e.g., #RRGGBB)
    const rgb = hexToRgb(backgroundColor);
    const luma = getLuma(rgb.r, rgb.g, rgb.b);

    // Set font color based on brightness
    return luma > 128 ? 'var(--text-secondary)' : 'var(--nav-text-secondary)';
}

export function getColorForName(name: string): string {
    const colors = [
        "#6A0DAD", // Dark Purple
        "#D92E1D", // Red
        "#228B22", // Dark Green
        "#1E90FF", // Dodger Blue
        "#D81B60", // Dark Pink
        "#FFB400", // Dark Yellow
        "#00CED1", // Dark Cyan
        "#E07000", // Burnt Orange
    ];

    if (!name || name.length === 0) {
        return colors[0];
    }

    const hash = Array.from(name).reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const colorIndex = hash % colors.length;

    return colors[colorIndex];
}