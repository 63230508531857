import { t } from "i18next";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom"

// Hooks
// import { useTokenRefresh } from "@/_helpers/hooks";

// Repositories
import baseApi from "@/repositories/base_api";
import { useGetTeamQuery } from "@/repositories/team";
import { useGetUserQuery } from "@/repositories/auth";
import { useGetFirebaseTokenQuery } from "@/repositories/messaging";
import { useGetSubscriptionQuery } from "@/repositories/subscription";

// Selectors
import { AppDispatch } from "@/store/store";
import { selectIsAuthenticated } from "@/store/auth";
import { selectHasCompletedSetup } from "@/store/coach";
import { selectHasTeamOrInvitation, selectIsOwner } from "@/store/team";
import { authenticateFirebaseUser, selectFirebaseToken } from "@/store/messaging";

// Components
import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";


const AuthGuard = () => {

  // NOTE Switched off until we move to cookis over presigend URLs
  // useTokenRefresh();
  const dispatch: AppDispatch = useDispatch();
  const { isLoading: userIsLoading } = useGetUserQuery();
  const { isLoading: subIsLoading } = useGetSubscriptionQuery();
  const { isLoading: teamIsLoading } = useGetTeamQuery();
  const { isLoading: firebaseIsLoading } = useGetFirebaseTokenQuery();


  const loaders = [userIsLoading, subIsLoading, teamIsLoading, firebaseIsLoading];
  const isLoading = loaders.some(l => l);

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const completedSetup = useSelector(selectHasCompletedSetup);
  const hasTeamOrInvitation = useSelector(selectHasTeamOrInvitation);
  const firebaseToken = useSelector(selectFirebaseToken);
  const isOwner = useSelector(selectIsOwner);
  const location = useLocation();

  useEffect(() => {
    if (firebaseToken) {
      dispatch(authenticateFirebaseUser(firebaseToken))
    }
  }, [firebaseToken])

  useEffect(() => {
    return () => {
      dispatch(baseApi.util.resetApiState());
    } 
  }, [])

  if (isLoading) {
    return (
      <Backdrop
        open={isLoading}
        sx={{
            bgcolor: 'var(--nav-background)',
            zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        >
          <CircularProgress size={16} thickness={5} sx={{color: 'var(--text-overlay)'}} />
          <Box width={8} />
          <span className="helper-text-02" style={{color: 'var(--text-overlay)'}}>{t('general.loading1FIT')}</span>
      </Backdrop>
    ) 
  }
  
  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace />
  }

  if (!completedSetup && hasTeamOrInvitation && !isOwner && !['/setup/team', '/setup/profile', '/setup/settings', '/setup/checkin'].includes(location.pathname)) {
    return <Navigate to="/setup/team" replace />;
  }
  
  if (!completedSetup && !location.pathname.startsWith('/setup')) {
    return <Navigate to="/setup/subscription" replace />;
  }

  if (completedSetup && location.pathname.startsWith('/setup')) {
    return <Navigate to="/clients/active" replace />;
  }

  return (
    <Outlet />
  );
};

export default AuthGuard;