import { z } from "zod";
import ruleset from "@/_helpers/ruleset";
import { 
    IClientSettings, 
    IClientSettingsActivity, 
    IClientSettingsHealth, 
    IClientSettingsMessaging, 
    IClientSettingsPhysique, 
    IClientSettingsRecovery, 
    IClientSettingsSupplementation, 
    IClientSettingsTraining 
} from "@/interfaces/settings/client_settings";
import { ISiteSettings } from "@/interfaces/settings/site_settings";


export interface ISettingsForm extends IClientSettings {
    physique: IClientSettingsPhysique,
    training: IClientSettingsTraining,
    supplementation: IClientSettingsSupplementation,
    messaging: IClientSettingsMessaging,
    activity: IClientSettingsActivity,
    recovery: IClientSettingsRecovery,
    health: IClientSettingsHealth,
    units: ISiteSettings
}

export const physiqueSchema = z.object({
    bodyweight: ruleset.boolean,
    photos: ruleset.boolean
});

export const trainingSchema = z.object({
    weight: ruleset.boolean,
    reps: ruleset.boolean,
    rir: ruleset.boolean,
    rpe: ruleset.boolean,
    tempo: ruleset.boolean,
    rest_time: ruleset.boolean,
    set_type: ruleset.boolean,
    session_performance: ruleset.boolean,
});

export const supplementationSchema = z.object({
    supplements: ruleset.boolean
});

export const messagingSchema = z.object({
    messages: ruleset.boolean
});

export const activitySchema = z.object({
    steps: ruleset.boolean,
    cardio: ruleset.boolean
});

export const recoverySchema = z.object({
    sleep_time: ruleset.boolean,
    sleep_quality: ruleset.boolean,
    muscle_soreness: ruleset.boolean,
    energy_level: ruleset.boolean
});

export const healthSchema = z.object({
    hrv: ruleset.boolean,
    rhr: ruleset.boolean,
    blood_glucose: ruleset.boolean,
    blood_pressure: ruleset.boolean,
    menstrual_cycle: ruleset.boolean
});


export const unitsSchema = z.object({
    currency: ruleset.selectObject,
    weight: ruleset.selectObject,
    bodyweight: ruleset.selectObject,
    measurements: ruleset.selectObject
});

export const settingsSchema = z.object({
    physique: physiqueSchema,
    training: trainingSchema,
    supplementation: supplementationSchema,
    messaging: messagingSchema,
    activity: activitySchema,
    recovery: recoverySchema,
    health: healthSchema,
    units: unitsSchema
});