import { createSlice } from "@reduxjs/toolkit";

// Apis
import { authApi } from "@/repositories/auth";
import { coachApi } from "@/repositories/coach";

// Interfaces
import { ISiteSettings } from "@/interfaces/settings/site_settings"; 
import { INotificationSettings } from "@/interfaces/settings/settings";
import { 
  IClientSettingsActivity, 
  IClientSettingsHealth, 
  IClientSettingsPhysique,
  IClientSettingsRecovery, 
  IClientSettingsSupplementation, 
  IClientSettingsTraining,
  IClientSettingsMessaging
} from "@/interfaces/settings/client_settings";

interface ISettingsState {
  physique: IClientSettingsPhysique|undefined;
  training: IClientSettingsTraining|undefined;
  supplementation: IClientSettingsSupplementation|undefined;
  activity: IClientSettingsActivity|undefined;
  recovery: IClientSettingsRecovery|undefined;
  health: IClientSettingsHealth|undefined;
  messaging: IClientSettingsMessaging|undefined;
  units: ISiteSettings|undefined;
  notifications: INotificationSettings;
}

const initialState: ISettingsState = {
  physique: undefined,
  training: undefined,
  supplementation: undefined,
  activity: undefined,
  recovery: undefined,
  health: undefined,
  messaging: undefined,
  units: undefined,
  notifications: {
    messaging: false
  }
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
      state.physique = payload.settings.client_settings.physique;
      state.training = payload.settings.client_settings.training;
      state.supplementation = payload.settings.client_settings.supplementation;
      state.activity = payload.settings.client_settings.activity;
      state.recovery = payload.settings.client_settings.recovery;
      state.health = payload.settings.client_settings.health;
      state.messaging = payload.settings.client_settings.messaging;
      state.units = payload.settings.site_settings;
      state.notifications = payload.settings.notifications;
    }).addMatcher(coachApi.endpoints.updateSettings.matchFulfilled, (state, { payload }) => {
      state.physique = payload.client_settings.physique;
      state.training = payload.client_settings.training;
      state.supplementation = payload.client_settings.supplementation;
      state.activity = payload.client_settings.activity;
      state.recovery = payload.client_settings.recovery;
      state.health = payload.client_settings.health;
      state.messaging = payload.client_settings.messaging;
      state.units = payload.site_settings;
      state.notifications = payload.notifications;
    }).addMatcher(coachApi.endpoints.updateMessaging.matchFulfilled, (state, { payload }) => {
      state.messaging = payload.client_settings.messaging;
    }).addMatcher(coachApi.endpoints.updateNotificationSettings.matchFulfilled, (state, { payload }) => {
      state.notifications = payload.notifications;
    }).addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      resetState(state);
    })
  },
  selectors: {
    selectSettings: (state): ISettingsState => state,
    selectSiteSettings: (state) => state.units,
    selectSiteSettingsWeight: (state) => state.units?.weight,
    selectSiteSettingsBodyweight: (state) => state.units?.bodyweight,
    selectCurrency: (state) => state.units?.currency,
    selectClientSettingsPhysique: (state) => state.physique,
    selectClientSettingsTraining: (state) => state.training,
    selectClientSettingsSupplementation: (state) => state.supplementation,
    selectClientSettingsActivity: (state) => state.activity,
    selectClientSettingsRecovery: (state) => state.recovery,
    selectClientSettingsHealth: (state) => state.health,
    selectClientSettings: (state) => {
      return {
        physique: state.physique,
        training: state.training,
        supplementation: state.supplementation,
        activity: state.activity,
        recovery: state.recovery,
        health: state.health,
        messaging: state.messaging
      }
    },
    selectNotificationSettings: (state) => state.notifications,
  },
});

const resetState = (state: ISettingsState) => {
  state.physique = undefined;
  state.training = undefined;
  state.supplementation = undefined;
  state.activity = undefined;
  state.recovery = undefined;
  state.health = undefined;
  state.units = undefined;
  state.messaging = undefined;
  state.notifications = {
    messaging: false
  }
}

export const { 
  selectSettings, 
  selectSiteSettings,
  selectSiteSettingsWeight,
  selectSiteSettingsBodyweight,
  selectCurrency, 
  selectClientSettings,
  selectClientSettingsTraining,
  selectNotificationSettings,
 } = settingsSlice.selectors;

export default settingsSlice.reducer;