import { t } from "i18next";
import { FC, RefObject, useState } from "react"

// Services and interfaces
import { IMessage } from "@/interfaces/messaging/messaging";

// Components
import Menu from "@/components/menu";
import DeleteMessageModal from "./delete_message";


interface _MessageMenuProps {
    open: boolean;
    onClose: () => void;
    chat_id: string;
    message: IMessage;
    anchor: RefObject<HTMLButtonElement>;
    canEdit?: boolean;
    onEdit: (message: IMessage) => void;
}

const MessageMenu: FC<_MessageMenuProps> = ({
    open,
    onClose,
    chat_id,
    message,
    anchor,
    canEdit = true,
    onEdit
}) => {

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const options = [
        ...(canEdit ? [ {key: 'edit', name: t('components.menuItems.edit'), action: () => onEdit(message)}] : []),
        {key: 'delete', name: t('components.menuItems.delete'), action: () => setModalOpen(true)},
    ]

    return (
        <>
            <Menu
                anchorEl={anchor?.current}
                open={open}
                onClose={() => onClose()}
                divider={false}
                options={options}
                position={{horizontal: 'right', vertical: 'bottom'}}
                />
                {modalOpen && <DeleteMessageModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    chat_id={chat_id}
                    message_ids={[message.id]}
                    />}
            </>
    )
}

export default MessageMenu;