import { FC, ReactNode, useRef, useState } from "react";

// Components
import Button from "@/components/button";
import { Box, ButtonProps } from "@mui/material";
import { ChevronDown } from "@carbon/icons-react";
import Menu, { Options } from "@/components/menu";


interface _MenuButtonProps {
    kind?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'danger' | 'danger-secondary' | 'danger-ghost';
    size?: 'small' | 'medium' | 'large';
    label?: string;
    startIcon?: ReactNode;
    options: Options[];
    activeOption?: string;
    disabled?: boolean;
    // Optional parameter to set the position of the menu
    position?: { horizontal: 'center' | 'left' | 'right' | number, vertical: 'bottom' | 'center' | 'top' | number };
    // Optional parameter to set the width of the menu
    menuWidth?: string;
    sx?: ButtonProps['sx'];
}

const MenuButton: FC<_MenuButtonProps> = ({
    kind = 'primary',
    size = 'medium',
    label,
    options,
    startIcon,
    activeOption,
    disabled = false,
    position = { horizontal: 'right', vertical: 'bottom' },
    menuWidth,
    sx,
    // showSelection = false
}) => {
   
    const anchor = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState<boolean>(false);

    const internalSx = {
        ...sx,
        //NOTE - This is a temporary fix to make the button active when the menu is open
        backgroundColor: open ? 'var(--background-active) !important' : undefined,
    }

    const iconStyles = {    
        display: 'flex',
        height: 16,
        '& svg': {
            transform: open ? 'rotate(180deg)' : undefined,
            transition: 'transform 0.1s linear'
        }
    }

    return (
        <>
            <Button
                ref={anchor} 
                kind={kind}
                size={size}
                label={label}
                startIcon={startIcon}
                endIcon={
                    <Box sx={iconStyles}>
                        <ChevronDown />
                    </Box>}
                disabled={disabled}
                onClick={() => setOpen(true)}
                sx={internalSx}
                />
            <Menu
                anchorEl={anchor?.current}
                open={open}
                onClose={() => setOpen(false)}
                divider={false}
                options={options}
                activeOption={activeOption}
                position={position}
                sx={{ width: menuWidth }}
                />
        </>

    );
}

export default MenuButton;