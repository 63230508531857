import { FC } from "react";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { DefaultExtensionType, FileIcon, defaultStyles } from "react-file-icon";

// Helpers
import { formatDuration } from "@/_helpers/time_functions";

// Services and interfaces
import { RootState } from "@/store/store";
import { selectMessageById } from "@/store/messaging";
import { IParticipant } from "@/interfaces/messaging/messaging";

// Styles
import { Folder, PhotoCamera } from "@mui/icons-material";
import { VideoFilled, MicrophoneFilled } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";


interface _MessageReplyProps {
    chat_id: string;
    reply_id: string;
    participants: Record<string, IParticipant>;
}

const MessageReply: FC<_MessageReplyProps> = ({ 
    chat_id,
    reply_id,
    participants
}) => {

    const reply = useSelector((state: RootState) => selectMessageById(state, chat_id, reply_id));

    if (!reply) return null;

    const sender = participants[reply.sender_id];
    const hasMedia = !!reply.media;
    const hasText = !!reply.text && reply.text.length > 0;
    const isImage = hasMedia && reply.type === 'image';
    const isVideo = hasMedia && reply.type === 'video';
    const isAudio = hasMedia && reply.type === 'voice';
    const isFile = hasMedia && reply.type === 'file';
    const fileExtension = reply.metadata?.extension as DefaultExtensionType;

    const getMediaIcon = () => {
        const iconStyle = {color: 'var(--text-secondary)', height: '16px', width: '16px', marginRight: '4px'};
        if (isImage) return <PhotoCamera style={iconStyle} />;
        if (isVideo) return <VideoFilled style={iconStyle} />;
        if (isAudio) return <MicrophoneFilled style={iconStyle} />;
        if (isFile) return <Folder style={iconStyle} />;
        return null;
    }

    const getMediaText = () => {
        if (hasText) return (
            <span className="body-02 ThreeLine--ellipsis" style={{color: 'var(--text-primary)'}}>{reply.text}</span>
        )
        return (
            <Box display="flex" alignItems="center" mt="2px">
                {getMediaIcon()}
                {!isAudio && <span className="label-text-02" style={{color: 'var(--text-primary)'}}>{t(`enums.mediaIcon.${reply.type}`)}</span>}
                {isAudio && <span className="label-text-02" style={{color: 'var(--text-primary)'}}>{formatDuration(reply.metadata?.duration ?? 0)}</span>}
            </Box>
        )
    }

    const handleReplyClick = () => {
        const targetMessage = document.getElementById(`message-${reply_id}`);
        if (targetMessage) {
          targetMessage.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    return (
        <Box
            display="flex"
            alignItems="flex-start"
            width="100%"
            maxHeight="94px"
            justifyContent="space-between"
            marginBottom="4px"
            boxSizing="border-box"
            borderRadius="6px"
            borderLeft={`solid 3px ${sender.color}`}
            bgcolor="var(--nav-layer-03-selected-hover)"
            onClick={handleReplyClick}
            >
            <Box display="flex" flexDirection="column" padding="8px" marginRight="8px">
                <span className="heading-07-compact" style={{color: sender.color}}>{sender.name}</span>
                {getMediaText()}
            </Box>
            {(isImage || isVideo) && <img src={reply.media_thumbnail} alt="media" 
                style={{
                    maxHeight: '94px', 
                    alignSelf: 'center',
                    borderRadius: '6px', 
                    objectFit: 'cover',
                    borderTopRightRadius: '6px',
                    borderBottomRightRadius: '6px'
                }} />}
            {isFile && <Box display="flex" alignSelf="center" width="30px" paddingRight="8px">
                <FileIcon
                    extension={fileExtension} {...defaultStyles[fileExtension]} />
            </Box>}

        </Box>
    )
}

export default MessageReply;