import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';


export const messagingApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getFirebaseToken: build.query<any, void>({
            query: () => ({ url: '/messaging/auth', method: 'get' }),
            transformResponse: (response: IAPIResponse<any>) => response.data,
        }),
    }),
})

export const { 
    useGetFirebaseTokenQuery,
} = messagingApi;

