import { z } from "zod";
import ruleset from "@/_helpers/ruleset";


export interface IGroupForm {
    contacts: string[];
}

export const groupFormSchema = z.object({
    contacts: ruleset.multiselect
});