import { FC, useRef, useState } from "react";

// Helpers
import { convertToM4A } from "@/_helpers/audio_functions";
import { formatDuration } from "@/_helpers/time_functions";
import { formatHumanReadableDateString } from "@/_helpers/date_functions";

// Services and interfaces
import { IMediaFile } from "@/interfaces/messaging/messaging";

// Styles
import { CircleFilled, Microphone, Pause, SendAlt, TrashCan } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import AudioPlayer from "./audio_player";
import IconButton from "@/components/icon_button";


interface _VoiceNoteProps {
    isUploading?: boolean;
    isRecording: boolean;
    disabled?: boolean;
    setIsRecording: (isRecording: boolean) => void;
    onSend: (file: IMediaFile) => void;
}

const VoiceNote: FC<_VoiceNoteProps> = ({
    isUploading = false,
    isRecording,
    disabled = false,
    setIsRecording,
    onSend
}) => {

    const audioChunksRef = useRef<Blob[]>([]);
    const timerRef = useRef<NodeJS.Timeout|null>(null);
    const mediaRecorderRef = useRef<MediaRecorder|null>(null);

    const [isPaused, setIsPaused] = useState(false);
    const [audioURL, setAudioURL] = useState<string|null>(null);
    const [recordingTime, setRecordingTime] = useState<number>(0);
    const [mimeType, setMimeType] = useState<string>("audio/webm");

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            if (MediaRecorder.isTypeSupported(mimeType)) {
                console.log('Using supported MIME type:', mimeType);
                mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: mimeType });
            } else {
                console.log('Using default MIME type: audio/webm');
                setMimeType("audio/webm");
                mediaRecorderRef.current = new MediaRecorder(stream);
            }

            audioChunksRef.current = [];

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                    setAudioURL(URL.createObjectURL(new Blob(audioChunksRef.current, { type: mimeType })));
                }
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
            setIsPaused(false);

            timerRef.current = setInterval(() => {
                setRecordingTime((prev) => prev + 1);
            }, 1000);
        } catch (error) {
            console.error("Microphone access error:", error);
        }
    };

    const handleToggleRecord = () => {
        if (!mediaRecorderRef.current) return;

        if (mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.pause();
            clearInterval(timerRef.current!);
            setIsPaused(true);
            mediaRecorderRef.current.requestData();
        } else if (mediaRecorderRef.current.state === "paused") {
            mediaRecorderRef.current.resume();
            timerRef.current = setInterval(() => {
                setRecordingTime((prev) => prev + 1);
            }, 1000);
            setIsPaused(false);
        }
    };

    const handleSendRecording = () => {
        if (!mediaRecorderRef.current) return;
        
        console.log("Attempting to send recording...");
        
        if (mediaRecorderRef.current.state === "recording" || mediaRecorderRef.current.state === "paused") {
            console.log("Stopping recorder to finalize audio...");
            
            mediaRecorderRef.current.stop(); // ✅ Stop the recorder first
            
            mediaRecorderRef.current.onstop = async () => {
                console.log("Recorder stopped, finalizing audio...");
                handleStopStream(); // ✅ Release microphone
    
                if (!audioChunksRef.current.length) {
                    console.error("No audio data available, cannot send.");
                    return;
                }
    
                const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
                const convertedBlob = await convertToM4A(audioBlob);
                const file: IMediaFile = {
                    file: convertedBlob,
                    name: `voice_${formatHumanReadableDateString()}.${convertedBlob.type.split('/')[1]}`,
                    type: 'voice',
                    preview_url: URL.createObjectURL(convertedBlob)
                }
                onSend(file);
                handleResetRecording();
            };
        }
    };
    
    const handleStopStream = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.stream) {
            console.log("Releasing microphone...");
            mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop()); // ✅ Stop all tracks
        }
    }

    const handleResetRecording = () => {
        mediaRecorderRef.current?.stop();
        handleStopStream();

        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
        
        setIsRecording(false);
        setIsPaused(false);
        setRecordingTime(0);
        setAudioURL(null);
        audioChunksRef.current = [];
    };

    return (
        <>
            {isRecording ? 
                <Box display="flex" width="100%">
                    <IconButton
                        size="medium"
                        kind="danger"
                        icon={<TrashCan />}
                        onClick={handleResetRecording}
                        sx={{ marginRight: '12px' }}
                    />
                    <Box
                        display="flex"
                        width="100%"
                        alignItems="center"
                        padding="10px 12px"
                        borderRadius="6px"
                        border="solid 1px var(--border-subtle-01)"
                        bgcolor="var(--layer-01)"
                        >
                        {isPaused && audioURL ?
                            <AudioPlayer audioURL={audioURL} /> :
                            <CircleFilled color="var(--support-error)" style={{ marginRight: '8px'}} />}
                        <span className="label-text-02">{formatDuration(recordingTime, false)}</span>
                    </Box>
                    <IconButton
                        size="medium"
                        icon={isPaused ? <Microphone /> : <Pause />}
                        onClick={handleToggleRecord}
                        sx={{ marginLeft: '12px' }}
                        />
                    <IconButton
                        size="medium"
                        icon={<SendAlt />}
                        loading={isUploading}
                        onClick={handleSendRecording}
                        sx={{ marginLeft: '12px' }}
                        />
                </Box> :
                <IconButton
                    size="medium"
                    icon={<Microphone />}
                    disabled={disabled}
                    onClick={startRecording}
                    sx={{marginLeft: '12px'}}
                    />

                }
        </>
    )
}

export default VoiceNote;