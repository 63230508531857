import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useRef, useState } from "react";

// Services and interfaces
import { selectTeamId } from "@/store/team";
import { selectCoachId } from "@/store/coach";
import { IChat } from "@/interfaces/messaging/messaging";
import { listenForChats, listenForUsers } from "@/services/chat_listeners";
import { selectChats, selectFirebaseAuthenticated, selectFirebaseUser, selectMessagingLoaded } from "@/store/messaging";

// Styles
import { Chat } from "@carbon/icons-react";

// Components
import Tooltip from "@/components/tooltip";
import Badge, { badgeClasses } from "@mui/material/Badge";
import ChatPanel from "@/components/messaging/message_panel";
import NavHeaderAction from "@/components/navigation/nav_header_action";


const NavMessages: FC = () => {

    const badgeSx = {
        [`& .${badgeClasses.badge}`]: {
            bgcolor: 'var(--nav-background-brand)',
            transform: 'translate(3px, -4px)'
        }
    }

    const { t } = useTranslation();
    const navigate = useNavigate();
    const anchor  = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [hasUnread, setHasUnread] = useState<boolean>(false);
    
    const chats = useSelector(selectChats);
    const teamId = useSelector(selectTeamId);
    const coachId = useSelector(selectCoachId);
    const firebaseUser = useSelector(selectFirebaseUser);
    const messagingIsLoaded = useSelector(selectMessagingLoaded);
    const isAuthenticated = useSelector(selectFirebaseAuthenticated);

    const updateUnreadState = (chats: IChat[]) => {
        const unread = chats.some((chat) =>
            chat.messages?.some((message) =>
                message.sender_id !== firebaseUser.id &&
                !message.read_by.some((user) => user === firebaseUser.id)
            )
        );
        setHasUnread(unread);
    };

    useEffect(() => {
        updateUnreadState(chats);
    }, [chats]);

    useEffect(() => {
        if (!isAuthenticated) return;

        const hydrateStore = async () => {
            const unsubscribeUsers = listenForUsers(coachId!, teamId);
            const unsubscribeChats = listenForChats(firebaseUser.id);
    
            return () => {
                unsubscribeUsers();
                unsubscribeChats();
            };
        }
        hydrateStore();
    }, [isAuthenticated]);


    const handleClick = () => {
        if (!messagingIsLoaded) return;
        navigate('/messaging');
    }

    return (
        <>
            <Tooltip kind="nav" title={t('tooltips.messages')}>
                <NavHeaderAction ref={anchor} icon={
                    <Badge sx={hasUnread ? badgeSx : undefined} overlap="circular" variant="dot">
                        <Chat size={20} />
                    </Badge>
                } onClick={handleClick} />
            </Tooltip>
            {open && <ChatPanel
                open={open}
                onClose={() => setOpen(false)}
                />}
            
        </>
    );
}

export default NavMessages;