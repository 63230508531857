import WaveSurfer from "wavesurfer.js";
import WavesurferPlayer from "@wavesurfer/react";
import { FC, useEffect, useRef, useState } from "react";

// Styles
import { PauseFilled, PlayFilledAlt } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";


interface _AudioPlayerProps {
  source?: string;
  isSender: boolean;
}
const AudioPlayer: FC<_AudioPlayerProps> = ({ 
  source, 
  isSender 
}) => {

  const [dragging, setDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [playbackPosition, setPlaybackPosition] = useState(0);
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);

  const onReady = (ws: WaveSurfer) => {
    ws.setOptions({ cursorColor: "transparent" });
    setWavesurfer(ws);
    setIsPlaying(false);
    setIsLoading(false);

    ws.on("audioprocess", () => {
      if (!dragging) {
        const position = ws.getCurrentTime() / ws.getDuration();
        setPlaybackPosition(position);
      }
    });

    ws.on("finish", () => {
      setIsPlaying(false);
      setPlaybackPosition(0);
    });
  };

  const onPlayPause = () => {
    if (wavesurfer) {
      wavesurfer.playPause();
      setIsPlaying((prev) => !prev);
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!dragging || !wavesurfer || !containerRef.current) return;

    const containerWidth = containerRef.current.offsetWidth;
    const rect = containerRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;

    // Ensure the cursor doesn't go out of bounds
    const position = Math.max(0, Math.min(offsetX / containerWidth, 1));

    setCursorPosition(position);
  };

  const handleMouseUp = () => {
    if (dragging && wavesurfer) {
      wavesurfer.seekTo(cursorPosition);
      if (!isPlaying) {
        setPlaybackPosition(cursorPosition);
      }
    }
    setDragging(false);
  };

  const playheadStyle = {
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: isSender ? "white" : "var(--text-primary)",
    cursor: "grab",
    zIndex: 2,
  };

  const buttonStyles = {
    height: '20px',
    width: '20px',
    cursor: "pointer",
    color: isLoading ? 'var(--text-placeholder)' : 
      isSender ? 'white' : 'var(--text-primary)',
  }

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging, cursorPosition]);

  return (
    <Box position="relative" display="flex" alignItems="center">
      {isPlaying ? 
        <PauseFilled onClick={onPlayPause} style={buttonStyles} /> :
        <PlayFilledAlt onClick={onPlayPause} style={buttonStyles} />}
      <Box
        ref={containerRef}
        position="relative"
        width="100%"
        height="30px"
        ml="12px"
        mr="12px"
        onMouseMove={handleMouseMove}
        >
        {isLoading && <Box
          position="relative"
          top="15px"
          left="0"
          height="3px"
          width="240px"
          bgcolor="rgba(255, 255, 255, 0.2)"
          zIndex={1}
          />}
        <WavesurferPlayer
          url={source}
          height={30}
          width="240px"
          waveColor={isSender ? 'white' : 'var(--text-primary)'}
          barWidth={2}
          barHeight={4}
          interact={false}
          onReady={onReady}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
        {wavesurfer && (
          <Box
            sx={{
              ...playheadStyle,
              left: `${(dragging ? cursorPosition : playbackPosition) * 100}%`,
            }}
            onMouseDown={(e) => {
              setDragging(true);
              e.stopPropagation();
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default AudioPlayer;