import { FC, ReactNode } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

// Services and interfaces
import { selectContacts } from '@/store/messaging';

// Components
import { Box } from '@mui/material';
import Avatar from '@/components/avatar';


interface _IMessageToastProps {
    message: string|ReactNode;
    sender_id: string;
}

const showMessageToast: FC<_IMessageToastProps> = ({
    message,
    sender_id,
}) => {

    return (
        toast(
            <_Body message={message} sender_id={sender_id} />,
            {
                className: 'MessageToast',
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            }
        )
    );
}

export default showMessageToast;


interface _BodyProps {
    message: string|ReactNode;
    sender_id: string;
}

const _Body: FC<_BodyProps> = ({
    message,
    sender_id,
}) => {

    const contacts = useSelector(selectContacts);
    const sender = contacts.find(contact => contact.id === sender_id);

    return (
        <Box display="flex" alignItems="center">
            <Avatar
                size="medium"
                src={sender?.avatar ?? ''}
                name={sender?.name ?? ''}
                sx={{marginRight: '16px'}}
                />
            <Box style={{display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'start', textAlign: 'start'}}>
                <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                    <span className="heading-07-compact" style={{color: 'var(--text-inverse)'}}>{`New message from ${sender?.name}`}</span>
                </Box>
                <span className="body-02-compact ThreeLine--ellipsis" style={{whiteSpace: 'pre-wrap', color: 'var(--text-inverse)'}}>{message}</span>
            </Box>
        </Box>
    )
}