import ErrorPage from "@/pages/error";
import DefaultLayout from "@/layouts/default";
import AuthGuard from "@/middleware/auth_guard";
import { createBrowserRouter, Navigate } from "react-router-dom";

// Routes
import { editRoutes } from "./edit_routes";
import { authRoutes } from "./auth_routes";
import { setupRoutes } from "./setup_routes";
import { vaultRoutes } from "./vault_routes";
import { clientRoutes } from "./client_routes";
import { libraryRoutes } from "./library_routes";
import { templateRoutes } from "./template_routes";
import { settingsRoutes } from "./settings_routes";
import { prospectRoutes } from "./prospect_routes";
import { businessRoutes } from "./business_routes";
import { catalogueRoutes } from "./catalogue_routes";
import { registrationRoutes } from "./registration_routes";
import MessagingPage from "@/pages/messaging/messaging_page";
import { clientDetailsRoutes } from "./client_details_routes";

// Pages - move to separate files
import PhaseRoadmap from "@/pages/client/phases/phase_roadmap";
import CheckinBoardPage from "@/pages/checkins/checkin_board_page";


const router = createBrowserRouter([
  {  
    element: <AuthGuard />,
    errorElement: <ErrorPage />,
    children: [
      setupRoutes,
      {
        path: "/",
        element: <DefaultLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/clients/active" replace />,
          },
          clientRoutes,
          clientDetailsRoutes,
          prospectRoutes,
          {
            path: "/checkins",
            element: <CheckinBoardPage />
          },
          {
            path: "/messaging",
            element: <MessagingPage />
          },
          vaultRoutes,
          templateRoutes,
          catalogueRoutes,
          libraryRoutes,
          businessRoutes,
          settingsRoutes,
        ],
      },
      editRoutes,
      {
        path: "/clients/:id/phases/roadmap",
        element: <PhaseRoadmap />,
      }
    ]
  },
  authRoutes,
  registrationRoutes
]);

export default router;