import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FC, useEffect, useMemo, useState } from "react";

// Context
import { MessageMediaProvider } from "@/contexts/message_media_context";

// Services and interfaces
import { renameGroup } from "@/services/messaging/group_service";
import { muteChat, setTypingStatus } from "@/services/messaging/chat_service";
import { markMessagesAsReadWithTimestamp } from "@/services/messaging/message_service";
import { selectChats, selectFirebaseId, selectMessagingLoaded } from "@/store/messaging";

// Styles
import { AddComment, Edit, Group, Notification, NotificationOff, Settings, User } from "@carbon/icons-react";

// Components
import Button from "@/components/button";
import { Options } from "@/components/menu";
import Chat from "@/components/messaging/chat";
import SearchInput from "@/components/search_input";
import { Box, CircularProgress } from "@mui/material";
import Messages from "@/components/messaging/messages";
import MenuIconButton from "@/components/menu_icon_button";
import RenameModal from "@/components/builder/rename_modal";
import GroupModal from "@/components/messaging/group_modal";
import NoMessages from "@/components/messaging/no_messages";
import SecondaryToolbar from "@/components/secondary_toolbar";
import NewMessageModal from "@/components/messaging/new_message_modal";


const MessagingPage: FC = () => {

    const navigate = useNavigate();
    const chats = useSelector(selectChats);
    const [open, setOpen] = useState<string|null>(null);
    const firebaseId = useSelector(selectFirebaseId);
    const messagingIsLoaded = useSelector(selectMessagingLoaded);
    const [selectedChatId, setSelectedChatId] = useState<string|null>(null);

    const selectedChat = useMemo(() => {
        return chats.find(chat => chat.id === selectedChatId) ?? null;
    }, [chats, selectedChatId]);

    const selectedChatTitle = useMemo(() => {
        if (selectedChat?.type == 'direct') {
            return Object.values(selectedChat.participants).find(participant => participant.id !== firebaseId)?.name;
        } else if (selectedChat?.type == 'group') {
            return selectedChat.name ?? t('components.messaging.groupChat');
        }
        return '';
    }, [selectedChat]);

    const formMethods = useForm({
        defaultValues: {
            search: ''
        }
    });

    const search = formMethods.watch('search');
    const isGroup = selectedChat?.type == 'group';
    const showGoToClient = selectedChat?.type == 'direct' && 
        Object.values(selectedChat.participants).find(participant => participant.id !== firebaseId)?.role == 'client';

    const getMenuOption = (key: string) => {
        if (key == 'direct') return (
            <Box display="flex" alignItems="center">
                <User />
                <span className="body-02-compact" style={{marginLeft: '8px'}}>{t('components.menuItems.newMessage')}</span>
            </Box>
        )
        return (
            <Box display="flex" alignItems="center">
                <Group />
                <span className="body-02-compact"  style={{marginLeft: '8px'}}>{t('components.menuItems.newGroup')}</span>
            </Box>
        )
    }

    const newMessageOptions: Options[] = [
        {key: 'direct', name: t('components.menuItems.newMessage'), render: () => getMenuOption('direct'),  action: () => setOpen('new')},
        {key: 'group', name: t('components.menuItems.newGroup'), render: () => getMenuOption('group'), action: () => setOpen('new_group')},
    ]

    const filteredChats = useMemo(() => {
        return chats.filter(chat => 
            (chat.name && chat.name.toLowerCase().includes(search.toLowerCase())) ||
            Object.values(chat.participants).some(participant => 
                participant.name.toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [chats, search]);

    const handleNewMessage = (chat_id: string) => {
        setSelectedChatId(chat_id);
        setOpen(null);
    }

    const handleRenameGroup = (data: {name: string}) => {
        if (!selectedChatId) return;
        renameGroup(selectedChatId, data.name);
    }

    const handleLeaveGroup = (chat_id: string) => {
        if (!selectedChatId) return;
        setSelectedChatId(chats.filter((chat) => chat.id !== chat_id)[0].id ?? null);
        setOpen(null);
    }

    const handleMuteChat = async () => {
        if (!selectedChatId) return;
        await muteChat(selectedChatId, !selectedChat?.muted);
    }

    const handleGoToClient = () => {
        if (!selectedChat) return;
        const contact = Object.values(selectedChat.participants).find(participant => participant.id !== firebaseId);
        if (!contact) return;
        navigate(`/clients/${contact?.client_id}`);
    }

    useEffect(() => {
        if (!messagingIsLoaded || chats.length == 0) return;
        setSelectedChatId((prevChatId) => {
            return prevChatId && chats.some(chat => chat.id === prevChatId)
                ? prevChatId
                : chats[0].id;
        });
    }, [chats, messagingIsLoaded]);

    useEffect(() => {
        if (!selectedChatId) return;

        markMessagesAsReadWithTimestamp(selectedChatId)

        return () => {
            setTypingStatus(selectedChatId, firebaseId, false);
        };
    }, [selectedChatId, firebaseId]);

    return (
        <Box 
            display="grid" 
            gridTemplateColumns="2fr 4fr" 
            height="calc(100vh - 40px)"                
            borderRadius="6px" 
            border="solid 1px var(--border-subtle-01)"
            boxSizing="border-box"
            overflow="hidden"
            >

            {/* Chats list */}
            <Box display="flex" flexDirection="column">

                <SecondaryToolbar 
                    title={t('pages.messages.title')}
                    slot={<MenuIconButton
                        icon={<AddComment />}
                        options={newMessageOptions}
                        />}
                    />
                    {open == 'new' && <NewMessageModal
                        open={open == 'new'}
                        onClose={() => setOpen(null)}
                        onSubmit={handleNewMessage}
                        />}
                    {open == 'new_group' && <GroupModal
                        open={open == 'new_group'}
                        onClose={() => setOpen(null)}
                        onSubmit={handleNewMessage}
                        />}

                <Box 
                    display="flex" 
                    flexDirection="column" 
                    alignItems="flex-start" 
                    justifyContent="flex-start" 
                    borderRight="solid 1px var(--border-subtle-01)"
                    bgcolor="var(--layer-01)"
                    height="100%"
                    >
                    <Box width="100%" p={2} boxSizing="border-box">
                        <SearchInput
                            name="search"
                            control={formMethods.control}
                            disabled={chats.length == 0}
                            sx={{maxWidth: 'unset'}}
                            />
                    </Box>
                    {filteredChats.length > 0 && filteredChats.sort((a,b) => b.last_message_at.localeCompare(a.last_message_at)).map((chat, chatIndex) => (
                        <Chat
                            key={chat.id}
                            chat={chat}
                            chatIndex={chatIndex}
                            isSelected={chat.id === selectedChat?.id}
                            onSelect={() => setSelectedChatId(chat.id)}
                        />
                    ))}
                    {filteredChats.length == 0 && <Box 
                        display="flex" 
                        flexDirection="column"
                        height="100%"
                        width="100%"
                        alignItems="center" 
                        justifyContent="center" 
                        padding="16px" 
                        boxSizing="border-box"
                        >
                            {search ? 
                                <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('components.messaging.noChatsFound')}</span> :
                                <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('components.messaging.noChats')}</span>}
                            <Button
                                kind="ghost"
                                size="small"
                                label={t('components.buttons.newMessage')}
                                minWidth={false}
                                onClick={() => setOpen('new')}
                                sx={{marginTop: '10px'}}
                                />
                        </Box>}
                </Box>

            </Box>


            {/* Messages */}
            <Box display="flex" flexDirection="column" sx={{overflow: 'hidden'}}>

                <SecondaryToolbar 
                    title={selectedChatTitle}
                    titleAction={isGroup ? 
                        {kind: 'ghost', icon: <Edit /> , onClick: () => setOpen('rename_group')}
                        : undefined}
                    iconActions={[
                        ...(isGroup ? [{icon: <Settings />, onClick: () => setOpen('manage_group')}] : []),
                        {icon: selectedChat?.muted ? <Notification /> : <NotificationOff />, tooltip: selectedChat?.muted ? t('tooltips.unmuteChat') : t('tooltips.muteChat'), onClick: handleMuteChat}
                    ]}
                    action1={showGoToClient ? 
                        {kind: 'ghost', label: t('components.buttons.goToClient'), icon: <User />, onClick: handleGoToClient}
                        : undefined}
                    />
                    {open == 'rename_group' && <RenameModal
                        open={open == 'rename_group'}
                        onClose={() => setOpen(null)}
                        title={t('components.messaging.renameGroup')}
                        name={selectedChat?.name ?? ''}
                        onSubmit={handleRenameGroup}
                        />}
                    {open == 'manage_group' && <GroupModal
                        open={open == 'manage_group'}
                        onClose={() => setOpen(null)}
                        chat={selectedChat}
                        onSubmit={handleNewMessage}
                        onLeave={handleLeaveGroup}
                        />}

                {!messagingIsLoaded ?
                    <Box display="flex" width="100%" height="100%" justifyContent="center" alignItems="center">
                        <CircularProgress size="32px" sx={{color: 'var(--text-primary)'}} />
                    </Box> : (
                        selectedChat ? 
                            <MessageMediaProvider>
                                <Messages chat={selectedChat} />
                            </MessageMediaProvider> :
                            <NoMessages />
                    )}

            </Box>

        </Box>                
    )
}

export default MessagingPage;