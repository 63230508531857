import { RootState } from "./store";
import { authApi } from "@/repositories/auth";
import { coachApi } from "@/repositories/coach";
import showMessageToast from "@/_lib/message_toast";
import { loadingChats } from "@/_constants/messaging";
import { messagingApi } from "@/repositories/messaging";
import { getColorForName } from "@/_helpers/colour_functions";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IMessagingContact } from "@/interfaces/messaging/contact";
import { IChat, IFirebaseUser, IMessage } from "@/interfaces/messaging/messaging";
import { authenticateFirebase, syncUserWithFirestore } from "@/services/messaging/auth_service";


export interface IState {
  enabled: boolean;
  loaded: boolean;
  authenticated: boolean;
  status: 'idle'|'loading'|'authenticated'|'loaded'|'failed';
  error: null | string;
  token: string | null;
  user: IFirebaseUser;
  contacts: IMessagingContact[];
  chats: IChat[];
  messages: Record<string, IMessage[]>
}

const initialState: IState = {
  enabled: false,
  loaded: false,
  authenticated: false,
  status: "idle",
  error: null,
  token: null,
  user: {
    id: '',
    user_id: '',
    email: '',
    name: '',
    avatar: '',
    color: ''
  },
  contacts: [],
  chats: loadingChats,
  messages: {}
};

export const messagingSlice = createSlice({
  name: "messaging",
  initialState,
  reducers: {
    setContacts: (state, { payload }: { payload: IMessagingContact[] }) => {
      state.contacts = payload;
    },
    setChats: (state, { payload }: { payload: IChat[]}) => {
      state.chats = payload;
    },
    setMessages: (state, { payload }: { payload: { chat_id: string; messages: IMessage[] }}) => {
      state.messages[payload.chat_id] = payload.messages;
    },
    setLoaded: (state, { payload }: { payload: boolean }) => {
      state.loaded = payload;
    },
    addMessage: (state, { payload }: { payload: { chat_id: string; message: IMessage, notificationsEnabled: boolean }}) => {
      const chat = state.chats.find((chat) => chat.id === payload.chat_id);
      const chatMessages = state.messages[payload.chat_id] || [];
      if (chatMessages.some((msg) => msg.id === payload.message.id)) return;
      chatMessages.push(payload.message);
      state.messages[payload.chat_id] = chatMessages;
      if (payload.notificationsEnabled && !chat?.muted && !window.location.pathname.includes('messaging')) showNotification(state.user.id, payload.message);
    },
    updateMessage: (state, { payload }: { payload: { chat_id: string; message: IMessage, notificationsEnabled: boolean }}) => {
      const chat = state.chats.find((chat) => chat.id === payload.chat_id);
      const chatMessages = state.messages[payload.chat_id] || [];
      const index = chatMessages.findIndex((msg) => msg.id === payload.message.id);
      if (index && index !== -1) {
        chatMessages[index] = payload.message;
      } else if (index == -1) {
        chatMessages.push(payload.message);
        state.messages[payload.chat_id] = chatMessages;
        if (payload.notificationsEnabled && !chat?.muted && !window.location.pathname.includes('messaging')) showNotification(state.user.id, payload.message);
      }
    },
    removeMessage: (state, { payload }: { payload: { chat_id: string; message_id: string }}) => {
      state.messages[payload.chat_id] = (state.messages[payload.chat_id] || []).filter(
        (msg) => msg.id !== payload.message_id
      );
    },
  },  
  extraReducers: (builder) => {
    builder.addCase(authenticateFirebaseUser.fulfilled, (state, { payload }) => {
      state.authenticated = true;
      state.status = "authenticated";
      state.user = payload;
      state.user.color = getColorForName(payload.name);
    }).addMatcher(authApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
      state.enabled = payload.settings.messaging;
    }).addMatcher(coachApi.endpoints.updateMessaging.matchFulfilled, (state, { payload }) => {
      state.enabled = payload.messaging;
    }).addMatcher(messagingApi.endpoints.getFirebaseToken.matchFulfilled, (state, { payload }) => {
      state.status = "loading";
      state.token = payload.token;
    }).addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      resetState(state);
    });

  },
  selectors: {
    selectMessagingEnabled: (state) => state.enabled,
    selectMessagingLoaded: (state) => state.loaded,
    selectFirebaseAuthenticated: (state) => state.authenticated,
    selectFirebaseToken: (state) => state.token,
    selectFirebaseUser: (state) => state.user,
    selectFirebaseId: (state) => state.user.id,
    selectContacts: (state) => state.contacts.filter((contact) => contact.id !== state.user.id).sort((a, b) => a.name.localeCompare(b.name)),
    selectContactById: (state: IState, id: string) => state.contacts.find((contact) => contact.id === id),
    selectChats: (state): IChat[] => state.chats,
    selectChatById: (state: IState, id: string) => state.chats.find((chat) => chat.id === id),
    selectChatMessages: (state: IState, chat_id: string) => state.messages[chat_id] || [],
    selectMessageById: (state: IState, chat_id: string, message_id?: string) => {
      if (!message_id) return null;
      return state.messages[chat_id].find((message) => message.id === message_id);
    },
    selectChatLastMessage: (state: IState, chat_id: string) => {
      const messages = state.messages[chat_id] || [];
      return messages[messages.length - 1];
    },
    selectChatUnreadCount: (state: IState, chat_id: string) => {
      const count = state.messages[chat_id]?.filter((message) => message.sender_id !== state.user.id && !message.read_by.includes(state.user.id)).length ?? 0;
      return count;
    }
  }
});

const showNotification = (user_id: string, message: IMessage) => {
  if (message.sender_id === user_id) return;
  showMessageToast({
    message: message.text,
    sender_id: message.sender_id,
  });
}

const resetState = (state: IState) => {
  state.enabled = false;
  state.loaded = false;
  state.authenticated = false;
  state.status = "idle";
  state.error = null;
  state.token = null;
  state.user = {
    id: '',
    user_id: '',
    email: '',
    name: '',
    avatar: '',
    color: ''
  };
  state.chats = loadingChats;
}

export const authenticateFirebaseUser = createAsyncThunk<IFirebaseUser, string, {state: RootState;}>(
  "messaging/authenticateFirebaseUser",
  async (token, { getState, rejectWithValue }) => {
      try {
        const firebaseUser = await authenticateFirebase(token);

        const state = getState();
        const user = state.auth.user;
        const coach = state.coach;

        if (!user || !coach) {
          throw new Error("User or Coach data missing from Redux state");
        }

        const userDoc = await syncUserWithFirestore(firebaseUser.uid);
        return userDoc;
      } catch (error) {
        return rejectWithValue(error.message);
      }
  }
);


export const {
  setContacts,
  setChats,
  setMessages,
  addMessage,
  updateMessage,
  removeMessage,
  setLoaded,
} = messagingSlice.actions;

export const { 
  selectMessagingEnabled,
  selectMessagingLoaded,
  selectFirebaseAuthenticated,
  selectFirebaseToken,
  selectFirebaseUser,
  selectFirebaseId,
  selectContacts,
  selectContactById,
  selectChats,
  selectChatById,
  selectChatMessages,
  selectMessageById,
  selectChatLastMessage,
  selectChatUnreadCount,
} = messagingSlice.selectors;

export default messagingSlice.reducer;
