import { t } from "i18next";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ChangeEvent, FC, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

// Services and interfaces
import { selectCoachId } from "@/store/coach";
import { selectContacts } from "@/store/messaging";
import { newChat } from "@/services/messaging/chat_service";
import { selectHasTeam, selectIsOwner, selectTeamId } from "@/store/team";
import { INewMessageForm, newMessageFormSchema } from "@/interfaces/messaging/new_message_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Avatar from "@/components/avatar";
import Modal from "@/components/modal/modal";
import SearchInput from "@/components/search_input";
import { Box, Radio, RadioGroup } from "@mui/material";


interface _NewMessageModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (contact: string) => void;
}

const NewMessageModal: FC<_NewMessageModalProps> = ({
    open,
    onClose,
    onSubmit
}) => {

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formMethods = useForm<INewMessageForm>({
        resolver: zodResolver(newMessageFormSchema),
        mode: 'onChange',
        defaultValues: {
            contact: '',
        }
    });

    const handleClose = () => {
        formMethods.reset();
        onClose();
    }

    const submit = async (data: INewMessageForm) => {
        setIsLoading(true);
        const chatId = await newChat(data.contact);
        formMethods.reset();
        onSubmit(chatId);
        setIsLoading(false);
    }

    return (
        <Modal 
            open={open}
            onClose={formMethods.formState.isDirty ? () => {} : handleClose}
            title={t('modals.newMessage.title')}
            text={t('modals.newMessage.text')}
            children={
                <FormProvider {...formMethods}>
                    <NewMessageForm />
                </FormProvider>}
            action1={{
                kind: 'primary',
                label: t('components.buttons.chat'),
                icon: <ArrowRight />,
                loading: isLoading,
                disabled: !formMethods.formState.isValid,
                onClick: formMethods.handleSubmit(submit)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: handleClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px', width: '100%', height: '60vh', background: 'var(--background)'}}}
            />
    )
}

export default NewMessageModal;


const NewMessageForm: FC = () => {

    const teamId = useSelector(selectTeamId);
    const coachId = useSelector(selectCoachId);
    const hasTeam = useSelector(selectHasTeam);
    const contacts = useSelector(selectContacts);
    const isTeamOwner = useSelector(selectIsOwner);
    const { setValue, watch } = useFormContext<INewMessageForm>();

    // Search form
    const formMethods = useForm({
        mode: 'onBlur',
        defaultValues: {
            search: '',
        }
    });
    const selectedContact = watch('contact');
    const search = formMethods.watch('search');

    const filteredContacts = contacts.filter((contact) =>
        !isTeamOwner ? contact.coach_id == coachId || (contact.team_id == teamId && contact.role == 'coach') : true &&
        contact.messaging_enabled && contact.name.toLowerCase().includes(search.toLowerCase())
    );

    const handleRowSelectContact = (id: string) => {
        const contact = contacts.find((contact) => contact.id === id);
        if (!contact) return;
        setValue('contact', contact.id, { shouldValidate: true });
    }

    const handleSelectContact = (event: ChangeEvent<HTMLInputElement>) => {
        const id = event.target.value;
        const contact = contacts.find((contact) => contact.id === id);
        if (!contact) return;
        setValue('contact', contact.id, { shouldValidate: true });
    }

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            gap="12px"
            sx={{overflow: 'hidden'}}
            >

            <SearchInput 
                name="search"
                control={formMethods.control}
                disabled={contacts.length === 0}
                sx={{maxWidth: 'unset'}}
                />
            
            <RadioGroup
                defaultValue="standard"
                name="radio-buttons-group"
                sx={{width: '100%', rowGap: '8px'}}
                >
                <Box
                    display="flex" 
                    flexDirection="column" 
                    height="30vh" 
                    sx={{overflowY: 'auto'}}
                    >
                    {filteredContacts.length > 0 &&filteredContacts.map((contact) => (
                        <Box
                            key={contact.id}
                            display="flex" 
                            alignItems="center" 
                            padding="8px 16px"
                            onClick={handleRowSelectContact.bind(null, contact.id)} 
                            sx={{cursor: 'pointer'}}
                            >
                            <Avatar
                                name={contact.name}
                                src={contact.avatar}
                                size="medium"
                                sx={{marginRight: '16px'}}
                                />
                            <span className="body-02-compact">{contact.name}</span>
                            <Box display="flex" flexGrow={1} />
                            <Radio 
                                value={contact.id}
                                size="small" 
                                checked={selectedContact === contact.id} 
                                onChange={handleSelectContact} 
                                sx={{padding: 0, marginLeft: '16px'}} 
                                />
                        </Box>
                    ))}
                    {filteredContacts.length == 0 && <Box 
                        display="flex" 
                        flexDirection="column"
                        height="100%"
                        width="100%"
                        alignItems="center" 
                        justifyContent="center" 
                        padding="16px" 
                        boxSizing="border-box"
                        >
                        {search ? 
                            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('components.messaging.noContactsFound', {context: `${hasTeam}`})}</span> :
                            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('components.messaging.noContacts', {context: `${hasTeam}`})}</span>}
                    </Box>}
                </Box>
            </RadioGroup>
        </Box>
    )
}