import { FC } from "react";
import { Box, CircularProgress } from "@mui/material";


interface _MediaLoaderProps {
    showLoader: boolean;
    upload_progress: number;
}

const MediaLoader: FC<_MediaLoaderProps> = ({
    showLoader,
    upload_progress
}) => {

    if (!showLoader) return null;

    return (
        <Box
            position="absolute"
            top="50%"
            left="50%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{transform: 'translate(-50%, -50%)'}}
            >
            <CircularProgress
                variant="determinate"
                value={upload_progress}
                thickness={3}
                size="50px"
                sx={{ color: 'var(--text-placeholder)' }}
                />
            <Box
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                top={0}
                left={0}
                bottom={0}
                right={0}
                >
                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    {Math.round(upload_progress)}%
                </span>
            </Box>
        </Box>
    )
}

export default MediaLoader;