import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";

// Services and interfaces
import { IClientSettingsTraining } from "@/interfaces/settings/client_settings";
import { IProgrammeBuilder, programmeFormSchema } from "@/interfaces/programme/programme_builder";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import Checkbox from "@/components/checkbox";
import TextInput from "@/components/text_input";


interface _ProgrammeModalProps {
    open: boolean;
    onClose: () => void;
    programme?: IProgrammeBuilder;
    isLoading?: boolean;
    onSave: (data: IProgrammeBuilder) => void;
    defaultMetrics?: IClientSettingsTraining;
}

const ProgrammeModal: FC<_ProgrammeModalProps> = ({
    open,
    onClose,
    programme,
    isLoading,
    onSave,
    defaultMetrics
}) => {

    const { t } = useTranslation();
    
    const formMethods = useForm<IProgrammeBuilder>({
        resolver: zodResolver(programmeFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: programme?.name ?? '',
            focus: programme?.focus ?? '',
            notes: programme?.notes ?? '',
            settings: programme?.settings ?? {
                rir: defaultMetrics?.rir ?? false,
                rpe: defaultMetrics?.rpe ?? false,
                tempo: defaultMetrics?.tempo ?? false,
                set_type: defaultMetrics?.set_type ?? false,
                rest_time: defaultMetrics?.rest_time ?? false
            }
        }
    });

    const handleSave = (data: IProgrammeBuilder) => {
        onSave(data);
    }
    
    return (
        <Modal
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : onClose}
            title={t(programme ? 'modals.programme.title_edit' : 'modals.programme.title_add')}
            children={
                <FormProvider {...formMethods}>
                    <ProgrammeForm />
                </FormProvider>}
            action1={{
                label: t('components.buttons.save'),
                loading: isLoading,
                disabled: !formMethods.formState.isDirty,
                onClick: formMethods.handleSubmit(handleSave)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            />
    )
}

export default ProgrammeModal


const ProgrammeForm: FC = () => {

    const { t } = useTranslation();
    const { control, setValue } = useFormContext<IProgrammeBuilder>();
    const settings = useWatch({ control, name: 'settings' });

    useEffect(() => {
        if (settings.rpe) return setValue('settings.rir', false, { shouldDirty: true });
    }, [settings.rpe])

    useEffect(() => {
        if (settings.rir) return setValue('settings.rpe', false, { shouldDirty: true });
    }, [settings.rir])

    return (
        <Box display="flex" flexDirection="column" rowGap="16px" minWidth="640px">
            
            <span className="heading-07">{t('modals.programme.subtitle')}</span>

            <TextInput
                control={control}
                name="name"
                label={t('inputs.titles.programmeName')}
                maxLength={75}
                placeHolder={t('inputs.placeholders.programmeName')}
                />

            <TextInput
                control={control}
                name="focus"
                label={t('inputs.titles.focus')}
                maxLength={40}
                placeHolder={t('inputs.placeholders.programmeFocus')}
                />

            {/* Settings */}
            <Box>
                <span className="label-text-02">{t('inputs.titles.trainingData')}</span>
                <Box 
                    display="grid" 
                    gridTemplateColumns="1fr 1fr 1fr" 
                    columnGap="52px" padding="12px 16px"
                    marginTop="6px"
                    borderRadius="6px" 
                    border="solid 1px var(--border-subtle-01)"
                    sx={{bgcolor: 'var(--layer-01)'}}
                    >
                    <Checkbox
                        control={control}
                        name="settings.rpe"
                        label={t('components.checkbox.rpe')}
                        />
                    <Checkbox
                        control={control}
                        name="settings.rir"
                        label={t('components.checkbox.rir')}
                        />
                    <Checkbox
                        control={control}
                        name="settings.tempo"
                        label={t('components.checkbox.tempo')}
                        />
                    <Checkbox
                        control={control}
                        name="settings.set_type"
                        label={t('components.checkbox.setType')}
                        />
                    <Checkbox
                        control={control}
                        name="settings.rest_time"
                        label={t('components.checkbox.restTime')}
                        />
                </Box>
            </Box>


            <TextInput
                control={control}
                name="notes"
                size="textarea"
                label={t('inputs.titles.programmeNotes')}
                maxLength={4000}
                placeHolder={t('inputs.placeholders.programmeNotes')}
                minRows={4}
                />

        </Box>
    )
}