import baseApi from '@/repositories/base_api';

// Interfaces
import { IAPIResponse } from '@/interfaces/api/response';
import { IUserResponse } from '@/interfaces/user/user_response';
import { ILoginCredentials } from '@/interfaces/auth/login_credentials';

import * as Sentry from "@sentry/react";

export const authApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
      login: build.mutation<Promise<void>, ILoginCredentials>({
        query: (credentials) => ({
            url: 'auth/login',
            method: 'POST',
            data: credentials,
        }),
      }),
      logout: build.mutation<Promise<void>, void>({
        query: () => ({ url: 'auth/logout', method: 'POST' }),
        async onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled.then(() => 
            Sentry.setUser(null)).catch(() => {})
        },
      }),
      refreshCookies: build.mutation<Promise<void>, void>({
        query: () => ({ url: 'auth/refresh', method: 'POST' }),
      }),
      forgotPassword: build.mutation<Promise<void>, string>({
        query: (email) => ({
            url: 'auth/forgot-password',
            method: 'POST',
            data: { email },
        }),
      }),
      verifyEmail: build.mutation<Promise<void>, string>({
        query: (url) => ({
            url,
            method: 'POST',
        }),
      }),
      resendVerificationEmail: build.mutation<Promise<void>, number>({
        query: (id) => ({
            url: `verification/${id}/resend`,
            method: 'POST',
        }),
      }),
      getUser: build.query<IUserResponse, void>({
        query: () => ({ url: 'auth/user', method: 'GET', headers: {
          'timezone' : Intl.DateTimeFormat().resolvedOptions().timeZone
        }}),
        transformResponse: (response: IAPIResponse<IUserResponse>) => response.data,
        async onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled.then((response) => Sentry.setUser({
            id: response.data.user.id ?? undefined,
            email: response.data.user.email,
          })).catch(() => {})
        },
      }),
    }),
})

export const { 
  useLoginMutation,
  useLogoutMutation, 
  useRefreshCookiesMutation,
  useForgotPasswordMutation, 
  useVerifyEmailMutation, 
  useResendVerificationEmailMutation, 
  useGetUserQuery,
  useLazyGetUserQuery,
} = authApi;