
import { FC } from "react";

// Interfacea
import { MediaType } from "@/interfaces/messaging/messaging";

// Components
import Image from "./image";
import Box from "@mui/material/Box"
import ReactPlayer from "react-player";


interface LightboxProps {
    id: string;
    source: string;
    type: MediaType;
}

const Lightbox: FC<LightboxProps> = ({
    id,
    source,
    type
}) => {

    return (
        <Box display='flex' height='95vh' width="100%" justifyContent="center" borderRadius='6px' overflow='hidden'>
            {type == "image" && <Image 
                key={id}
                src={source}
                />}
            {type == "video"  && 
                <Box display="inline-block">
                    <ReactPlayer
                        url={source} 
                        width="100%"
                        height="100%"
                        controls
                        playing
                        />
                </Box>}
        </Box>
    );
}

export default Lightbox;