
import { FC } from "react";
import { t } from "i18next";
import { useSelector } from "react-redux";

// Helpers
import { formatDateAsText } from "@/_helpers/date_functions";

// Services and interfaces
import { RootState } from "@/store/store";
import { IChat } from "@/interfaces/messaging/messaging";
import { selectChatUnreadCount, selectFirebaseUser, selectChatLastMessage } from "@/store/messaging";

// Styles
import { NotificationOff } from "@carbon/icons-react";

// Components
import Tag from "@/components/tag";
import Avatar from "@/components/avatar";
import { Box, Skeleton } from "@mui/material";


interface _ChatProps {
    chat: IChat;
    chatIndex: number;
    isSelected?: boolean;
    isLoading?: boolean;
    onSelect: (chatId: string) => void;
}

const Chat: FC<_ChatProps> = ({
    chat,
    chatIndex,
    isSelected = false,
    isLoading = false,
    onSelect,
}) => {

    const firebaseUser = useSelector(selectFirebaseUser);
    const lastMessage = useSelector((state: RootState) => selectChatLastMessage(state, chat.id));
    const participant = Object.values(chat.participants).find((participant: any) => participant.id !== firebaseUser.id);
    const unreadMessageCount = useSelector((state: RootState) => selectChatUnreadCount(state, chat.id));
    const avatarUrl = participant?.avatar ?? '';
    const groupName = chat.name ?? t('components.messaging.groupChat');

    return (
        <Box 
            key={chat.id} 
            display="flex" 
            alignItems="center" 
            width="100%" 
            height="50px"
            padding="8px 16px"
            boxSizing="border-box"
            borderBottom="solid 1px var(--border-subtle-01)"
            borderRight={isSelected ? 'solid 2px var(--nav-background)' : 'solid 2px transparent'}
            bgcolor={isSelected ? 'var(--layer-01-hover)' : 'var(--layer-01)'}
            onClick={() => onSelect(chat.id)}
            sx={{
                cursor: 'pointer',
                '&:hover': {
                    bgcolor: 'var(--layer-01-hover)'
                }
            }}
            >
            <Avatar
                name={chat.type !== 'direct' ? groupName : participant?.name ?? ''}
                src={chat.type !== 'direct' ? '' : avatarUrl}
                index={chatIndex}
                isLoading={isLoading}
                />
            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" maxWidth="60%" ml="16px">
                {isLoading ? 
                    <Skeleton variant="rectangular" width="100px" height="16px" sx={{maxWidth: '120px', marginBottom: '4px'}} /> :
                    <span className="body-02-compact">{chat.type !== 'direct' ? groupName : participant?.name}</span>}
                {isLoading ? 
                    <Skeleton variant="rectangular" width="200px" height="16px" sx={{maxWidth: '240px'}} /> :
                    <span className="label-text-02 OneLine--ellipsis-breakWord">{lastMessage?.text}</span>}
            </Box>
            <Box display="flex" flexGrow={1} />
            {!isLoading && <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="center">
                <span className="label-text-02">{formatDateAsText(lastMessage?.created_at)}</span>
                <Box display="flex" alignItems="center">
                    {chat.muted && <NotificationOff size="14px" style={{marginTop: '4px'}} />}
                    {unreadMessageCount > 0 && <Tag
                        size="extra-small"
                        label={unreadMessageCount.toString()}
                        colour="blue"
                        sx={{marginLeft: '8px'}}
                        />}
                </Box>
            </Box>}
            {/* {!isLoading && unreadMessageCount > 0 && <Tag
                size="extra-small"
                label={unreadMessageCount.toString()}
                colour="blue"
                sx={{marginLeft: '8px'}}
                />} */}
        </Box>
    )
}

export default Chat;