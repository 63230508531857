import { db } from "@/_lib/firebase";
import { getAuth } from "firebase/auth";
import { storage } from "@/_lib/firebase";
import { getImageDimensions } from "@/_helpers/crop_image";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { IMediaFile, IMediaMetadata } from "@/interfaces/messaging/messaging";
import { 
    addDoc, 
    collection, 
    doc, 
    serverTimestamp, 
    setDoc, 
} from "firebase/firestore";


const auth = getAuth();
  
export const sendMedia = async (chat_id: string, media: IMediaFile) => {
    if (!auth.currentUser) return;
    const user_id = auth.currentUser.uid;
    try {
        const metadata: IMediaMetadata = {
            filename: media.name,
            extension: media.name.split('.').pop(),
            size: media.file.size,
        }
        let imageDimensions = null;
        if (media.type === "image") {
            imageDimensions = await getImageDimensions(media.preview_url);
            metadata.width = imageDimensions.width;
            metadata.height = imageDimensions.height;
        }
        const messageRef = await addDoc(collection(db, `chats/${chat_id}/messages`), {
            sender_id: user_id,
            type: media.type,
            text: media.text ?? null,
            pending: true,
            upload_progress: 0,
            media_group: media.media_group ?? null,
            metadata: metadata,
            created_at: serverTimestamp(),
            read_by: [user_id],
            read_timestamps: {[user_id]: serverTimestamp()},
        });
        await uploadMedia(chat_id, messageRef.id, media.file, media.name);
    } catch (error) {
        console.error("Failed to send message:", error);
    }
}

const uploadMedia = (chat_id: string, message_id: string, file: Blob, fileName: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const name = `${message_id}_${fileName}`;
        const storageRef = ref(storage, `chats/${chat_id}/${name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            async (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload is ${progress}% done`);
                await setDoc(doc(db, `chats/${chat_id}/messages/${message_id}`), {
                    upload_progress: progress,
                }, { merge: true });
            },
            (error) => {
                console.error("Upload failed", error);
                setDoc(doc(db, `chats/${chat_id}/messages/${message_id}`), {
                    pending: false,
                    status: "failed",
                }, { merge: true });
                reject(error);
            },
            async () => {
                try {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    await setDoc(doc(db, `chats/${chat_id}/messages/${message_id}`), {
                        media: downloadURL,
                        pending: false,
                        upload_progress: null,
                    }, { merge: true });
                    resolve(downloadURL);
                } catch (error) {
                    reject(error);
                }
            }
        );
    });
};

export const downloadMedia = async (media: string) => {
    try {
        // Get the file name from the URL
        const urlString = decodeURIComponent(media.split("/").pop() ?? '');
        const fileName = urlString.split("?")[0].split("/").pop() ?? "file";

        // Get the file blob and create a download link
        const response = await fetch(media);
        const blob = await response.blob();
        const blobURL = URL.createObjectURL(blob);

        // Create a download link and trigger the download
        const anchor = document.createElement("a");
        anchor.href = blobURL;
        anchor.download = fileName;
        anchor.click();

        URL.revokeObjectURL(blobURL);
    } catch (error) {
        console.error("Failed to download file:", error);
    }
};

export const generateImageUrl = async (imagePath?: string) => {

    if (!imagePath || imagePath == '') return '';

    const baseUrl = import.meta.env.VITE_MEDIA_URL;
    const currentUser = auth.currentUser;
  
    if (currentUser) {
      const idToken = await currentUser.getIdToken();
      return `${baseUrl}${imagePath}?token=${idToken}`;
    }
  
    throw new Error("User not authenticated");
};