export enum FileExtension {
    MP4 = 'mp4',
    MOV = 'mov',
    MPEG = 'mpeg',
    MPG = 'mpg',
    JPEG = 'jpeg',
    JPG = 'jpg',
    PNG = 'png',
    PDF = 'pdf',
    UNKNOWN = 'unknown'
}

export const allowedFileTypes = '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pages,.numbers,.key,.odt,.ods,.odp,.txt,.rtf';