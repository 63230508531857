import { FC } from "react";
import { t } from "i18next";

// Components
import { Box, keyframes, styled } from "@mui/material";


interface _TypingIndicatorProps {
    name?: string;
    typingCount?: number;
}

const TypingIndicator: FC<_TypingIndicatorProps> = ({
    name,
    typingCount = 1
}) => {

  return (
    <Box display="flex" flexDirection="column">
        <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            alignSelf="flex-start"
            padding="8px"
            maxWidth="45%"
            bgcolor="var(--layer-01)"
            color="var(--text-primary)"
            borderRadius="6px"
            marginBottom="6px"
            sx={{ animation: `${dotAnimation} 1.4s infinite` }}
            >
            <Dot sx={{ animationDelay: "0s" }} />
            <Dot sx={{ animationDelay: "0.2s" }} />
            <Dot sx={{ animationDelay: "0.4s" }} />
        </Box>
        <AnimatedText>
            {typingCount <= 1 ? 
                <span className="label-text-03">{t('components.messaging.message.isTyping', {name: name})}</span> :
                <span className="label-text-03">{t('components.messaging.message.multipleTyping', {name: name, count: typingCount})}</span>}
            <Ellipsis />
        </AnimatedText>
    </Box>
  );
};

export default TypingIndicator;

const dotAnimation = keyframes`
  0% { transform: scale(1); opacity: 0.3; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); opacity: 0.3; }
`;

// const fadeAnimation = keyframes`
//   0%, 100% { color: var(--text-placeholder); }
//   50% { color: var(--text-primary); }
// `;

const ellipsisAnimation = keyframes`
  0% { content: ''; }
  33% { content: '.'; }
  66% { content: '..'; }
  100% { content: '...'; }
`;

const Dot = styled(Box)(({ theme }) => ({
  width: 6,
  height: 6,
  margin: "0 4px",
  backgroundColor: "var(--text-placeholder)",
  borderRadius: "50%",
  animation: `${dotAnimation} 1.4s infinite ease-in-out`,
}));

const AnimatedText = styled("span")(({ theme }) => ({
    fontSize: "12px",
    textAlign: "left",
    // animation: `${fadeAnimation} 1.4s infinite ease-in-out`,
}));

const Ellipsis = styled("span")(({ theme }) => ({
    marginLeft: "4px",
    "::after": {
        display: "inline-block",
        content: "''",
        animation: `${ellipsisAnimation} 1.4s infinite ease-in-out`,
    },
}));