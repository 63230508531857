
import { t } from "i18next";
import { useSelector } from "react-redux";
import { FC, ReactNode, useRef, useState } from "react";

// Services and interfaces
import { selectFirebaseId } from "@/store/messaging";
import { IMessage, IParticipant } from "@/interfaces/messaging/messaging";
import { deleteReaction, reactToMessage } from "@/services/messaging/message_service";

// Styles
import { ChevronDown } from "@carbon/icons-react";
import { Done, DoneAll } from "@mui/icons-material";

// Components
import { Box } from "@mui/material";
import Avatar from "@/components/avatar";
import MessageMenu from "./message_menu";
import FileMessage from "./file_message";
import AudioMessage from "./audio_message";
import ImageMessage from "./image_message";
import VideoMessage from "./video_message";
import MessageReply from "./message_reply";
import MessageActions from "./message_actions";
import MessageReactions from "./message_reactions";


interface _MessageProps {
    chat_id: string;
    message: IMessage;
    participants: Record<string, IParticipant>;
    onEdit: (message: IMessage) => void;
    onReply: (message: IMessage) => void;
}

const Message: FC<_MessageProps> = ({
    chat_id,
    message,
    participants,
    onEdit,
    onReply
}) => {

    const anchor = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState<boolean>(false);

    const firebaseId = useSelector(selectFirebaseId);
    const isSender = message.sender_id == firebaseId;
    const isSystem = message.type == 'event';
    const isInGroup = Object.keys(participants).includes(message.sender_id);
    const avatarUrl = participants[message.sender_id]?.avatar;
    const hasReactions = message.reactions && message.reactions.length > 0;
    const read = Object.keys(participants).filter((participantId) => 
        message.sender_id !== participantId && message.read_by && !message.read_by.some((user) => 
            user === participantId)).length === 0;
    const sent_at = new Date(message.created_at).toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})
    const edited_at = message.updated_at && new Date(message.updated_at).toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})
    const messageColor = isSender ? 'var(--nav-background)' : 'var(--layer-01)';
    const textColor = isSender ? 'var(--white)' : 'var(--text-primary)';

    const getReadMarker = (): ReactNode => {
        if (!isSender) return null;
        if (read) return <DoneAll sx={{height: '14px', color: 'var(--icon-interactive)'}} />;
        return <Done sx={{height: '14px', color: 'var(--text-placeholder)'}} />;
    };

    const handleDoubleClick = () => {
        if (message.reactions && message.reactions.some((reaction) => reaction.user_id === firebaseId && reaction.emoji == "❤️")) {
            return deleteReaction(chat_id, message.id, {user_id: firebaseId, emoji: "❤️"});
        }
        reactToMessage(chat_id, message.id, {user_id: firebaseId, emoji: "❤️"});
    }

    const handleReplyToMessage = () => {
        onReply(message);
    }

    const getMessageWidth = () => {
        if (message.type == 'voice') return '300px';
        if (message.type == 'image' || message.type == 'video') return 'calc(250px + 8px)';
        return '100%';
    }

    if (isSystem) return (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%">
            <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{message.text}</span>
        </Box>
    );

    return (
        <Box
            id={`message-${message.id}`}
            display="flex"
            flexDirection={isSender ? 'row-reverse' : 'row'}
            alignSelf={isSender ? 'flex-end' : 'flex-start'}
            alignItems="flex-end"
            width="100%" maxWidth="70%"
            sx={{
                "&:hover ._MessageActions": {
                    visibility: "visible",
                    opacity: 1,
                },
            }}
            >
                
            {!isSender && isInGroup && <Avatar
                size="small"
                name={participants[message.sender_id].name}
                src={avatarUrl}
                alt={participants[message.sender_id].name}
                sx={{ml: isSender ? '8px' : '0', mr: isSender ? '0' : '8px', mb: '18px'}}
                />}

            {/* Message body */}
            <Box display="flex" alignItems="center" flexDirection={isSender ? 'row' : 'row-reverse'}>
                
                {/* Actions */}
                <MessageActions
                    chat_id={chat_id}
                    message_id={message.id}
                    isSender={isSender}
                    onReply={handleReplyToMessage}
                    />

                <Box position="relative" display="flex" flexDirection="column">
                    <Box 
                        key={message.id} 
                        display="flex" 
                        flexDirection="column" 
                        alignItems="flex-start" 
                        justifyContent="center"
                        width={message.type == 'voice' ? '100%' : 'unset'}
                        minWidth="160px"
                        maxWidth={getMessageWidth()}
                        padding="4px"
                        boxSizing="border-box"
                        borderRadius="6px"
                        bgcolor={messageColor}
                        color={textColor}
                        textAlign="left"
                        position="relative"
                        onDoubleClick={handleDoubleClick}
                        sx={{
                            boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.15)',
                            borderBottomLeftRadius: !isSender ? '0px' : '6px',
                            borderBottomRightRadius: isSender ? '0px' : '6px',
                            transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                            "&:hover": {
                                transform: "scale(1.02)",
                            },
                            '&:hover .chevron': {
                                color: 'var(--text-primary)',
                                backgroundColor: 'var(--layer-01)',
                                visibility: 'visible',
                            },
                        }}
                        >
                        {/* File message */}
                        {message.type === 'file' && <FileMessage
                            source={message.media}
                            upload_progress={message.upload_progress}
                            metadata={message.metadata}
                            />}

                        {/* Image message */}
                        {message.type == 'image' && <ImageMessage
                            id={message.id}
                            source={message.media_thumbnail}
                            upload_progress={message.upload_progress}
                            />}

                        {/* Video message */}
                        {message.type == 'video' && <VideoMessage
                            id={message.id}
                            source={message.media}
                            thumbnail={message.media_thumbnail}
                            upload_progress={message.upload_progress}
                            />}

                        {/* Audio message */}
                        {message.type == 'voice' && <AudioMessage 
                            source={message.media}
                            upload_progress={message.upload_progress}
                            pending={message.pending}
                            isSender={isSender}
                            />}   

                        {/* Reply message */}
                        {message.reply_to && <MessageReply
                            chat_id={chat_id}
                            reply_id={message.reply_to}
                            participants={participants}
                            />}

                        {/* Text message */}
                        {message.text && <Box padding="4px" sx={{marginTop: message.media ? '8px' : 'unset'}}>
                                <span className="body-02" style={{whiteSpace: 'pre-wrap'}}>{message.text}</span>
                            </Box>}
                        
                        {isSender && <Box display="flex" width="100%" alignItems="center">
                            <Box 
                                ref={anchor}
                                className="chevron"
                                display="flex"
                                alignItems="center"
                                borderRadius="4px"
                                position="absolute"
                                top="8px"
                                right="8px"
                                color="transparent"
                                visibility="hidden"
                                onClick={() => setOpen(true)}
                                sx={{
                                    cursor: 'pointer',
                                    transition: 'color 0.2s, background-color 0.2s',
                                }}
                                >
                                <ChevronDown  size="16px" />
                            </Box>
                        </Box>}
                    </Box>

                    {/* Reaction s */}
                    {hasReactions && <MessageReactions 
                        chat_id={chat_id}
                        message_id={message.id}
                        sender_id={message.sender_id}
                        reactions={message.reactions}
                        />}

                    {/* Timestamp and read marker */}
                    <Box display="flex" justifyContent={isSender ? 'flex-end' : 'flex-start'} mt="4px">
                        <span className="label-text-03">{edited_at ? t('components.messaging.message.editedAt', {time: edited_at}) : sent_at}</span>
                        {getReadMarker()}
                    </Box>
                </Box>
            </Box>

            {isSender && <MessageMenu 
                open={open}
                onClose={() => setOpen(false)}
                chat_id={chat_id}
                message={message}
                anchor={anchor}
                canEdit={message.type != 'voice'}
                onEdit={() => onEdit(message)}
                />}
        </Box>

    )
}

export default Message;