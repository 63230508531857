import { FC } from "react";

// Styles
import { Warning } from "@carbon/icons-react";

// Components
import AudioPlayer from "./audio_player_visual";
import { Box, CircularProgress } from "@mui/material";


interface _AudioMessageProps {
    source?: string;
    upload_progress?: number;
    pending?: boolean;
    isSender: boolean;
}

const AudioMessage: FC<_AudioMessageProps> = ({
    source,
    upload_progress,
    pending,
    isSender
}) => {

    const hasFailed = upload_progress == -1;
    const showLoader = upload_progress != null && upload_progress >= 0 && !hasFailed;

    return (
        <Box display="flex" width="100%" maxWidth="" alignItems="center">
            {showLoader && 
                <Box sx={{ position: 'relative', display: 'inline-flex', mr: '4px' }}>
                    <CircularProgress
                        variant="determinate"
                        value={upload_progress}
                        thickness={2}
                        size="28px" 
                        sx={{color: 'var(--background-brand)'}} 
                        />
                    <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                        }}
                        >
                        <span style={{fontSize: '7px'}}>{Math.round(upload_progress)}%</span>
                    </Box>
                </Box>}
            {hasFailed && <Warning size="24px" style={{color: 'var(--support-error)', marginLeft: '6px', marginRight: '4px'}} />}
            {source && <AudioPlayer source={source} isSender={isSender} />}
        </Box>
    )
}

export default AudioMessage;