import { Action, Middleware } from "@reduxjs/toolkit";

// Services and interfaces
import { IChat } from "@/interfaces/messaging/messaging";
import { setChats, setContacts } from "@/store/messaging";
import { getParticipants } from "@/services/messaging/chat_service";

export const contactsMiddleware: Middleware = (store) => (next) => (action: Action) => {

  const prevState = store.getState().messaging.contacts;
  const chats = store.getState().messaging.chats;
  const result = next(action);
  const newContacts = store.getState().messaging.contacts;

  if (action.type === setContacts.type && chats.length > 0 && JSON.stringify(prevState) !== JSON.stringify(newContacts)) {
      const updatedChats = chats.map((chat: IChat) => {
        return {
          ...chat,
          participants: getParticipants(Object.keys(chat.participants)),
        };
      });
      store.dispatch(setChats(updatedChats));
  }
  return result;
};