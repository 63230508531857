import { FC, useRef, useState } from "react"

// Styles
import { OverflowMenuVertical } from "@carbon/icons-react";

// Components
import { IconButtonProps } from "@mui/material";
import Menu, { Options } from "@/components/menu";
import IconButton from "@/components/icon_button";


interface MenuIconButtonProps {
    kind?: 'primary'|'secondary'|'tertiary'|'ghost'|'danger'|'danger-secondary'|'danger-ghost';
    size?: 'small'|'medium'|'large';
    icon?: React.ReactNode;
    position?: { 
        horizontal: 'center'|'left'|'right'|number, 
        vertical: 'bottom'|'center'|'top'|number 
    }
    options?: Options[];
    deleteLabel?: string;
    disableDelete?: boolean;
    disabled?: boolean;
    onDelete?: (() => void);
    sx?: IconButtonProps['sx'];
}

const MenuIconButton: FC<MenuIconButtonProps> = ({
    kind = 'ghost',
    size = 'small',
    icon = <OverflowMenuVertical />,
    position = { horizontal: 'right', vertical: 'bottom' },
    options,
    deleteLabel,
    disableDelete = false,
    disabled = false,
    onDelete,
    sx
}) => {

    const [open, setOpen] = useState<boolean>(false);
    const anchor = useRef<HTMLButtonElement>(null);

    const styles = {
        boxShadow: open ? '0px 2px 6px 0px rgba(0, 0, 0, 0.30)' : null,
        ...sx
    }

    return (
        <>
            <IconButton 
                ref={anchor} 
                kind={kind} 
                size={size} 
                icon={icon}
                disabled={disabled}
                onClick={() => setOpen(true)}
                sx={styles}
            />
            <Menu
                size={size}
                open={open}
                onClose={() => setOpen(false)}
                anchorEl={anchor?.current}
                position={position}
                options={options ?? []}
                deleteLabel={deleteLabel}
                disableDelete={disableDelete}
                onDelete={onDelete}
            />
        </>
    )

}

export default MenuIconButton;