import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Intercom from "@intercom/messenger-js-sdk";
import { boot, show, hide, onHide, shutdown } from "@intercom/messenger-js-sdk";

// Services and interfaces
import { selectUser } from "@/store/auth";
import { selectMessagingEnabled } from "@/store/messaging";

// Styles
import { Help } from "@carbon/icons-react";

// Components
import NavUser from "./nav_user";
import Box from "@mui/material/Box";
import NavReferral from "./nav_referral";
import NavMessages from "./nav_messages";
import Tooltip from "@/components/tooltip";
import NavNotification from "./nav_notification";
import NavHeaderAction from "@/components/navigation/nav_header_action";

const appId = import.meta.env.VITE_INTERCOM_APP_ID;

Intercom({
  app_id: appId,
  hide_default_launcher: true,
});

export default function NavToolbarActions() {

    const { t } = useTranslation();
    const user = useSelector(selectUser)
    const [showHelp, setShowHelp] = useState(false);
    const isSetup = location.pathname.startsWith('/setup');
    const messagingEnabled = useSelector(selectMessagingEnabled);

    const handleShowHelp = () => {
        const s = showHelp;
        setShowHelp(!s);
        s ? hide() : show();
    }

    useEffect(() => {
        boot({
            app_id: appId,
            user_id: String(user?.id),
            name: `${user?.first_name} ${user?.last_name}`,
            email: user?.email,
        });
        onHide(() => setShowHelp(false));
        return () => {
            shutdown;
        }
    }, []);

    return (
        <Box display='flex' alignItems='center'>    

            {!isSetup && messagingEnabled && <NavMessages /> }      
            
            {!isSetup && <NavReferral />}

            <Tooltip kind="nav" title={t('tooltips.supportCenter')}>
                <NavHeaderAction icon={<Help size={20} />} onClick={handleShowHelp} />
            </Tooltip>

            <NavNotification />
            <NavUser />
        </Box>
    );
}

