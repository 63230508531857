import { db } from "@/_lib/firebase";
import { getAuth } from "firebase/auth";
import { IMessageReply } from "@/interfaces/messaging/messaging";
import { 
    addDoc, 
    arrayRemove, 
    collection, 
    deleteDoc, 
    doc, 
    getDoc, 
    getDocs,
    query,
    serverTimestamp, 
    setDoc, 
    Timestamp, 
    where, 
    writeBatch 
} from "firebase/firestore";


const auth = getAuth();
  
// Helper to fetch messages
export const getMessages = async (chatId: string) => {
    const messagesRef = collection(db, `chats/${chatId}/messages`);
    const messagesSnapshot = await getDocs(messagesRef);

    return messagesSnapshot.docs.map((messageDoc) => ({
        id: messageDoc.id,
        sender_id: messageDoc.data().sender_id,
        type: messageDoc.data().type ?? 'text',
        text: messageDoc.data().text,
        media: messageDoc.data().media,
        media_thumbnail: messageDoc.data().media_thumbnail,
        reactions: messageDoc.data().reactions,
        metadata: messageDoc.data().metadata,
        reply_to: messageDoc.data().reply_to,
        created_at: messageDoc.data().created_at ? new Date(messageDoc.data().created_at.toDate()).toISOString() : '',
        updated_at: messageDoc.data().updated_at ? new Date(messageDoc.data().updated_at.toDate()).toISOString() : '',
        read_by: messageDoc.data().read_by ?? [],
        read_timestamps: messageDoc.data().read_timestamps ? mapReadTimestamps(messageDoc.data().read_timestamps) : [],
    })).sort((a, b) => 
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
};

export const sendMessage = async (chat_id: string, message: string, reply_to?: string) => {
    if (!auth.currentUser) return;
    const user_id = auth.currentUser.uid;
    try {
        await addDoc(collection(db, `chats/${chat_id}/messages`), {
            sender_id: user_id,
            type: 'text',
            text: message,
            reply_to: reply_to ?? null,
            created_at: serverTimestamp(),
            read_by: [user_id],
            read_timestamps: {[user_id]: serverTimestamp()},
        });
    } catch (error) {
        console.error("Failed to send message:", error);
    }
}

export const editMessage = async (chat_id: string, message_id: string, message: string) => {
    try {
        const messageRef = doc(db, `chats/${chat_id}/messages/${message_id}`);
        await setDoc(messageRef, {
            text: message,
            updated_at: serverTimestamp(),
        }, { merge: true });
    } catch (error) {
        console.error("Failed to edit message:", error);
    }
}

export const reactToMessage = async (chat_id: string, message_id: string, reaction: { user_id: string, emoji: string }) => {
    try {
        const messageRef = doc(db, `chats/${chat_id}/messages/${message_id}`);
        const messageSnapshot = await getDoc(messageRef);

        if (!messageSnapshot.exists()) {
            throw new Error("Message not found");
        }

        const currentReactions = messageSnapshot.data().reactions || [];
        const updatedReactions = currentReactions.map((r: { user_id: string; emoji: string }) =>
            r.user_id === reaction.user_id ? { ...r, emoji: reaction.emoji } : r
        );

        if (!currentReactions.some((r: { user_id: string }) => r.user_id === reaction.user_id)) {
            updatedReactions.push(reaction);
        }

        await setDoc(messageRef, {
            reactions: updatedReactions,
        }, {merge: true});
    } catch (error) {
        console.error("Failed to react to message:", error);
    }
}

export const deleteReaction = async (chat_id: string, message_id: string, reaction: { user_id: string, emoji: string }) => {
    try {
        const messageRef = doc(db, `chats/${chat_id}/messages/${message_id}`);
        await setDoc(messageRef, {
            reactions: arrayRemove(reaction),
        }, { merge: true });
    } catch (error) {
        console.error("Failed to delete reaction:", error);
    }
}

export const deleteMessage = async (chat_id: string, message_id: string) => {
    try {
        const messageRef = doc(db, `chats/${chat_id}/messages/${message_id}`);
        await deleteDoc(messageRef);
    } catch (error) {
        console.error("Failed to edit message:", error);
    }
}

export const deleteMessages = async (chat_id: string, message_ids: string[]): Promise<void> => {

    if (!message_ids.length) {
        console.warn("No messages to delete.");
        return;
    }
    
    try {
        const batch = writeBatch(db);
        message_ids.forEach((message_id) => {
            const messageRef = doc(db, `chats/${chat_id}/messages/${message_id}`);
            batch.delete(messageRef);
        });
        await batch.commit();
    } catch (error) {
        console.error("Error deleting messages:", error);
    }
};

export const mapReplyTo = async (chat_id: string, reply_to?: string): Promise<IMessageReply|null> => {
    if (!reply_to) return null;

    const messageRef = doc(db, `chats/${chat_id}/messages/${reply_to}`);
    const messageDoc = await getDoc(messageRef);

    if (!messageDoc.exists()) {
        console.warn("Reply to message not found");
        return null;
    }

    const messageData = messageDoc.data();
    return {
        id: messageDoc.id,
        sender_id: messageData.sender_id,
        type: messageData.type,
        text: messageData.text,
        media: messageData.media,
        media_thumbnail: messageData.media_thumbnail,
        metadata: messageData.metadata,
    };
}

export const mapReadTimestamps = (read_timestamps: Record<string, Timestamp>) => {
    return Object.entries(read_timestamps).map(([user_id, timestamp]) => ({
      user_id: user_id,
      read_at: timestamp ? new Date(timestamp.toDate()).toISOString() : '',
    }));
}

export const markMessagesAsReadWithTimestamp = async (chat_id: string) => {
    if (!auth.currentUser) return;
    const user_id = auth.currentUser?.uid;

    const messagesRef = collection(db, `chats/${chat_id}/messages`);
    const messagesQuery = query(messagesRef, where('read_by', 'not-in', [user_id]));
    const snapshot = await getDocs(messagesQuery);
  
    const batch = writeBatch(db);

    snapshot.docs.forEach((doc) => {
        const messageRef = doc.ref;
        if (!doc.data().read_by || doc.data().read_by.includes(user_id)) return;
        batch.update(messageRef, {
            read_by: [...doc.data().read_by, user_id],
            [`read_timestamps.${user_id}`]: serverTimestamp(),
        });
    });
  
    await batch.commit();
};

export const markMaessageAsRead = async (chat_id: string, message_id: string) => {
    if (!auth.currentUser) return;
    const user_id = auth.currentUser?.uid;

    const messageRef = doc(db, `chats/${chat_id}/messages/${message_id}`);
    await setDoc(messageRef, {
        [`read_by.${user_id}`]: serverTimestamp(),
    }, { merge: true });
}