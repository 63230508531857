import { FC } from "react";
import { defaultStyles, FileIcon } from "react-file-icon";

// Services and interfaces
import { IMediaFile } from "@/interfaces/messaging/messaging";

// Styles
import { TrashCan } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Tooltip from "@/components/tooltip";
import { fileExtension } from "@/_helpers/file_functions";


interface _MediaInputProps {
    selectedFiles: IMediaFile[];
    selectedFile?: IMediaFile|null;
    onSelectMedia: (file: IMediaFile) => void;
    handleRemoveMedia: (index: number) => void;
}

const MediaInput:FC<_MediaInputProps> = ({
    selectedFiles,
    selectedFile,
    onSelectMedia,
    handleRemoveMedia
}) => {

    const getMediaIcon = (item: IMediaFile) => {
        if (item.type.includes('image')) {
            return (
                <img 
                    src={item.preview_url} 
                    alt="Preview"
                    style={{ 
                        width: 80, 
                        height: 80, 
                        borderRadius: 2, 
                        objectFit: 'cover',
                        position: 'relative',
                        zIndex: 0
                    }} 
                    />
            )
        } else if (item.type.includes('video')) {
            return (
                <video width="80" >
                    <source src={URL.createObjectURL(item.file)}/>
                </video>
            )
        } else if (item.type.includes('file')) {
            return (
                <Box width="48px" borderRadius="4px">
                    <FileIcon
                        extension={fileExtension(item.name)} {...defaultStyles[fileExtension(item.name)]} />
                </Box>
            )
        }
        return null;
    }

    return (
        <Box display="flex" flexWrap="wrap" gap="8px" mb="16px">
            {selectedFiles.map((file, index) => (
                <Tooltip key={index} kind="nav" title={file.name}>
                    <Box 
                        key={index}
                        display="flex"
                        position="relative"
                        width="80px"
                        height="80px"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="2px"
                        overflow="hidden"
                        onClick={() => onSelectMedia(file)}
                        sx={{
                            cursor: 'pointer',
                            boxShadow: `0 0 0 2px ${selectedFile?.name == file.name ? 'var(--text-placeholder)' : 'transparent'}`,
                            transition: 'box-shadow 0.2s ease-in-out',
                            '&:hover': { 
                                '&::after': { opacity: 1 },
                                '& ._RemoveMedia-icon': { display: 'block !important' }
                            },
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                opacity: 0,
                                transition: 'opacity 0.2s ease-in-out',
                                zIndex: 1
                            }
                        }}
                        >
                        {getMediaIcon(file)}
                        <TrashCan
                            className="_RemoveMedia-icon"
                            color="white"
                            onClick={() => handleRemoveMedia(index)} 
                            style={{
                                position: 'absolute',
                                top: 4, 
                                right: 4,
                                display: 'none',
                                zIndex: 2
                            }}
                            />
                    </Box>
                </Tooltip>
            ))}
        </Box>
    )
}

export default MediaInput;