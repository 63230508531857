import { useEffect, useState, useRef } from "react";
import { useLogoutMutation, useRefreshCookiesMutation } from "@/repositories/auth";

function useDebounce<T>(callback: T, delay: number) {
  const [debounceValue, setDebounceValue] = useState(callback);
  useEffect(() => {
      // set delay and add callback to state
      const handler = setTimeout(() => setDebounceValue(callback), delay);

      // cleanup on done
      return () => clearTimeout(handler)
  }, [callback, delay]);
  return debounceValue;
}

function useFirstRender() {
  const firstRender = useRef(true);

  useEffect(() => {
    return () => {
      firstRender.current = false;
    }}, [])

  return firstRender;
}

const useTokenRefresh = () => {

  const isFirstRender = useFirstRender();
  const [refreshCookies] = useRefreshCookiesMutation();
  const [logout] = useLogoutMutation();

  useEffect(() => {
    const refresh = async () => {
      try {
        await refreshCookies();
      } catch (error) {
        console.error("Token refresh failed:", error);
        await logout();
      }
    };

    if (isFirstRender.current) {
      refresh();
    }

    const interval = setInterval(refresh, 30 * 60 * 1000);

    return () => clearInterval(interval);
  }, [isFirstRender, refreshCookies, logout]);
};

export { useDebounce, useFirstRender, useTokenRefresh };