import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import Modal from "@/components/modal/modal";
import { deleteMessage, deleteMessages } from "@/services/messaging/message_service";


interface _DeleteMessageModalProps {
    open: boolean;
    onClose: () => void;
    chat_id: string;
    message_ids: string[];
}

const DeleteMessageModal: FC<_DeleteMessageModalProps> = ({
    open,
    onClose,
    chat_id,
    message_ids,
}) => {

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const handleDeleteMessage = async () => {
        setIsLoading(true);
        await deleteMessage(chat_id, message_ids[0]);
        onClose();
        setIsLoading(false);
    }

    const handleDeleteAll = () => {
        setIsLoading(true);
        deleteMessages(chat_id, message_ids);
        onClose();
        setIsLoading(false);
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.deleteMessage.title', {context: `${message_ids.length > 1}`})}
            text={t('modals.deleteMessage.text', {context: `${message_ids.length > 1}`})}
            action1={{
                kind: 'danger',
                label: t('components.buttons.delete'),
                loading: isLoading,
                onClick: message_ids.length > 1 ? handleDeleteAll : handleDeleteMessage
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px', background: 'var(--background)'}}}
            />
    )
}

export default DeleteMessageModal;