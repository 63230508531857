
import { FC } from "react";
import { useSelector } from "react-redux";

// Services and interfaces
import { selectFirebaseUser } from "@/store/messaging";

// Components
import { Box } from "@mui/material";
import MessageInput from "./message_input";


const NoMessages: FC = () => {

    const firebaseUser = useSelector(selectFirebaseUser);

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px - 48px)" overflow="hidden">
            <Box
                display="flex"
                flexDirection="column"
                height="90%"
                rowGap="16px"
                padding="16px"
                bgcolor="var(--layer-02)"
                sx={{ overflowY: "auto" }}
                />

            <MessageInput
                chat_id={'1'}
                user_id={firebaseUser.id}
                disabled
                onClearEdit={() => {}}
                onClearReply={() => {}}
                />
        </Box>
    )
}

export default NoMessages;