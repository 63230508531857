import { db } from "@/_lib/firebase";
import store from "@/store/store";
import { getAuth } from "firebase/auth";
import { 
    doc, 
    addDoc, 
    collection, 
    serverTimestamp,
    setDoc,
} from "firebase/firestore";


const auth = getAuth();
  
export const newGroup = async (contacts: string[]): Promise<string> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("User is not authenticated");
    }

    const chatRef = collection(db, 'chats');

    const chatDoc = await addDoc(chatRef, {
        type: 'group',
        owner_id: user.uid,
        participants: [...contacts, user.uid],
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
    });

    return chatDoc.id;
}

export const renameGroup = async (chatId: string, name: string): Promise<void> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("User is not authenticated");
    }
    console.log("Renaming group", chatId, name);

    try {
        const chatRef = doc(db, `chats/${chatId}`);
        await setDoc(chatRef, {
            name: name,
            updated_at: serverTimestamp(),
        }, { merge: true });
    } catch (error) {
        console.error("Failed to rename group:", error);
    }
}

// export const updateGroup = async (chat_id: string, contacts: string[]): Promise<string> => {
//     const user = auth.currentUser;
//     if (!user) {
//         throw new Error("User is not authenticated");
//     }

//     const chatRef = doc(db, `chats/${chat_id}`);

//     await setDoc(chatRef, {
//         participants: [...contacts, user.uid],
//         updated_at: serverTimestamp(),
//     }, { merge: true });

//     return chat_id;
// }

export const updateGroup = async (chat_id: string, contacts: string[]): Promise<string> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("User is not authenticated");
    }

    const chatRef = doc(db, `chats/${chat_id}`);
    const messagesRef = collection(db, `chats/${chat_id}/messages`);

    const state = store.getState();
    const chat = state.messaging.chats.find(chat => chat.id === chat_id);

    const oldContacts: string[] = Object.keys(chat?.participants ?? {});
    const updatedContacts = [...new Set([...contacts, user.uid])];

    const joinedUsers = updatedContacts.filter(uid => !oldContacts.includes(uid));
    const leftUsers = oldContacts.filter(uid => !updatedContacts.includes(uid));

    await setDoc(chatRef, {
        participants: updatedContacts,
        updated_at: serverTimestamp(),
    }, { merge: true });

    const eventPromises: Promise<any>[] = [];

    joinedUsers.forEach(uid => {
        const name = state.messaging.contacts.find(contact => contact.id === uid)?.name || "Unknown";
        eventPromises.push(addDoc(messagesRef, {
            sender_id: "system",
            type: "event",
            text: `${name} joined the chat.`,
            read_by: [user.uid],
            created_at: serverTimestamp(),
        }));
    });

    leftUsers.forEach(uid => {
        const name = state.messaging.contacts.find(contact => contact.id === uid)?.name || "Unknown";
        eventPromises.push(addDoc(messagesRef, {
            sender_id: "system",
            type: "event",
            text: `${name} left the chat.`,
            read_by: [user.uid],
            created_at: serverTimestamp(),
        }));
    });

    await Promise.all(eventPromises);
    return chat_id;
};

export const leaveGroup = async (chat_id: string): Promise<string> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("User is not authenticated");
    }

    const chatRef = doc(db, `chats/${chat_id}`);
    const messagesRef = collection(db, `chats/${chat_id}/messages`);

    const state = store.getState();
    const chat = state.messaging.chats.find(chat => chat.id === chat_id);

    const updatedParticipants = Object.keys(chat?.participants ?? {}).filter(uid => uid !== user.uid);

    await addDoc(messagesRef, {
        sender_id: "system",
        type: "event",
        text: `${state.messaging.user.name} left the chat.`,
        read_by: [user.uid],
        created_at: serverTimestamp(),
    });

    await setDoc(chatRef, {
        participants: updatedParticipants,
        updated_at: serverTimestamp(),
    }, { merge: true });
    return chat_id;
}