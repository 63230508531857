import { FC, useEffect, useRef, useState } from "react";
import EmojiPickerReact, { EmojiClickData } from 'emoji-picker-react';

// Styles
import { FaceActivated } from '@carbon/icons-react';

// Components
import { Box } from "@mui/material";


interface _EmojiPickerProps {
  onSelect: (emoji: string) => void;
}

const EmojiPicker: FC<_EmojiPickerProps>  = ({
  onSelect
}) => {


  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    onSelect(emojiData.emoji);
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as Node)
      ) {
        setEmojiPickerOpen(false);
      }
    };

    if (emojiPickerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPickerOpen]);

  return (
    <Box ref={emojiPickerRef} position="relative" display="flex" alignItems="center">
      <FaceActivated 
        size="20px" 
        onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
        style={{
          position: 'relative',
          cursor: 'pointer', 
          marginTop: '16px',
          color: emojiPickerOpen ? 'var(--focus)' : 'var(--text-secondary)'
        }}
        />
        {emojiPickerOpen && <EmojiPickerReact
          open={emojiPickerOpen}
          onEmojiClick={handleEmojiClick} 
          style={{
            position: 'absolute', 
            bottom: 'calc(100% - 4px)', 
            right: '-12px', 
            zIndex: 1000, 
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
          }}
          />}
    </Box>
  );
};

export default EmojiPicker;